.RowBox {
  padding-left: 30%;
  width: 100%;
  padding-right: 30%;
  display: flex;
  justify-content: center;
  align-items: flex-start; /* 상단에 정렬 */
  overflow: hidden;
  height: 65vh;
  margin-top: 5%;
}

.RowBox img {
  width: 100%;
  height: 80vh; /* `RowBox` 높이보다 크게 설정 */
  object-fit: cover;
  object-position: top; /* 상단을 중심으로 */
}

.center-button {
  text-align: center;
}

.orange-button {
  background-color: #ff6600; /* 주황색 배경 */
  color: white; /* 흰색 텍스트 */
  width: 300px;
  height: 70px;
  border: none; /* 테두리 제거 */
  border-radius: 100px; /* 둥근 테두리 */
  padding: 10px 20px; /* 상하, 좌우 패딩 */
  font-size: 30px; /* 폰트 크기 */
  cursor: pointer;
  font-weight: 900;
}
