@import url(https://fonts.cdnfonts.com/css/ibm-plex-mono-3);
/* @charset "UTF-8"; */
/* 웹폰트 */
/* @font-face {
  font-family: "Spoqa Han Sans Neo";
  font-style: normal;
  font-weight: 400;
  src: url("../font/SpoqaHanSansNeo-Regular.woff2");
  src: url("../font/SpoqaHanSansNeo-Regular.woff2") format("embedded-opentype"),
    url("../font/SpoqaHanSansNeo-Regular.woff2") format("woff2"),
    url("../font/SpoqaHanSansNeo-Regular.woff") format("woff");
}
@font-face {
  font-family: "Spoqa Han Sans Neo";
  font-style: normal;
  font-weight: 500;
  src: url("../font/SpoqaHanSansNeo-Medium.woff2");
  src: url("../font/SpoqaHanSansNeo-Medium.woff2") format("embedded-opentype"),
    url("../font/SpoqaHanSansNeo-Medium.woff2") format("woff2"),
    url("../font/SpoqaHanSansNeo-Medium.woff2") format("woff");
}
@font-face {
  font-family: "Spoqa Han Sans Neo";
  font-style: normal;
  font-weight: 700;
  src: url("../font/SpoqaHanSansNeo-Bold.woff2");
  src: url("../font/SpoqaHanSansNeo-Bold.woff2") format("embedded-opentype"),
    url("../font/SpoqaHanSansNeo-Bold.woff2") format("woff2"),
    url("../font/SpoqaHanSansNeo-Bold.woff2") format("woff");
} */
/* default */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  height: 100%;
}
@media (min-width: 1025px) {
  html ::-webkit-scrollbar {
    width: 16px;
    height: 16px;
    background-color: transparent;
  }
  html ::-webkit-scrollbar-thumb {
    background-color: #a6adb9;
    border-radius: 8px;
    background-clip: padding-box;
    border: 6px solid transparent;
  }
  html ::-webkit-scrollbar-thumb:hover {
    background-color: #98a0ae;
  }
  html ::-webkit-scrollbar-thumb:active {
    background-color: #8993a3;
  }
  html ::-webkit-scrollbar-track {
    background-color: #f9fafb;
  }
  html ::-webkit-scrollbar-corner {
    background-color: #f9fafb;
  }
  html * {
    scrollbar-width: thin;
    scrollbar-color: #a6adb9 #f9fafb;
  }
  html:hover {
    scrollbar-color: #98a0ae #f9fafb;
  }
  html:active {
    scrollbar-color: #8993a3 #f9fafb;
  }
}
@media (max-width: 1024.98px) {
  html {
    -webkit-text-size-adjust: none;
    overflow-y: auto;
  }
}

body {
  min-width: 360px;
  color: #374151;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Spoqa Han Sans Neo",
    "malgun gothic", Arial, sans-serif;
  letter-spacing: -0.01em;
  line-height: 1.3;
  word-wrap: break-word;
  word-break: break-all;
}
@media (min-width: 1025px) {
  body {
    transform-origin: 50% 0%;
    overflow-y: scroll;
  }
  body ::-webkit-scrollbar {
    width: 20px;
    height: 20px;
    background-color: transparent;
  }
  body ::-webkit-scrollbar-thumb {
    background-color: #d1d5db;
    border-radius: 10px;
    background-clip: padding-box;
    border: 8px solid transparent;
  }
  body ::-webkit-scrollbar-thumb:hover {
    background-color: #c3c8d0;
  }
  body ::-webkit-scrollbar-thumb:active {
    background-color: #b4bbc5;
  }
  body ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  body ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
  body * {
    scrollbar-width: thin;
    scrollbar-color: #d1d5db transparent;
  }
  body:hover {
    scrollbar-color: #c3c8d0 transparent;
  }
  body:active {
    scrollbar-color: #b4bbc5 transparent;
  }
}

header,
section,
article,
aside,
footer,
nav {
  display: block;
}

fieldset {
  display: block;
  margin: 0;
  border: 0;
  padding: 0;
}

pre {
  white-space: pre-wrap;
}

code {
  padding: 2px 4px;
  border-radius: 4px;
  border: 1px solid #e5e7eb;
  background-color: #f3f4f6;
  font-family: Consolas, monaco, monospace;
  letter-spacing: 0.01em;
  line-height: 1.6em;
}

img {
  max-width: 100%;
  border: 0;
}

.Mform-date {
  max-width: 120px;
  appearance: none;
  text-align: center !important;
  border: 1px solid #d1d5db;
  color: #374151;
  background: #fff;
  vertical-align: middle;
  font-size: 14px;
  height: 40px;
  border-radius: 9px;
  padding: 10px 16px;
  line-height: 30px;
  transition-duration: 0.2s;
  transition-property: color, border-color, box-shadow;
  will-change: color, border-color, box-shadow;
}

ul,
li,
ol {
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
dd {
  margin: 0;
}

a,
select,
button,
input,
textarea {
  font-family: inherit;
}

a {
  color: inherit;
  text-decoration: none;
}

button,
input[type="submit"] {
  margin: 0;
  padding: 0;
  border: 0;
  color: inherit;
  cursor: pointer;
  background: transparent;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.sr-only {
  position: absolute;
  left: -9999px;
  width: 1px;
  height: 1px;
  margin: 0 !important;
  font-size: 0;
  line-height: 0;
  overflow: hidden;
}

caption.sr-only {
  position: static;
  width: auto;
  height: 0;
}

/* 간격 */
/* 주요 마진셋 */
/* 간격 Preset */
.m-0 {
  margin: 0px !important;
}

.m-1 {
  margin: 4px !important;
}

.m-2 {
  margin: 8px !important;
}

.m-3 {
  margin: 12px !important;
}

.m-4 {
  margin: 16px !important;
}

.m-5 {
  margin: 20px !important;
}

.m-6 {
  margin: 24px !important;
}

.m-7 {
  margin: 28px !important;
}

.m-8 {
  margin: 32px !important;
}

.m-9 {
  margin: 36px !important;
}

.m-10 {
  margin: 40px !important;
}

.p-0 {
  padding: 0px !important;
}

.p-1 {
  padding: 4px !important;
}

.p-2 {
  padding: 8px !important;
}

.p-3 {
  padding: 12px !important;
}

.p-4 {
  padding: 16px !important;
}

.p-5 {
  padding: 20px !important;
}

.p-6 {
  padding: 24px !important;
}

.p-7 {
  padding: 28px !important;
}

.p-8 {
  padding: 32px !important;
}

.p-9 {
  padding: 36px !important;
}

.p-10 {
  padding: 40px !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mt-1 {
  margin-top: 4px !important;
}

.mt-2 {
  margin-top: 8px !important;
}

.mt-3 {
  margin-top: 12px !important;
}

.mt-4 {
  margin-top: 16px !important;
}

.mt-5 {
  margin-top: 20px !important;
}

.mt-6 {
  margin-top: 24px !important;
}

.mt-7 {
  margin-top: 28px !important;
}

.mt-8 {
  margin-top: 32px !important;
}

.mt-9 {
  margin-top: 36px !important;
}

.mt-10 {
  margin-top: 40px !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.ml-1 {
  margin-left: 4px !important;
}

.ml-2 {
  margin-left: 8px !important;
}

.ml-3 {
  margin-left: 12px !important;
}

.ml-4 {
  margin-left: 16px !important;
}

.ml-5 {
  margin-left: 20px !important;
}

.ml-6 {
  margin-left: 24px !important;
}

.ml-7 {
  margin-left: 28px !important;
}

.ml-8 {
  margin-left: 32px !important;
}

.ml-9 {
  margin-left: 36px !important;
}

.ml-10 {
  margin-left: 40px !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.mr-1 {
  margin-right: 4px !important;
}

.mr-2 {
  margin-right: 8px !important;
}

.mr-3 {
  margin-right: 12px !important;
}

.mr-4 {
  margin-right: 16px !important;
}

.mr-5 {
  margin-right: 20px !important;
}

.mr-6 {
  margin-right: 24px !important;
}

.mr-7 {
  margin-right: 28px !important;
}

.mr-8 {
  margin-right: 32px !important;
}

.mr-9 {
  margin-right: 36px !important;
}

.mr-10 {
  margin-right: 40px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-1 {
  margin-bottom: 4px !important;
}

.mb-2 {
  margin-bottom: 8px !important;
}

.mb-3 {
  margin-bottom: 12px !important;
}

.mb-4 {
  margin-bottom: 16px !important;
}

.mb-5 {
  margin-bottom: 20px !important;
}

.mb-6 {
  margin-bottom: 24px !important;
}

.mb-7 {
  margin-bottom: 28px !important;
}

.mb-8 {
  margin-bottom: 32px !important;
}

.mb-9 {
  margin-bottom: 36px !important;
}

.mb-10 {
  margin-bottom: 40px !important;
}

.pt-0 {
  padding-top: 0px !important;
}

.pt-1 {
  padding-top: 4px !important;
}

.pt-2 {
  padding-top: 8px !important;
}

.pt-3 {
  padding-top: 12px !important;
}

.pt-4 {
  padding-top: 16px !important;
}

.pt-5 {
  padding-top: 20px !important;
}

.pt-6 {
  padding-top: 24px !important;
}

.pt-7 {
  padding-top: 28px !important;
}

.pt-8 {
  padding-top: 32px !important;
}

.pt-9 {
  padding-top: 36px !important;
}

.pt-10 {
  padding-top: 40px !important;
}

.pl-0 {
  padding-left: 0px !important;
}

.pl-1 {
  padding-left: 4px !important;
}

.pl-2 {
  padding-left: 8px !important;
}

.pl-3 {
  padding-left: 12px !important;
}

.pl-4 {
  padding-left: 16px !important;
}

.pl-5 {
  padding-left: 20px !important;
}

.pl-6 {
  padding-left: 24px !important;
}

.pl-7 {
  padding-left: 28px !important;
}

.pl-8 {
  padding-left: 32px !important;
}

.pl-9 {
  padding-left: 36px !important;
}

.pl-10 {
  padding-left: 40px !important;
}

.pr-0 {
  padding-right: 0px !important;
}

.pr-1 {
  padding-right: 4px !important;
}

.pr-2 {
  padding-right: 8px !important;
}

.pr-3 {
  padding-right: 12px !important;
}

.pr-4 {
  padding-right: 16px !important;
}

.pr-5 {
  padding-right: 20px !important;
}

.pr-6 {
  padding-right: 24px !important;
}

.pr-7 {
  padding-right: 28px !important;
}

.pr-8 {
  padding-right: 32px !important;
}

.pr-9 {
  padding-right: 36px !important;
}

.pr-10 {
  padding-right: 40px !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.pb-1 {
  padding-bottom: 4px !important;
}

.pb-2 {
  padding-bottom: 8px !important;
}

.pb-3 {
  padding-bottom: 12px !important;
}

.pb-4 {
  padding-bottom: 16px !important;
}

.pb-5 {
  padding-bottom: 20px !important;
}

.pb-6 {
  padding-bottom: 24px !important;
}

.pb-7 {
  padding-bottom: 28px !important;
}

.pb-8 {
  padding-bottom: 32px !important;
}

.pb-9 {
  padding-bottom: 36px !important;
}

.pb-10 {
  padding-bottom: 40px !important;
}

@media (min-width: 1025px) {
  .m-lg-0 {
    margin: 0px !important;
  }
  .m-lg-1 {
    margin: 4px !important;
  }
  .m-lg-2 {
    margin: 8px !important;
  }
  .m-lg-3 {
    margin: 12px !important;
  }
  .m-lg-4 {
    margin: 16px !important;
  }
  .m-lg-5 {
    margin: 20px !important;
  }
  .m-lg-6 {
    margin: 24px !important;
  }
  .m-lg-7 {
    margin: 28px !important;
  }
  .m-lg-8 {
    margin: 32px !important;
  }
  .m-lg-9 {
    margin: 36px !important;
  }
  .m-lg-10 {
    margin: 40px !important;
  }
  .p-lg-0 {
    padding: 0px !important;
  }
  .p-lg-1 {
    padding: 4px !important;
  }
  .p-lg-2 {
    padding: 8px !important;
  }
  .p-lg-3 {
    padding: 12px !important;
  }
  .p-lg-4 {
    padding: 16px !important;
  }
  .p-lg-5 {
    padding: 20px !important;
  }
  .p-lg-6 {
    padding: 24px !important;
  }
  .p-lg-7 {
    padding: 28px !important;
  }
  .p-lg-8 {
    padding: 32px !important;
  }
  .p-lg-9 {
    padding: 36px !important;
  }
  .p-lg-10 {
    padding: 40px !important;
  }
  .mt-lg-0 {
    margin-top: 0px !important;
  }
  .mt-lg-1 {
    margin-top: 4px !important;
  }
  .mt-lg-2 {
    margin-top: 8px !important;
  }
  .mt-lg-3 {
    margin-top: 12px !important;
  }
  .mt-lg-4 {
    margin-top: 16px !important;
  }
  .mt-lg-5 {
    margin-top: 20px !important;
  }
  .mt-lg-6 {
    margin-top: 24px !important;
  }
  .mt-lg-7 {
    margin-top: 28px !important;
  }
  .mt-lg-8 {
    margin-top: 32px !important;
  }
  .mt-lg-9 {
    margin-top: 36px !important;
  }
  .mt-lg-10 {
    margin-top: 40px !important;
  }
  .ml-lg-0 {
    margin-left: 0px !important;
  }
  .ml-lg-1 {
    margin-left: 4px !important;
  }
  .ml-lg-2 {
    margin-left: 8px !important;
  }
  .ml-lg-3 {
    margin-left: 12px !important;
  }
  .ml-lg-4 {
    margin-left: 16px !important;
  }
  .ml-lg-5 {
    margin-left: 20px !important;
  }
  .ml-lg-6 {
    margin-left: 24px !important;
  }
  .ml-lg-7 {
    margin-left: 28px !important;
  }
  .ml-lg-8 {
    margin-left: 32px !important;
  }
  .ml-lg-9 {
    margin-left: 36px !important;
  }
  .ml-lg-10 {
    margin-left: 40px !important;
  }
  .mr-lg-0 {
    margin-right: 0px !important;
  }
  .mr-lg-1 {
    margin-right: 4px !important;
  }
  .mr-lg-2 {
    margin-right: 8px !important;
  }
  .mr-lg-3 {
    margin-right: 12px !important;
  }
  .mr-lg-4 {
    margin-right: 16px !important;
  }
  .mr-lg-5 {
    margin-right: 20px !important;
  }
  .mr-lg-6 {
    margin-right: 24px !important;
  }
  .mr-lg-7 {
    margin-right: 28px !important;
  }
  .mr-lg-8 {
    margin-right: 32px !important;
  }
  .mr-lg-9 {
    margin-right: 36px !important;
  }
  .mr-lg-10 {
    margin-right: 40px !important;
  }
  .mb-lg-0 {
    margin-bottom: 0px !important;
  }
  .mb-lg-1 {
    margin-bottom: 4px !important;
  }
  .mb-lg-2 {
    margin-bottom: 8px !important;
  }
  .mb-lg-3 {
    margin-bottom: 12px !important;
  }
  .mb-lg-4 {
    margin-bottom: 16px !important;
  }
  .mb-lg-5 {
    margin-bottom: 20px !important;
  }
  .mb-lg-6 {
    margin-bottom: 24px !important;
  }
  .mb-lg-7 {
    margin-bottom: 28px !important;
  }
  .mb-lg-8 {
    margin-bottom: 32px !important;
  }
  .mb-lg-9 {
    margin-bottom: 36px !important;
  }
  .mb-lg-10 {
    margin-bottom: 40px !important;
  }
  .pt-lg-0 {
    padding-top: 0px !important;
  }
  .pt-lg-1 {
    padding-top: 4px !important;
  }
  .pt-lg-2 {
    padding-top: 8px !important;
  }
  .pt-lg-3 {
    padding-top: 12px !important;
  }
  .pt-lg-4 {
    padding-top: 16px !important;
  }
  .pt-lg-5 {
    padding-top: 20px !important;
  }
  .pt-lg-6 {
    padding-top: 24px !important;
  }
  .pt-lg-7 {
    padding-top: 28px !important;
  }
  .pt-lg-8 {
    padding-top: 32px !important;
  }
  .pt-lg-9 {
    padding-top: 36px !important;
  }
  .pt-lg-10 {
    padding-top: 40px !important;
  }
  .pl-lg-0 {
    padding-left: 0px !important;
  }
  .pl-lg-1 {
    padding-left: 4px !important;
  }
  .pl-lg-2 {
    padding-left: 8px !important;
  }
  .pl-lg-3 {
    padding-left: 12px !important;
  }
  .pl-lg-4 {
    padding-left: 16px !important;
  }
  .pl-lg-5 {
    padding-left: 20px !important;
  }
  .pl-lg-6 {
    padding-left: 24px !important;
  }
  .pl-lg-7 {
    padding-left: 28px !important;
  }
  .pl-lg-8 {
    padding-left: 32px !important;
  }
  .pl-lg-9 {
    padding-left: 36px !important;
  }
  .pl-lg-10 {
    padding-left: 40px !important;
  }
  .pr-lg-0 {
    padding-right: 0px !important;
  }
  .pr-lg-1 {
    padding-right: 4px !important;
  }
  .pr-lg-2 {
    padding-right: 8px !important;
  }
  .pr-lg-3 {
    padding-right: 12px !important;
  }
  .pr-lg-4 {
    padding-right: 16px !important;
  }
  .pr-lg-5 {
    padding-right: 20px !important;
  }
  .pr-lg-6 {
    padding-right: 24px !important;
  }
  .pr-lg-7 {
    padding-right: 28px !important;
  }
  .pr-lg-8 {
    padding-right: 32px !important;
  }
  .pr-lg-9 {
    padding-right: 36px !important;
  }
  .pr-lg-10 {
    padding-right: 40px !important;
  }
  .pb-lg-0 {
    padding-bottom: 0px !important;
  }
  .pb-lg-1 {
    padding-bottom: 4px !important;
  }
  .pb-lg-2 {
    padding-bottom: 8px !important;
  }
  .pb-lg-3 {
    padding-bottom: 12px !important;
  }
  .pb-lg-4 {
    padding-bottom: 16px !important;
  }
  .pb-lg-5 {
    padding-bottom: 20px !important;
  }
  .pb-lg-6 {
    padding-bottom: 24px !important;
  }
  .pb-lg-7 {
    padding-bottom: 28px !important;
  }
  .pb-lg-8 {
    padding-bottom: 32px !important;
  }
  .pb-lg-9 {
    padding-bottom: 36px !important;
  }
  .pb-lg-10 {
    padding-bottom: 40px !important;
  }
}
@media (max-width: 1024.98px) {
  .m-md-0 {
    margin: 0px !important;
  }
  .m-md-1 {
    margin: 4px !important;
  }
  .m-md-2 {
    margin: 8px !important;
  }
  .m-md-3 {
    margin: 12px !important;
  }
  .m-md-4 {
    margin: 16px !important;
  }
  .m-md-5 {
    margin: 20px !important;
  }
  .m-md-6 {
    margin: 24px !important;
  }
  .m-md-7 {
    margin: 28px !important;
  }
  .m-md-8 {
    margin: 32px !important;
  }
  .m-md-9 {
    margin: 36px !important;
  }
  .m-md-10 {
    margin: 40px !important;
  }
  .p-md-0 {
    padding: 0px !important;
  }
  .p-md-1 {
    padding: 4px !important;
  }
  .p-md-2 {
    padding: 8px !important;
  }
  .p-md-3 {
    padding: 12px !important;
  }
  .p-md-4 {
    padding: 16px !important;
  }
  .p-md-5 {
    padding: 20px !important;
  }
  .p-md-6 {
    padding: 24px !important;
  }
  .p-md-7 {
    padding: 28px !important;
  }
  .p-md-8 {
    padding: 32px !important;
  }
  .p-md-9 {
    padding: 36px !important;
  }
  .p-md-10 {
    padding: 40px !important;
  }
  .mt-md-0 {
    margin-top: 0px !important;
  }
  .mt-md-1 {
    margin-top: 4px !important;
  }
  .mt-md-2 {
    margin-top: 8px !important;
  }
  .mt-md-3 {
    margin-top: 12px !important;
  }
  .mt-md-4 {
    margin-top: 16px !important;
  }
  .mt-md-5 {
    margin-top: 20px !important;
  }
  .mt-md-6 {
    margin-top: 24px !important;
  }
  .mt-md-7 {
    margin-top: 28px !important;
  }
  .mt-md-8 {
    margin-top: 32px !important;
  }
  .mt-md-9 {
    margin-top: 36px !important;
  }
  .mt-md-10 {
    margin-top: 40px !important;
  }
  .ml-md-0 {
    margin-left: 0px !important;
  }
  .ml-md-1 {
    margin-left: 4px !important;
  }
  .ml-md-2 {
    margin-left: 8px !important;
  }
  .ml-md-3 {
    margin-left: 12px !important;
  }
  .ml-md-4 {
    margin-left: 16px !important;
  }
  .ml-md-5 {
    margin-left: 20px !important;
  }
  .ml-md-6 {
    margin-left: 24px !important;
  }
  .ml-md-7 {
    margin-left: 28px !important;
  }
  .ml-md-8 {
    margin-left: 32px !important;
  }
  .ml-md-9 {
    margin-left: 36px !important;
  }
  .ml-md-10 {
    margin-left: 40px !important;
  }
  .mr-md-0 {
    margin-right: 0px !important;
  }
  .mr-md-1 {
    margin-right: 4px !important;
  }
  .mr-md-2 {
    margin-right: 8px !important;
  }
  .mr-md-3 {
    margin-right: 12px !important;
  }
  .mr-md-4 {
    margin-right: 16px !important;
  }
  .mr-md-5 {
    margin-right: 20px !important;
  }
  .mr-md-6 {
    margin-right: 24px !important;
  }
  .mr-md-7 {
    margin-right: 28px !important;
  }
  .mr-md-8 {
    margin-right: 32px !important;
  }
  .mr-md-9 {
    margin-right: 36px !important;
  }
  .mr-md-10 {
    margin-right: 40px !important;
  }
  .mb-md-0 {
    margin-bottom: 0px !important;
  }
  .mb-md-1 {
    margin-bottom: 4px !important;
  }
  .mb-md-2 {
    margin-bottom: 8px !important;
  }
  .mb-md-3 {
    margin-bottom: 12px !important;
  }
  .mb-md-4 {
    margin-bottom: 16px !important;
  }
  .mb-md-5 {
    margin-bottom: 20px !important;
  }
  .mb-md-6 {
    margin-bottom: 24px !important;
  }
  .mb-md-7 {
    margin-bottom: 28px !important;
  }
  .mb-md-8 {
    margin-bottom: 32px !important;
  }
  .mb-md-9 {
    margin-bottom: 36px !important;
  }
  .mb-md-10 {
    margin-bottom: 40px !important;
  }
  .pt-md-0 {
    padding-top: 0px !important;
  }
  .pt-md-1 {
    padding-top: 4px !important;
  }
  .pt-md-2 {
    padding-top: 8px !important;
  }
  .pt-md-3 {
    padding-top: 12px !important;
  }
  .pt-md-4 {
    padding-top: 16px !important;
  }
  .pt-md-5 {
    padding-top: 20px !important;
  }
  .pt-md-6 {
    padding-top: 24px !important;
  }
  .pt-md-7 {
    padding-top: 28px !important;
  }
  .pt-md-8 {
    padding-top: 32px !important;
  }
  .pt-md-9 {
    padding-top: 36px !important;
  }
  .pt-md-10 {
    padding-top: 40px !important;
  }
  .pl-md-0 {
    padding-left: 0px !important;
  }
  .pl-md-1 {
    padding-left: 4px !important;
  }
  .pl-md-2 {
    padding-left: 8px !important;
  }
  .pl-md-3 {
    padding-left: 12px !important;
  }
  .pl-md-4 {
    padding-left: 16px !important;
  }
  .pl-md-5 {
    padding-left: 20px !important;
  }
  .pl-md-6 {
    padding-left: 24px !important;
  }
  .pl-md-7 {
    padding-left: 28px !important;
  }
  .pl-md-8 {
    padding-left: 32px !important;
  }
  .pl-md-9 {
    padding-left: 36px !important;
  }
  .pl-md-10 {
    padding-left: 40px !important;
  }
  .pr-md-0 {
    padding-right: 0px !important;
  }
  .pr-md-1 {
    padding-right: 4px !important;
  }
  .pr-md-2 {
    padding-right: 8px !important;
  }
  .pr-md-3 {
    padding-right: 12px !important;
  }
  .pr-md-4 {
    padding-right: 16px !important;
  }
  .pr-md-5 {
    padding-right: 20px !important;
  }
  .pr-md-6 {
    padding-right: 24px !important;
  }
  .pr-md-7 {
    padding-right: 28px !important;
  }
  .pr-md-8 {
    padding-right: 32px !important;
  }
  .pr-md-9 {
    padding-right: 36px !important;
  }
  .pr-md-10 {
    padding-right: 40px !important;
  }
  .pb-md-0 {
    padding-bottom: 0px !important;
  }
  .pb-md-1 {
    padding-bottom: 4px !important;
  }
  .pb-md-2 {
    padding-bottom: 8px !important;
  }
  .pb-md-3 {
    padding-bottom: 12px !important;
  }
  .pb-md-4 {
    padding-bottom: 16px !important;
  }
  .pb-md-5 {
    padding-bottom: 20px !important;
  }
  .pb-md-6 {
    padding-bottom: 24px !important;
  }
  .pb-md-7 {
    padding-bottom: 28px !important;
  }
  .pb-md-8 {
    padding-bottom: 32px !important;
  }
  .pb-md-9 {
    padding-bottom: 36px !important;
  }
  .pb-md-10 {
    padding-bottom: 40px !important;
  }
}
.card + .card {
  /* margin-top: 20px; */
}
@media (max-width: 1024.98px) {
  .card + .card {
    margin-top: 12px;
  }
}

.clearfix {
  position: relative;
}
.clearfix:after {
  display: block;
  clear: both;
  content: "";
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 1025px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (max-width: 1024.98px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (max-width: 767.98px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
.vertical-top {
  vertical-align: top !important;
}

.vertical-middle {
  vertical-align: middle !important;
}

.vertical-bottom {
  vertical-align: bottom !important;
}

.flex-container {
  display: flex;
}
.flex-container .flex-item {
  flex: 1 1 50%;
}
.flex-container .flex-item-fit {
  flex: 0 0 auto;
}

.flex-justify-between {
  justify-content: space-between;
}

.flex-justify-center {
  justify-content: center;
}

.flex-align-stretch {
  align-items: stretch;
}

.flex-align-center {
  align-items: center;
}

@media (max-width: 1024.98px) {
  .d-up-lg {
    display: none !important;
  }
}

@media (max-width: 767.98px) {
  .d-up-md {
    display: none !important;
  }
}

@media (min-width: 1025px) {
  .d-down-md {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .d-down-sm {
    display: none !important;
  }
}

.text-default {
  color: #374151 !important;
}

.text-white {
  color: #fff !important;
}

.text-black {
  color: #000 !important;
}

.text-primary {
  color: #1d4ed8 !important;
}

.text-darkprimary {
  color: #1e3a8a !important;
}

.text-secondary {
  color: #f77120 !important;
}

.text-gray {
  color: #4b5563 !important;
}

.text-lightgray {
  color: #7e8594 !important;
}

.text-darkgray {
  color: #1f2937 !important;
}

.text-valid {
  color: #10b981 !important;
}

.text-invalid {
  color: #ef4444 !important;
}

.bg-default {
  background-color: #374151 !important;
  color: #fff;
}

.bg-primary {
  background-color: #357fed !important;
  color: #fff;
}

.bg-darkprimary {
  background-color: #2563eb !important;
  color: #fff;
}

.bg-darkgray {
  background-color: #1f2937 !important;
  color: #fff;
}

.bg-secondary {
  background-color: #f77120 !important;
  color: #fff;
}

.bg-invalid {
  background-color: #e03131 !important;
  color: #fff;
}

.bg-invalid-op {
  background-color: rgba(224, 49, 49, 0.5) !important;
  color: #fff;
}

.bg-valid {
  background-color: #099268 !important;
  color: #fff;
}

.bg-lightgray {
  background-color: #f9fafb !important;
}

.bg-gray {
  background-color: #f3f4f6 !important;
}

.bg-lightprimary {
  background-color: #eff6ff !important;
}

.bg-white {
  background-color: #fff !important;
}

.text-sm {
  font-size: 12px;
}

.text-md {
  font-size: 14px;
}

.text-lg {
  font-size: 16px;
}

.text-normal {
  font-weight: normal !important;
}

.text-medium {
  font-weight: 500 !important;
}

.text-bold {
  font-weight: bold !important;
}

.link {
  position: relative;
  display: inline-flex;
  align-items: center;
  min-height: 18px;
  height: auto;
  padding-bottom: 1px;
  font-weight: 400;
  color: #2563eb;
  text-decoration: none;
}
.link .ico {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -20px;
}
.link:focus,
.link:hover {
  color: #1d4ed8;
  text-decoration: underline;
}
.link[target="_blank"] {
  position: relative;
  padding-left: 20px;
}
.link[target="_blank"]:before {
  content: "";
  position: absolute;
  left: 0;
  width: 12px;
  height: 12px;
  background-size: 12px;
}

.text-unit {
  color: #4b5563;
  text-align: right;
  margin-bottom: 5px;
  font-size: 12px;
}

.text-guide {
  color: #4b5563;
  font-size: 14px;
  word-break: break-all;
}
.text-guide.text-invalid:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  margin-top: -2px;
  margin-right: 4px;
  width: 12px;
  height: 12px;
  background-size: cover;
}
.text-guide.text-guide-tooltip {
  position: absolute;
  background: #fff;
  padding: 12px;
  z-index: 10;
  margin-top: 4px;
  border-radius: 8px;
  min-width: 160px;
  transition-duration: 0.2s;
}
.text-guide.text-guide-tooltip:not(.text-invalid) {
  border: 2px solid #374151;
}
.text-guide.text-guide-tooltip:not(.text-invalid):after {
  content: "";
  position: absolute;
  left: 20px;
  top: -8px;
  width: 16px;
  height: 10px;
  z-index: 0;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='10' viewBox='0 0 16 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_703_3150)'%3E%3Cpath d='M15.2897 8.5534L15.3097 8.57625L15.3324 8.59651C15.5246 8.76824 15.5358 8.96244 15.4466 9.14171C15.3462 9.34328 15.1237 9.5 14.8638 9.5H1.07369C0.573997 9.5 0.327145 8.9062 0.642131 8.56034L0.642195 8.5604L0.648333 8.5534L7.54312 0.693687C7.54321 0.693582 7.5433 0.693478 7.54339 0.693373C7.77057 0.435569 8.16732 0.435547 8.39453 0.693308C8.39464 0.693435 8.39475 0.693561 8.39486 0.693688L15.2897 8.5534Z' fill='%23fff' stroke='%23374151' stroke-width='2px'/%3E%3Cpath d='M0 9H16' stroke='%23fff' stroke-width='2'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_703_3150'%3E%3Crect width='16' height='10' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
  background-size: cover;
  background-repeat: no-repeat;
}
.text-guide.text-guide-tooltip.text-invalid {
  border: 2px solid #ef4444;
}
.text-guide.text-guide-tooltip.text-invalid:after {
  content: "";
  position: absolute;
  left: 20px;
  top: -8px;
  width: 16px;
  height: 10px;
  z-index: 0;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='10' viewBox='0 0 16 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_703_3150)'%3E%3Cpath d='M15.2897 8.5534L15.3097 8.57625L15.3324 8.59651C15.5246 8.76824 15.5358 8.96244 15.4466 9.14171C15.3462 9.34328 15.1237 9.5 14.8638 9.5H1.07369C0.573997 9.5 0.327145 8.9062 0.642131 8.56034L0.642195 8.5604L0.648333 8.5534L7.54312 0.693687C7.54321 0.693582 7.5433 0.693478 7.54339 0.693373C7.77057 0.435569 8.16732 0.435547 8.39453 0.693308C8.39464 0.693435 8.39475 0.693561 8.39486 0.693688L15.2897 8.5534Z' fill='%23fff' stroke='%23ef4444' stroke-width='2px'/%3E%3Cpath d='M0 9H16' stroke='%23fff' stroke-width='2'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_703_3150'%3E%3Crect width='16' height='10' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
  background-size: cover;
  background-repeat: no-repeat;
}
@media (min-width: 1025px) {
  .text-guide {
    flex: 0 1 auto;
  }
}
@media (max-width: 1024.98px) {
  .text-guide {
    flex: 1 1 100%;
  }
}

/*-------------------------------------
zTree Style

version:	3.5.19
author:		Hunter.z
email:		hunter.z@263.net
website:	http://code.google.com/p/jquerytree/

-------------------------------------*/
.ztree * {
  padding: 0;
  margin: 0;
  font-size: 12px;
  font-family: Verdana, Arial, Helvetica, AppleGothic, sans-serif;
}

.ztree {
  margin: 0;
  padding: 5px;
  color: #333;
}

.ztree li {
  padding: 0;
  margin: 0;
  list-style: none;
  line-height: 14px;
  text-align: left;
  white-space: nowrap;
  outline: 0;
}

.ztree li ul {
  margin: 0;
  padding: 0 0 0 18px;
}

.ztree li ul.line {
}

.ztree li a {
  padding: 1px 3px 0 0;
  margin: 0;
  cursor: pointer;
  height: 17px;
  color: #333;
  background-color: transparent;
  text-decoration: none;
  vertical-align: top;
  display: inline-block;
}

.ztree li a:hover {
  text-decoration: underline;
}

.ztree li a.curSelectedNode {
  padding-top: 0px;
  background-color: #ffe6b0;
  color: black;
  height: 16px;
  border: 1px #ffb951 solid;
  opacity: 0.8;
}

.ztree li a.curSelectedNode_Edit {
  padding-top: 0px;
  background-color: #ffe6b0;
  color: black;
  height: 16px;
  border: 1px #ffb951 solid;
  opacity: 0.8;
}

.ztree li a.tmpTargetNode_inner {
  padding-top: 0px;
  background-color: #316ac5;
  color: white;
  height: 16px;
  border: 1px #316ac5 solid;
  opacity: 0.8;
  filter: alpha(opacity=80);
}

/* .ztree li a.tmpTargetNode_prev {}
.ztree li a.tmpTargetNode_next {} */
.ztree li a input.rename {
  height: 14px;
  width: 80px;
  padding: 0;
  margin: 0;
  font-size: 12px;
  border: 1px #7ec4cc solid;
  *border: 0px;
}

.ztree li span {
  line-height: 16px;
  margin-right: 2px;
}

.ztree li span.button {
  line-height: 0;
  margin: 0;
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: middle;
  border: 0 none;
  cursor: pointer;
  outline: none;
  background-color: transparent;
  background-repeat: no-repeat;
  background-attachment: scroll;
}

.ztree li span.button.chk {
  width: 13px;
  height: 13px;
  margin: 0 3px 0 0;
  cursor: auto;
}

.ztree li span.button.chk.checkbox_false_full {
  background-position: 0 0;
}

.ztree li span.button.chk.checkbox_false_full_focus {
  background-position: 0 -14px;
}

.ztree li span.button.chk.checkbox_false_part {
  background-position: 0 -28px;
}

.ztree li span.button.chk.checkbox_false_part_focus {
  background-position: 0 -42px;
}

.ztree li span.button.chk.checkbox_false_disable {
  background-position: 0 -56px;
}

.ztree li span.button.chk.checkbox_true_full {
  background-position: -14px 0;
}

.ztree li span.button.chk.checkbox_true_full_focus {
  background-position: -14px -14px;
}

.ztree li span.button.chk.checkbox_true_part {
  background-position: -14px -28px;
}

.ztree li span.button.chk.checkbox_true_part_focus {
  background-position: -14px -42px;
}

.ztree li span.button.chk.checkbox_true_disable {
  background-position: -14px -56px;
}

.ztree li span.button.chk.radio_false_full {
  background-position: -28px 0;
}

.ztree li span.button.chk.radio_false_full_focus {
  background-position: -28px -14px;
}

.ztree li span.button.chk.radio_false_part {
  background-position: -28px -28px;
}

.ztree li span.button.chk.radio_false_part_focus {
  background-position: -28px -42px;
}

.ztree li span.button.chk.radio_false_disable {
  background-position: -28px -56px;
}

.ztree li span.button.chk.radio_true_full {
  background-position: -42px 0;
}

.ztree li span.button.chk.radio_true_full_focus {
  background-position: -42px -14px;
}

.ztree li span.button.chk.radio_true_part {
  background-position: -42px -28px;
}

.ztree li span.button.chk.radio_true_part_focus {
  background-position: -42px -42px;
}

.ztree li span.button.chk.radio_true_disable {
  background-position: -42px -56px;
}

.ztree li span.button.switch {
  width: 18px;
  height: 18px;
}

.ztree li span.button.root_open {
  background-position: -92px -54px;
}

.ztree li span.button.root_close {
  background-position: -74px -54px;
}

.ztree li span.button.roots_open {
  background-position: -92px 0;
}

.ztree li span.button.roots_close {
  background-position: -74px 0;
}

.ztree li span.button.center_open {
  background-position: -92px -18px;
}

.ztree li span.button.center_close {
  background-position: -74px -18px;
}

.ztree li span.button.bottom_open {
  background-position: -92px -36px;
}

.ztree li span.button.bottom_close {
  background-position: -74px -36px;
}

.ztree li span.button.noline_open {
  background-position: -92px -72px;
}

.ztree li span.button.noline_close {
  background-position: -74px -72px;
}

.ztree li span.button.root_docu {
  background: none;
}

.ztree li span.button.roots_docu {
  background-position: -56px 0;
}

.ztree li span.button.center_docu {
  background-position: -56px -18px;
}

.ztree li span.button.bottom_docu {
  background-position: -56px -36px;
}

.ztree li span.button.noline_docu {
  background: none;
}

.ztree li span.button.ico_open {
  margin-right: 2px;
  background-position: -110px -16px;
  vertical-align: top;
  *vertical-align: middle;
}

.ztree li span.button.ico_close {
  margin-right: 2px;
  background-position: -110px 0;
  vertical-align: top;
  *vertical-align: middle;
}

.ztree li span.button.ico_docu {
  margin-right: 2px;
  background-position: -110px -32px;
  vertical-align: top;
  *vertical-align: middle;
}

.ztree li span.button.edit {
  margin-right: 2px;
  background-position: -110px -48px;
  vertical-align: top;
  *vertical-align: middle;
}

.ztree li span.button.remove {
  margin-right: 2px;
  background-position: -110px -64px;
  vertical-align: top;
  *vertical-align: middle;
}

.ztree li span.button.ico_loading {
  margin-right: 2px;
  vertical-align: top;
  *vertical-align: middle;
}

ul.tmpTargetzTree {
  background-color: #ffe6b0;
  opacity: 0.8;
  filter: alpha(opacity=80);
}

span.tmpzTreeMove_arrow {
  width: 16px;
  height: 16px;
  display: inline-block;
  padding: 0;
  margin: 2px 0 0 1px;
  border: 0 none;
  position: absolute;
  background-color: transparent;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: -110px -80px;
}

ul.ztree.zTreeDragUL {
  margin: 0;
  padding: 0;
  position: absolute;
  width: auto;
  height: auto;
  overflow: hidden;
  background-color: #cfcfcf;
  border: 1px #00b83f dotted;
  opacity: 0.8;
  filter: alpha(opacity=80);
}

.zTreeMask {
  z-index: 10000;
  background-color: #cfcfcf;
  opacity: 0;
  filter: alpha(opacity=0);
  position: absolute;
}

/* level style*/
/*.ztree li span.button.level0 {
	display:none;
}
.ztree li ul.level0 {
	padding:0;
	background:none;
}*/
/* z-tree */
.ztree {
  position: relative;
  padding: 2px 6px;
  z-index: 15;
}
.ztree.tmpTargetzTree {
  background-color: transparent !important;
}
.ztree li ul {
  padding: 0 0 0 16px;
}
.ztree li span.button {
  position: relative;
  z-index: 2;
}
.ztree li span.button.switch {
  width: 15px;
  height: 28px;
}
.ztree li span.button.noline_close {
  background-size: 8px auto;
  background-position: center 10px;
}
.ztree li span.button.noline_open {
  background-size: 8px auto;
  background-position: center 10px;
}
.ztree li span.button.ico_open,
.ztree li span.button.ico_close,
.ztree li span.button.ico-folder-md {
  margin-right: 4px;
  background-size: 16px auto;
  background-position: center center;
}
.ztree li span.button.ico_docu,
.ztree li span.button.ico-page-md {
  margin-right: 4px;
  background-size: 16px auto;
  background-position: center center;
}
.ztree li span.button.ico-site-md,
.ztree li span.button.ico-site-md_ico_docu,
.ztree li span.button.ico-site-md_ico_open,
.ztree li span.button.ico-site-md_ico_close {
  margin-right: 4px;
  background-size: 16px auto;
  background-position: center center;
}
.ztree li span.button.ico-editpage-md,
.ztree li span.button.ico-editpage-md_ico_docu,
.ztree li span.button.ico-editpage-md_ico_open,
.ztree li span.button.ico-editpage-md_ico_close {
  margin-right: 4px;
  background-size: 16px auto;
  background-position: center center;
}
.ztree li span.button.ico-pagepgm-md,
.ztree li span.button.ico-pagepgm-md_ico_docu,
.ztree li span.button.ico-pagepgm-md_ico_open,
.ztree li span.button.ico-pagepgm-md_ico_close {
  margin-right: 4px;
  background-size: 16px auto;
  background-position: center center;
}
.ztree li span.button.ico-pagebbs-md,
.ztree li span.button.ico-pagebbs-md_ico_docu,
.ztree li span.button.ico-pagebbs-md_ico_open,
.ztree li span.button.ico-pagebbs-md_ico_close {
  margin-right: 4px;
  background-size: 16px auto;
  background-position: center center;
}
.ztree li span.button.ico-newwindow-md,
.ztree li span.button.ico-newwindow-md_ico_docu,
.ztree li span.button.ico-newwindow-md_ico_open,
.ztree li span.button.ico-newwindow-md_ico_close {
  margin-right: 4px;
  background-size: 16px auto;
  background-position: center center;
}
.ztree li span.button.ico-code-md,
.ztree li span.button.ico-code-md_ico_docu,
.ztree li span.button.ico-code-md_ico_open,
.ztree li span.button.ico-code-md_ico_close {
  margin-right: 4px;
  background-size: 16px auto;
  background-position: center center;
}
.ztree li a {
  display: inline-flex;
  align-items: center;
  min-width: calc(100% - 10px);
  height: 28px;
  padding-top: 0px;
}
.ztree li a:hover {
  text-decoration: auto;
}
.ztree li a:hover .node_name {
  color: #1d4ed8;
}
.ztree li a .node_name {
  padding-right: 8px;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Spoqa Han Sans Neo",
    "malgun gothic", Arial, sans-serif;
  font-size: 14px;
  line-height: 1.3rem;
  color: #4b5563;
}
.ztree li a.tmpTargetNode_inner {
  position: relative;
  height: 28px;
  background-color: transparent;
  border: 0;
}
.ztree li a.disabled .label-disabled {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 48px;
  height: 24px;
  margin-right: 4px;
  padding: 0px 8px;
  background-color: #7e8594;
  border-radius: 14px;
  font-weight: 500;
  /* font-family: -apple-system, BlinkMacSystemFont, "Spoqa Han Sans Neo",
    "malgun gothic", Arial, sans-serif; */
  font-size: 12px;
  color: #fff;
}
.ztree li a.disabled .node_name {
  color: #7e8594;
}
.ztree li a.curSelectedNode {
  position: relative;
  height: 28px;
  background-color: transparent;
  border: 0;
  opacity: 1;
}
.ztree li a.curSelectedNode .node_name {
  font-weight: 500;
  color: #1d4ed8;
}
.ztree li a.curSelectedNode .button.ico_open,
.ztree li a.curSelectedNode .button.ico_close,
.ztree li a.curSelectedNode .button.ico-folder-md {
  background-size: 16px auto;
  background-position: center center;
}
.ztree li a.curSelectedNode .button.ico_docu,
.ztree li a.curSelectedNode .button.ico-page-md {
  background-size: 16px auto;
  background-position: center center;
}
.ztree li a.curSelectedNode .button.ico-site-md,
.ztree li a.curSelectedNode .button.ico-site-md_ico_docu,
.ztree li a.curSelectedNode .button.ico-site-md_ico_open,
.ztree li a.curSelectedNode .button.ico-site-md_ico_close {
}
.ztree li a.curSelectedNode .button.ico-editpage-md,
.ztree li a.curSelectedNode .button.ico-editpage-md_ico_docu,
.ztree li a.curSelectedNode .button.ico-editpage-md_ico_open,
.ztree li a.curSelectedNode .button.ico-editpage-md_ico_close {
}
.ztree li a.curSelectedNode .button.ico-pagepgm-md,
.ztree li a.curSelectedNode .button.ico-pagepgm-md_ico_docu,
.ztree li a.curSelectedNode .button.ico-pagepgm-md_ico_open,
.ztree li a.curSelectedNode .button.ico-pagepgm-md_ico_close {
}
.ztree li a.curSelectedNode .button.ico-pagebbs-md,
.ztree li a.curSelectedNode .button.ico-pagebbs-md_ico_docu,
.ztree li a.curSelectedNode .button.ico-pagebbs-md_ico_open,
.ztree li a.curSelectedNode .button.ico-pagebbs-md_ico_close {
}
.ztree li a.curSelectedNode .button.ico-newwindow-md,
.ztree li a.curSelectedNode .button.ico-newwindow-md_ico_docu,
.ztree li a.curSelectedNode .button.ico-newwindow-md_ico_open,
.ztree li a.curSelectedNode .button.ico-newwindow-md_ico_close {
}
.ztree li a.curSelectedNode .button.ico-code-md,
.ztree li a.curSelectedNode .button.ico-code-md_ico_docu,
.ztree li a.curSelectedNode .button.ico-code-md_ico_open,
.ztree li a.curSelectedNode .button.ico-code-md_ico_close {
}
.ztree li a.curSelectedNode_Edit .node_name input {
  height: 24px;
  width: 100%;
  border: 0;
  padding: 0 8px;
  /* font-family: -apple-system, BlinkMacSystemFont, "Spoqa Han Sans Neo",
    "malgun gothic", Arial, sans-serif; */
  font-weight: 400;
  font-size: 14px;
  color: #7e8594;
}
.ztree li a.curSelectedNode_Edit .node_name input:focus {
  outline-offset: 0;
  outline: 1px solid #d1d5db;
  border-radius: 6px;
}
.ztree li span.button.chk {
  width: 16px;
  height: 16px;
  background: #fff;
  border-radius: 3px;
  border: 1px solid #a6adb9;
}
.ztree li span.button.chk.checkbox_false_full,
.ztree li span.button.chk.checkbox_false_full_focus {
  border-radius: 3px;
  border: 1px solid #a6adb9;
}
.ztree li span.button.chk.checkbox_true_full,
.ztree li span.button.chk.checkbox_true_full_focus {
  border-color: #357fed;
  background-size: 16px auto;
  background-position: center -2px;
}

span.tmpzTreeMove_arrow {
  width: 12px;
  height: 12px;
  background-repeat: no-repeat;
  background-position: 0 -2px;
  background-size: 12px auto;
  z-index: 20;
}

/* 트리메뉴 영역 기본값 */
.tree-menu-area {
  width: 100%;
  border-radius: 16px;
  background-color: #f9fafb;
  background-color: #fff;
}
.tree-menu-area .tree-header {
  height: 56px;
  padding: 16px 0;
  border-bottom: 1px solid #f3f4f6;
}
.tree-menu-area .tree-header .btn-group {
  margin: 0;
}
.tree-menu-area .tree-body {
  height: 0%;
  padding: 2px 0 2px 2px;
  border-radius: 16px;
}
.tree-menu-area .tree-body .scroll-araa {
  overflow-y: auto;
}
.tree-menu-area .tree-footer {
  padding: 16px 9px;
  border-top: 1px solid #f3f4f6;
}
.tree-menu-area .tree-footer .btn-group {
  margin: 0;
}
.tree-menu-area .btn-group-center {
  word-spacing: normal;
}
@media (min-width: 1025px) {
  .tree-menu-area {
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 112px;
    min-width: 251px;
    /* max-width: 270px; */
    min-height: 300px;
    max-height: calc(100vh - (80px + 32px + 130px));
  }
  .tree-menu-area .tree-header {
    flex: 0 0 auto;
  }
  .tree-menu-area .tree-footer {
    flex: 0 0 auto;
  }
  .tree-menu-area .tree-body {
    display: flex;
    flex: 1 1 auto;
    min-height: 100%;
  }
  .tree-menu-area .tree-body .scroll-araa {
    overflow: scroll;
  }
}
@media (max-width: 1024.98px) {
  .tree-menu-area {
    margin-bottom: 12px;
    border-radius: 0;
  }
  .tree-menu-area .tree-body {
    min-height: 280px;
  }
  .tree-menu-area .tree-body .scroll-araa {
    height: 280px;
  }
}
.tree-menu-area.card-in-tree {
  background-color: #f9fafb;
  border: 1px solid #e5e7eb;
}
.tree-menu-area.card-in-tree .tree-header {
  border-bottom: 1px solid #e5e7eb;
}
.tree-menu-area.card-in-tree .tree-body .scroll-araa {
  width: 100%;
}
@media (max-width: 1439.98px) {
  .tree-menu-area.card-in-tree {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }
}
@media (max-width: 1024.98px) {
  .tree-menu-area.card-in-tree {
    margin-bottom: 0;
    border-radius: 16px;
  }
}

.tree-menu-area .no-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.tree-menu-area .no-data strong {
  font-weight: 500;
  font-size: 14px;
  color: #4b5563;
}
.tree-menu-area .no-data span {
  margin-top: 4px;
  font-size: 12px;
  color: #7e8594;
}
.tree-menu-area .no-data .ztree {
  display: none;
}
@media (min-width: 1025px) {
  .tree-menu-area.no-data .tree-body,
  .tree-menu-area.no-data .no-data {
    min-height: 384px;
    width: 100%;
  }
}
@media (max-width: 1024.98px) {
  .tree-menu-area.no-data .tree-body,
  .tree-menu-area.no-data .no-data {
    min-height: 280px;
  }
}

/* 트리메뉴 레이아웃 */
.tree-in-wrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
@media (min-width: 1025px) {
  .tree-in-wrap .tree-menu-area {
    max-width: 270px;
  }
  .tree-in-wrap .tree-menu-area + .card,
  .tree-in-wrap .tree-menu-area + .card-wrap {
    width: calc(100% - 294px);
    margin-left: 24px;
  }
}
@media (max-width: 1024.98px) {
  .tree-in-wrap {
    flex-direction: column;
  }
  .tree-in-wrap .card {
    width: 100%;
    border-radius: 0;
  }
}

/* sortable */
.table-sortable .handle {
  cursor: pointer;
}
.table-sortable th:first-child,
.table-sortable td:first-child {
  padding: 0;
}
.table-sortable .form-control-sm {
  min-width: 116px;
}
.table-sortable .form-control-sm + .dropdown .option-list,
.table-sortable .form-control-sm + .dropdown-sm .option-list {
  min-width: 96px;
  text-align: left;
}
.table-sortable .form-control-sm.full {
  width: 100%;
}
@media (max-width: 1024.98px) {
  .table-sortable + .btn-group-between .btn-group-flex {
    display: flex;
    width: calc(100% + 8px);
    margin-left: -4px;
    margin-right: -4px;
  }
  .table-sortable + .btn-group-between .btn-group-flex > * {
    flex: 1 1 auto;
  }
}

/*

	Dropzone.js
	drag And Drop file upload

 */
.file-upload {
  position: relative;
  width: 100%;
  min-height: 64px;
}
.file-upload.col2 .preview-area {
  display: flex;
  flex-wrap: wrap;
  column-gap: 4px;
}
.file-upload.col2 .preview-area .file-item {
  min-width: 0;
  flex: 0 0 calc(50% - 2px) !important;
}
.file-upload .upload-area {
  position: relative;
  width: 100%;
  min-height: 130px;
  padding: 19px 12px;
  background-color: #fff;
  border: 1px dashed #a6adb9;
  border-radius: 16px;
}
.file-upload .upload-area::before {
  content: "";
  position: absolute;
  top: 20px;
  left: calc(50% - 20px);
  width: 40px;
  height: 40px;
  background-size: 40px 40px;
}
.file-upload .upload-area + .btn-all-delete {
  margin-top: 8px;
  display: none;
}
.file-upload .guide-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 52px;
  text-align: center;
}
.file-upload .guide-text p {
  font-weight: 400;
  font-size: 14px;
  letter-spacing: -0.01em;
  line-height: 18px;
  color: #1f2937;
}
.file-upload .guide-text p .btn {
  display: inline-block;
  margin-top: -1px;
  vertical-align: top;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: -0.01em;
  line-height: 18px;
  text-decoration: underline;
  text-underline-offset: 2px;
  color: #2563eb;
}
.file-upload .guide-text span {
  margin-top: 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  letter-spacing: -0.01em;
  color: #7e8594;
}
.file-upload .file-item {
  position: relative;
  overflow: hidden;
}
.file-upload .progress-track {
  position: absolute;
  left: 20px;
  right: 20px;
  bottom: 8px;
  height: 4px;
  border-radius: 2px;
  background: #d1d5db;
}
.file-upload .progress-track .progress-bar {
  border-radius: 2px;
  height: 100%;
  background: #357fed;
}
.file-upload .preview-area .file-item {
  margin-top: 4px;
  flex: 1 1 100%;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 16px 12px;
  background: #f3f4f6;
  border-radius: 12px;
}
.file-upload .preview-area .file-item .preview {
  display: inline-block;
  min-width: 40px;
  max-height: auto;
  width: 40px;
  height: 40px;
  border-radius: 8px;
}
.file-upload .preview-area .file-item .preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-fit: scale-down;
  border-radius: 8px;
}
.file-upload .preview-area .info {
  width: calc(100% - 92px);
  margin-left: 12px;
  margin-right: 12px;
}
.file-upload .preview-area .info .name {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.2em;
  color: #374151;
  display: block;
  margin: 0.1em 0;
}
@media (min-width: 1025px) {
  .file-upload .preview-area .info .name {
    max-width: 100%;
    white-space: nowrap;
    max-height: 1.2em;
    line-height: 1.2em;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
.file-upload .preview-area .info .size {
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  letter-spacing: -0.01em;
  color: #7e8594;
}
.file-upload .preview-area .info .size strong {
  font-weight: 400;
}
.file-upload .preview-area .btn-area {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
}
.file-upload .preview-area .btn-area .btn {
  width: 32px;
  height: 32px;
  border-radius: 6px;
}
.file-upload .preview-area .btn-area .btn .ico {
  margin-right: 0;
}
.file-upload .preview-area .btn-area .btn + .btn {
  margin-left: 4px;
}
.file-upload .preview-area .btn-area .btn:hover {
  background-color: #e5e7eb;
}
.file-upload .preview-area-single {
  position: absolute;
  top: 0;
  width: 100%;
}
.file-upload .preview-area-single .file-col {
  margin-top: 0;
}

/* ckeditor.inuix.neibis */
/* inuixEditor : 기본 toolbar에 대한 스타일의 기본값입니다. */
/* 페이지 정보 버튼 */
.page-info-header button {
  display: inline-block;
  width: 16px;
  height: 16px;
  background-size: 16px 16px;
  overflow: hidden;
}
.page-info-header button.on {
  transform: rotate(180deg);
}

/* tab menu */
.tab-list.type2 li span {
  font-weight: 500;
}
.tab-list.type2 li.on {
  position: relative;
}
.tab-list.type2 li.on span {
  font-weight: 700;
}
.tab-list.type2 li.on::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #357fed;
}
.tab-list.type2 li:not(.on):hover {
  position: relative;
}
.tab-list.type2 li:not(.on):hover span {
  color: #374151 !important;
}
.tab-list.type2 li:not(.on):hover::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #d1d5db;
}
@media (min-width: 768px) {
  .tab-list.type2 li {
    min-width: 200px;
    width: auto;
  }
}

/* 패널 (화면 보기 방식) 영역 */
.mode-control #fullscreen-toggle {
  position: relative;
  padding-left: 28px;
}
.mode-control #fullscreen-toggle::before {
  content: "";
  position: absolute;
  top: 12px;
  left: 12px;
  width: 12px;
  height: 12px;
  background-size: 12px 12px;
}
.mode-control .panel-area {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  padding: 2px;
  border-radius: 6px;
  background: #e5e7eb;
}
.mode-control .panel-area button {
  position: relative;
  display: inline-block;
  width: 28px;
  min-width: 28px;
  height: 28px;
  padding: 0;
  overflow: hidden;
  border-radius: 5px;
  background: none;
}
.mode-control .panel-area button::before {
  content: "";
  position: absolute;
  top: 7px;
  left: 7px;
  width: 12px;
  height: 12px;
}
.mode-control .panel-area button[data-layout-type="default"]::before {
  background-size: 12px 12px;
}
.mode-control .panel-area button[data-layout-type="left"]::before {
  background-size: 12px 12px;
}
.mode-control .panel-area button[data-layout-type="right"]::before {
  background-size: 12px 12px;
}
.mode-control .panel-area button.on {
  background: #4b5563;
}
.mode-control .panel-area button.on[data-layout-type="default"]::before {
  background-size: 12px 12px;
}
.mode-control .panel-area button.on[data-layout-type="left"]::before {
  background-size: 12px 12px;
}
.mode-control .panel-area button.on[data-layout-type="right"]::before {
  background-size: 12px 12px;
}
.mode-control .panel-area button:hover {
  background: #a6adb9;
}
.mode-control .panel-area button:hover[data-layout-type="default"]::before {
  background-size: 12px 12px;
}
.mode-control .panel-area button:hover[data-layout-type="left"]::before {
  background-size: 12px 12px;
}
.mode-control .panel-area button:hover[data-layout-type="right"]::before {
  background-size: 12px 12px;
}

/* 레이아웃 */
.editor-area {
  overflow: hidden;
  overflow-x: auto;
}
.editor-area.layout-type-left #cke_ckeditor,
.editor-area.layout-type-right #cke_ckeditor {
  height: 100%;
}

/* 코드 영역 */
.codes-area .seperator {
  position: relative;
}
.codes-area .seperator::before {
  content: "";
  position: absolute;
  top: calc(50% - 6px);
  left: 2px;
  width: 1px;
  height: 12px;
  border-radius: 1px;
  background-color: #a6adb9;
}
.codes-area .seperator::after {
  content: "";
  position: absolute;
  top: calc(50% - 6px);
  right: 2px;
  width: 1px;
  height: 12px;
  border-radius: 1px;
  background-color: #a6adb9;
}
.codes-area .seperator.on::before {
  content: "";
  position: absolute;
  top: 2.5px;
  left: calc(50% - 6px);
  width: 12px;
  height: 1px;
  border-radius: 1px;
  background-color: #a6adb9;
}
.codes-area .seperator.on::after {
  content: "";
  position: absolute;
  top: 4.5px;
  left: calc(50% - 6px);
  width: 12px;
  height: 1px;
  border-radius: 1px;
  background-color: #a6adb9;
}
@media (max-width: 1024.98px) {
  .codes-area .seperator::before {
    display: none;
  }
  .codes-area .seperator::after {
    display: none;
  }
}
.codes-area .code-item {
  min-width: 36px;
  min-height: 36px;
}
.codes-area h4.code-item-title {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: -0.3px;
  color: #4b5563;
}
.codes-area .code-item-tools {
  display: flex;
  align-items: center;
  justify-content: center;
}
.codes-area .code-item-tools button {
  position: relative;
  width: 28px;
  min-width: 28px;
  height: 28px;
  padding: 0;
}
.codes-area .code-item-tools button::before {
  content: "";
  position: absolute;
  top: 8px;
  left: 8px;
  width: 12px;
  height: 12px;
}
.codes-area .code-item-tools button[data-editor="beautifyCode"]::before {
  background-size: 12px 12px;
}
.codes-area .code-item-tools button.btn-code-openclose::before {
  background-size: 12px 12px;
}
.codes-area .code-item-tools button.btn-code-openclose.on::before {
  background-size: 12px 12px;
}
.codes-area .CodeMirror-gutters {
  background: #fff;
  border: none;
}
.codes-area .CodeMirror-gutter {
  width: 47px;
}
.codes-area .CodeMirror-linenumber {
  padding-left: 12px;
  padding-right: 0;
  text-align: left;
}
.codes-area .CodeMirror-lines {
  padding-top: 12px;
  padding-bottom: 12px;
}
.codes-area .CodeMirror-code {
  font-size: 13px;
}
.codes-area .code-item-header {
  display: flex;
  overflow: hidden;
}
.codes-area .code-item-header.length {
  align-items: flex-start;
  height: 100%;
  padding-top: 48px;
  text-align: left;
  position: relative;
}
.codes-area .code-item-header.length .code-item-title {
  width: 80px;
  white-space: nowrap;
  transform: rotate(90deg);
  position: absolute;
  top: 77px;
  right: -23px;
}
.codes-area .code-item-header.length .btn-code-openclose {
  position: absolute;
  top: 4px;
  right: 4px;
}

/* 에디터 영역 */
.webeditor-area .webeditor-viewport {
  padding: 0;
  border-radius: 0 0 12px 12px;
}
.webeditor-area .webeditor-viewport .cke_chrome {
  border: none;
}
.webeditor-area .webeditor-viewport .cke_toolbar_break {
  width: calc(100% + 24px);
  height: 1px;
  margin-left: -12px;
  background: #e5e7eb;
}
.webeditor-area .cke_top.cke_reset_all {
  display: flex;
  position: relative;
  min-height: 76px;
  height: auto;
  padding: 4px 12px 8px;
  background: #f3f4f6;
  border-bottom: 1px solid #e5e7eb;
}
.webeditor-area .cke_top.cke_reset_all #cke_16 {
  position: absolute;
  right: 0;
}
.webeditor-area .cke_inner {
  border-radius: 12px;
}
.webeditor-area .cke_inner .cke_contents {
  text-align: center;
}
.webeditor-area .cke_inner .cke_toolbar:nth-child(n + 3) {
  margin-top: 8px;
}
.webeditor-area .cke_toolbox {
  width: 100%;
  height: 100%;
  padding-bottom: 0;
  /* 드롭다운 (combo box) button */
}
.webeditor-area .cke_toolbox .cke_combo_button {
  width: 102px !important;
  height: 34px !important;
  padding: 0 30px 0 16px !important;
  background-size: 16px;
  background-repeat: no-repeat;
  background-position: right 11px center;
  background-color: #fff;
  border-radius: 9px;
  border: 1px solid #d1d5db;
  cursor: pointer;
}
.webeditor-area .cke_toolbox .cke_combo_button:disabled {
  border-color: #e5e7eb;
  pointer-events: none;
  background-color: #f3f4f6;
}
.webeditor-area .cke_toolbox .cke_combo_button[aria-expanded="true"] {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.webeditor-area .cke_toolbox .cke_combo_button:hover {
  margin-left: 0;
  background-repeat: no-repeat;
  background-position: right 11px center;
  background-color: #fff;
  background-size: 16px;
  border: 1px solid #d1d5db;
}
.webeditor-area .cke_toolbox .cke_combo_text.cke_combo_inlinelabel {
  width: auto;
  padding-left: 0;
  margin-top: 7px;
  /* font-family: -apple-system, BlinkMacSystemFont, "Spoqa Han Sans Neo",
    "malgun gothic", Arial, sans-serif; */
  font-size: 14px;
  font-weight: 400;
  line-height: 130%;
  color: #374151;
  cursor: pointer;
}
.webeditor-area .cke_toolbox .cke_combo_text.cke_combo_inlinelabel:hover {
  margin-left: 0 !important;
}
.webeditor-area .cke_toolbox .cke_combo {
  margin-bottom: 0;
}
.webeditor-area .cke_combo_arrow,
.webeditor-area .cke_combo_open,
.webeditor-area .cke_toolbar_end {
  display: none;
}
.webeditor-area .cke_button.cke_button__undo,
.webeditor-area .cke_button.cke_button__redo {
  width: 28px !important;
  height: 28px !important;
  padding: 0 !important;
  border: none;
  cursor: pointer;
}
.webeditor-area .cke_button.cke_button__undo::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 28px;
  height: 28px;
  background-size: 16px 16px;
}
.webeditor-area .cke_button.cke_button__redo::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 28px;
  height: 28px;
  background-size: 16px 16px;
}
.webeditor-area a.cke_button_on {
  background: #d1d5db !important;
}
.webeditor-area .cke_bottom {
  display: none;
}
.webeditor-area .cke_toolbar.cke_toolbar_last {
  padding-right: 12px;
}
.webeditor-area [aria-labelledby="cke_13_label"] {
  margin-bottom: 6px;
  margin-top: 5px;
}
.webeditor-area [aria-labelledby="cke_13_label"] .cke_button:hover {
  background: #d1d5db;
}
.webeditor-area #cke_1_contents {
  background: #f9fafb;
  padding: 12px;
}

/* toolbar */
.cke_toolbar .cke_toolgroup {
  display: flex;
  align-items: center;
  margin: 0 !important;
  padding-right: 0 !important;
}
.cke_toolbar .cke_toolgroup a.cke_button:last-child:after {
  display: none !important;
}
.cke_toolbar .cke_toolgroup .cke_button {
  position: relative;
  margin-right: 4px;
  padding: 4px 6px;
  border: none;
  border-radius: 5px;
  /* font-family: -apple-system, BlinkMacSystemFont, "Spoqa Han Sans Neo",
    "malgun gothic", Arial, sans-serif; */
  font-size: 14px;
  color: #374151 !important;
  line-height: 130%;
  cursor: pointer;
}
.cke_toolbar .cke_toolgroup .cke_button .cke_button_icon {
  background-image: none !important;
}
.cke_toolbar .cke_toolgroup .cke_button .cke_button_icon.cke_button__h1_icon,
.cke_toolbar .cke_toolgroup .cke_button .cke_button_icon.cke_button__h2_icon,
.cke_toolbar .cke_toolgroup .cke_button .cke_button_icon.cke_button__h3_icon {
  display: none;
  background-image: none !important;
}
.cke_toolbar .cke_toolgroup .cke_button.cke_button__h1:before {
  content: "제목1";
  width: 34px;
  font-weight: 700;
}
.cke_toolbar .cke_toolgroup .cke_button.cke_button__h2:before {
  content: "제목2";
  width: 34px;
  font-weight: 500;
}
.cke_toolbar .cke_toolgroup .cke_button.cke_button__h3:before {
  content: "제목3";
  width: 34px;
  font-weight: 400;
}
.cke_toolbar .cke_toolgroup .cke_button.cke_button__bold:before {
  content: "";
  position: absolute;
  top: 6px;
  left: 6px;
  width: 16px;
  height: 16px;
  background-size: 16px 16px;
}
.cke_toolbar .cke_toolgroup .cke_button.cke_button__italic:before {
  content: "";
  position: absolute;
  top: 6px;
  left: 6px;
  width: 16px;
  height: 16px;
  background-size: 16px 16px;
}
.cke_toolbar .cke_toolgroup .cke_button.cke_button__underline:before {
  content: "";
  position: absolute;
  top: 6px;
  left: 6px;
  width: 16px;
  height: 16px;
  background-size: 16px 16px;
}
.cke_toolbar .cke_toolgroup .cke_button.cke_button__strike:before {
  content: "";
  position: absolute;
  top: 6px;
  left: 6px;
  width: 16px;
  height: 16px;
  background-size: 16px 16px;
}
.cke_toolbar .cke_toolgroup .cke_button.cke_button__code:before {
  content: "";
  position: absolute;
  top: 6px;
  left: 6px;
  width: 16px;
  height: 16px;
  background-size: 16px 16px;
}
.cke_toolbar .cke_toolgroup .cke_button.cke_button__codeblock:before {
  content: "";
  position: absolute;
  top: 6px;
  left: 6px;
  width: 16px;
  height: 14px;
  background-size: 16px 14px;
}
.cke_toolbar .cke_toolgroup .cke_button.cke_button__textcolor {
  padding: 4px 4px 4px 6px !important;
}
.cke_toolbar .cke_toolgroup .cke_button.cke_button__textcolor:before {
  content: "";
  position: absolute;
  top: 6px;
  left: 6px;
  width: 16px;
  height: 16px;
  background-size: 16px 16px;
}
.cke_toolbar .cke_toolgroup .cke_button.cke_button__bgcolor {
  padding: 4px 4px 4px 6px !important;
}
.cke_toolbar .cke_toolgroup .cke_button.cke_button__bgcolor:before {
  content: "";
  position: absolute;
  top: 6px;
  left: 6px;
  width: 16px;
  height: 16px;
  background-size: 16px 16px;
}
.cke_toolbar .cke_toolgroup .cke_button.cke_button__justifyleft:before {
  content: "";
  position: absolute;
  top: 6px;
  left: 6px;
  width: 16px;
  height: 16px;
  background-size: 16px 16px;
}
.cke_toolbar .cke_toolgroup .cke_button.cke_button__justifycenter:before {
  content: "";
  position: absolute;
  top: 6px;
  left: 6px;
  width: 16px;
  height: 16px;
  background-size: 16px 16px;
}
.cke_toolbar .cke_toolgroup .cke_button.cke_button__justifyright:before {
  content: "";
  position: absolute;
  top: 6px;
  left: 6px;
  width: 16px;
  height: 16px;
  background-size: 16px 16px;
}
.cke_toolbar .cke_toolgroup .cke_button.cke_button__bulletedlist:before {
  content: "";
  position: absolute;
  top: 6px;
  left: 6px;
  width: 16px;
  height: 16px;
  background-size: 16px 16px;
}
.cke_toolbar .cke_toolgroup .cke_button.cke_button__numberedlist:before {
  content: "";
  position: absolute;
  top: 6px;
  left: 6px;
  width: 16px;
  height: 16px;
  background-size: 16px 16px;
}
.cke_toolbar .cke_toolgroup .cke_button.cke_button__table:before {
  content: "";
  position: absolute;
  top: 6px;
  left: 6px;
  width: 16px;
  height: 16px;
  background-size: 16px 16px;
}
.cke_toolbar .cke_toolgroup .cke_button.cke_button__blockquote {
  width: 15px;
}
.cke_toolbar .cke_toolgroup .cke_button.cke_button__blockquote:before {
  content: "";
  position: absolute;
  top: 6px;
  left: 6px;
  width: 15px;
  height: 15px;
  background-size: 15px 15px;
}
.cke_toolbar .cke_toolgroup .cke_button.cke_button__image:before {
  content: "";
  position: absolute;
  top: 6px;
  left: 6px;
  width: 16px;
  height: 16px;
  background-size: 16px 16px;
}
.cke_toolbar .cke_toolgroup .cke_button.cke_button__link:before {
  content: "";
  position: absolute;
  top: 6px;
  left: 6px;
  width: 16px;
  height: 16px;
  background-size: 16px 16px;
}
.cke_toolbar .cke_toolgroup .cke_button.cke_button__button {
  width: 21px;
  margin-right: 8px;
}
.cke_toolbar .cke_toolgroup .cke_button.cke_button__button:before {
  content: "";
  position: absolute;
  top: 6px;
  left: 6px;
  width: 21px;
  height: 13.5px;
  background-size: 21px 13.5px;
}
.cke_toolbar .cke_toolgroup .cke_button.cke_button__emojipanel {
  padding: 4px 4px 4px 6px !important;
}
.cke_toolbar .cke_toolgroup .cke_button.cke_button__emojipanel:before {
  content: "";
  position: absolute;
  top: 6px;
  left: 6px;
  width: 16px;
  height: 16px;
  background-size: 16px 16px;
}
.cke_toolbar .cke_toolgroup .cke_button.cke_button__templates:before {
  content: "";
  position: absolute;
  top: 6px;
  left: 6px;
  width: 16px;
  height: 16px;
  background-size: 16px 16px;
}
.cke_toolbar .cke_toolgroup .cke_button .cke_button_arrow {
  width: 12px;
  height: 12px;
  margin: 4px 0 0 4px !important;
  background-size: 12px 12px;
  border: none !important;
}
.cke_toolbar .cke_toolgroup .cke_button.cke_button_off:hover,
.cke_toolbar .cke_toolgroup .cke_button.cke_button_off:focus,
.cke_toolbar .cke_toolgroup .cke_button.cke_button_off:active {
  padding: 4px 6px;
}
.cke_toolbar .cke_toolgroup .cke_button:hover {
  border-radius: 5px;
  background: #d1d5db;
}
.cke_toolbar#cke_20 {
  position: relative;
}
.cke_toolbar#cke_20::before {
  content: "";
  position: absolute;
  top: calc(50% - 8px);
  right: 0px;
  width: 1px;
  height: 16px;
  background-color: #d1d5db;
  border-radius: 100px;
}
.cke_toolbar#cke_24 {
  position: relative;
  margin-left: 4px;
}
.cke_toolbar#cke_24::before {
  content: "";
  position: absolute;
  top: calc(50% - 8px);
  right: 0px;
  width: 1px;
  height: 16px;
  background-color: #d1d5db;
  border-radius: 100px;
}
.cke_toolbar#cke_32 {
  position: relative;
  margin-left: 4px;
}
.cke_toolbar#cke_32::before {
  content: "";
  position: absolute;
  top: calc(50% - 8px);
  right: 0px;
  width: 1px;
  height: 16px;
  background-color: #d1d5db;
  border-radius: 100px;
}
.cke_toolbar#cke_35 {
  position: relative;
  margin-left: 4px;
}
.cke_toolbar#cke_35::before {
  content: "";
  position: absolute;
  top: calc(50% - 8px);
  right: 0px;
  width: 1px;
  height: 16px;
  background-color: #d1d5db;
  border-radius: 100px;
}
.cke_toolbar#cke_39 {
  position: relative;
  margin-left: 4px;
}
.cke_toolbar#cke_39::before {
  content: "";
  position: absolute;
  top: calc(50% - 8px);
  right: 0px;
  width: 1px;
  height: 16px;
  background-color: #d1d5db;
  border-radius: 100px;
}
.cke_toolbar#cke_42 {
  position: relative;
  margin-left: 4px;
}
.cke_toolbar#cke_42::before {
  content: "";
  position: absolute;
  top: calc(50% - 8px);
  right: 0px;
  width: 1px;
  height: 16px;
  background-color: #d1d5db;
  border-radius: 100px;
}
.cke_toolbar#cke_45 {
  position: relative;
  margin-left: 4px;
  margin-right: 4px;
}
.cke_toolbar#cke_45::before {
  content: "";
  position: absolute;
  top: calc(50% - 8px);
  right: 0px;
  width: 1px;
  height: 16px;
  background-color: #d1d5db;
  border-radius: 100px;
}
.cke_toolbar[aria-labelledby="cke_16_label"] .cke_toolgroup {
  height: 28px;
  margin-bottom: 6px;
  margin-top: 2px !important;
  margin-right: 4px !important;
  padding: 2px;
  border-radius: 6px;
  background: #d1d5db;
}
.cke_toolbar[aria-labelledby="cke_16_label"] .cke_toolgroup .cke_button {
  cursor: pointer;
  margin-right: 0;
}
.cke_toolbar[aria-labelledby="cke_16_label"]
  .cke_toolgroup
  .cke_button[title="PC View"]
  .cke_button_icon {
  background-size: 16px 16px !important;
}
.cke_toolbar[aria-labelledby="cke_16_label"]
  .cke_toolgroup
  .cke_button[title="Tablet View"]
  .cke_button_icon {
  background-size: 12px 12px !important;
}
.cke_toolbar[aria-labelledby="cke_16_label"]
  .cke_toolgroup
  .cke_button[title="Mobile View"]
  .cke_button_icon {
  background-size: 12px 12px !important;
}
.cke_toolbar[aria-labelledby="cke_16_label"] .cke_toolgroup .cke_button.on {
  background: #4b5563;
}
.cke_toolbar[aria-labelledby="cke_16_label"]
  .cke_toolgroup
  .cke_button.on[title="PC View"]
  .cke_button_icon {
  background-size: 16px 16px !important;
}
.cke_toolbar[aria-labelledby="cke_16_label"]
  .cke_toolgroup
  .cke_button.on[title="Tablet View"]
  .cke_button_icon {
  background-size: 12px 12px !important;
}
.cke_toolbar[aria-labelledby="cke_16_label"]
  .cke_toolgroup
  .cke_button.on[title="Mobile View"]
  .cke_button_icon {
  background-size: 12px 12px !important;
}
.cke_toolbar[aria-labelledby="cke_16_label"] .cke_toolgroup .cke_button:hover {
  background: #a6adb9 !important;
}
.cke_toolbar[aria-labelledby="cke_16_label"]
  .cke_toolgroup
  .cke_button:hover[title="PC View"]
  .cke_button_icon {
  background-size: 16px 16px !important;
}
.cke_toolbar[aria-labelledby="cke_16_label"]
  .cke_toolgroup
  .cke_button:hover[title="Tablet View"]
  .cke_button_icon {
  background-size: 12px 12px !important;
}
.cke_toolbar[aria-labelledby="cke_16_label"]
  .cke_toolgroup
  .cke_button:hover[title="Mobile View"]
  .cke_button_icon {
  background-size: 12px 12px !important;
}

/* 콘텐츠 변경 이력 버튼 */
.btn-outline-gray.btn-con-change-history {
  position: relative;
  padding-left: 38px !important;
}
.btn-outline-gray.btn-con-change-history::before {
  content: "";
  position: absolute;
  top: calc(50% - 7px);
  left: 17px;
  width: 16px;
  height: 16px;
  background-size: 16px 16px !important;
}

/* 드롭다운 (combo box) */
.cke_panel.cke_panel {
  border: none;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.15);
  /* font-family: -apple-system, BlinkMacSystemFont, "Spoqa Han Sans Neo",
    "malgun gothic", Arial, sans-serif; */
}

.cke_panel.cke_combopanel {
  width: 140px;
  height: 246px;
  padding: 4px;
  border: 1px solid #d1d5db;
  border-radius: 0px 0px 9px 9px !important;
  box-shadow: none;
}

/* 레이어 팝업 */
.cke_dialog_background_cover {
  z-index: 600 !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.cke_reset_all .cke_dialog {
  border-radius: 12px;
  overflow: hidden;
}
.cke_reset_all.cke_editor_ckeditor_dialog {
  z-index: 700 !important;
}
.cke_reset_all .cke_dialog_title {
  display: flex;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid #e5e7eb;
  background: none;
  /* font-family: -apple-system, BlinkMacSystemFont, "Spoqa Han Sans Neo",
    "malgun gothic", Arial, sans-serif; */
  font-size: 18px;
  font-weight: 700;
  line-height: 140%;
  color: #1f2937;
}
.cke_reset_all .cke_dialog_contents_body {
  padding: 14px 24px 24px;
}
.cke_reset_all .cke_dialog_contents_body tbody {
  width: 100%;
}
.cke_reset_all
  .cke_dialog_contents_body
  .cke_dialog_ui_hbox
  .cke_dialog_ui_hbox_first {
  padding-right: 10px;
}
.cke_reset_all
  .cke_dialog_contents_body
  .cke_dialog_ui_hbox
  > .cke_dialog_ui_hbox_last {
  padding-right: 0;
}
.cke_reset_all
  .cke_dialog_contents_body
  .cke_dialog_ui_hbox
  > .cke_dialog_ui_hbox_last
  .cke_dialog_ui_hbox_last {
  padding-left: 0;
}
.cke_reset_all .cke_dialog_contents_body .cke_dialog_ui_text {
  margin-top: 10px;
}
.cke_reset_all .cke_dialog_contents_body .cke_dialog_ui_labeled_label {
  display: inline-block;
  height: 18px;
  margin-bottom: 8px;
  /* font-family: -apple-system, BlinkMacSystemFont, "Spoqa Han Sans Neo",
    "malgun gothic", Arial, sans-serif; */
  font-size: 14px;
  font-weight: 500;
  line-height: 130%;
  color: #1f2937;
}
.cke_reset_all
  .cke_dialog_contents_body
  .cke_dialog_ui_labeled_label
  + .cke_dialog_ui_labeled_content {
  margin-top: 0;
  margin-right: 5px;
}
.cke_reset_all .cke_dialog_contents_body .cke_dialog_ui_labeled_content {
  width: 100% !important;
  min-width: 150px !important;
}
.cke_reset_all .cke_dialog_contents_body .cke_dialog_ui_vbox_child {
  width: 100% !important;
  padding: 0 !important;
}
.cke_reset_all
  .cke_dialog_contents_body
  .cke_dialog_ui_vbox_child
  .cke_dialog_ui_html {
  margin-top: 10px !important;
  margin-left: 20px !important;
  /* font-family: -apple-system, BlinkMacSystemFont, "Spoqa Han Sans Neo",
    "malgun gothic", Arial, sans-serif !important; */
  font-size: 14px !important;
}
.cke_reset_all .cke_dialog_contents_body .ImagePreviewBox {
  margin-top: 8px !important;
  padding: 12px 0 12px 16px !important;
  border: 1px solid #d1d5db !important;
  border-radius: 9px !important;
}
.cke_reset_all .cke_dialog_contents_body .cke_dialog_ui_input_text {
  min-width: 150px !important;
  padding: 0 !important;
}
.cke_reset_all
  .cke_dialog_contents_body
  .cke_dialog_ui_input_text
  .cke_dialog_ui_input_text {
  display: flex !important;
  height: 40px !important;
  padding: 0px 16px !important;
  align-items: center !important;
  gap: 10px !important;
  flex: 1 0 0 !important;
  border-radius: 9px !important;
  border: 1px solid #d1d5db !important;
  background: #fff !important;
  /* font-family: -apple-system, BlinkMacSystemFont, "Spoqa Han Sans Neo",
    "malgun gothic", Arial, sans-serif !important; */
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 130% !important;
  color: #374151 !important;
}
.cke_reset_all .cke_dialog_contents_body .cke_dialog_ui_select {
  margin-top: 10px !important;
}
.cke_reset_all .cke_dialog_contents_body .cke_dialog_ui_input_select {
  min-width: 161px !important;
  width: 100% !important;
  margin-right: 0 !important;
}
.cke_reset_all
  .cke_dialog_contents_body
  .cke_dialog_ui_input_select
  .cke_dialog_ui_input_select {
  appearance: none !important;
  -webkit-appearance: none !important;
  width: 100% !important;
  height: 40px !important;
  padding: 0 0 0 12px !important;
  border: 1px solid #d1d5db !important;
  border-radius: 8px !important;
  background-size: 12px !important;
  background-position: right 12px center !important;
  background-size: 12px 12px !important;
  /* font-family: -apple-system, BlinkMacSystemFont, "Spoqa Han Sans Neo",
    "malgun gothic", Arial, sans-serif !important; */
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 130% !important;
  color: #374151 !important;
}
.cke_reset_all .cke_dialog_contents_body .cke_dialog_ui_textarea {
  margin-top: 10px !important;
}
.cke_reset_all
  .cke_dialog_contents_body
  .cke_dialog_ui_input_textarea
  .cke_dialog_ui_input_textarea {
  height: auto !important;
  min-height: 120px !important;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  overflow-y: auto !important;
  line-height: inherit !important;
  resize: none !important;
  border: 1px solid #d1d5db !important;
  border-radius: 9px !important;
  color: #374151 !important;
  background-size: 5.5px 5.5px !important;
  background-position: right 6px bottom 6px !important;
}
.cke_reset_all .cke_dialog_footer {
  display: flex;
  padding: 16px 0px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-top: 1px solid #e5e7eb;
  background: none;
  /* font-family: -apple-system, BlinkMacSystemFont, "Spoqa Han Sans Neo",
    "malgun gothic", Arial, sans-serif; */
}
.cke_reset_all .cke_dialog_footer .cke_dialog_ui_button {
  display: flex;
  width: 70px;
  height: 40px;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 9px;
}
.cke_reset_all .cke_dialog_footer .cke_dialog_ui_button span {
  /* font-family: -apple-system, BlinkMacSystemFont, "Spoqa Han Sans Neo",
    "malgun gothic", Arial, sans-serif; */
  font-size: 14px;
  font-weight: 500;
  line-height: 130%;
}
.cke_reset_all .cke_dialog_footer .cke_dialog_ui_button_ok {
  border: none;
  background: #357fed;
  color: #fff;
}
.cke_reset_all .cke_dialog_footer .cke_dialog_ui_button_cancel {
  border: 1px solid #d1d5db;
  background: #fff;
}
.cke_reset_all .cke_dialog_close_button {
  top: 16px;
  right: 16px;
  width: 28px;
  height: 28px;
  background-size: 16px 16px;
}
.cke_reset_all .cke_resizer {
  display: none;
}
.cke_reset_all .cke_dialog_ui_checkbox label {
  /* font-family: -apple-system, BlinkMacSystemFont, "Spoqa Han Sans Neo",
    "malgun gothic", Arial, sans-serif; */
  font-size: 14px;
}
.cke_reset_all #cke_378_uiElement {
  margin-left: 10px;
}
.cke_reset_all .cke_dialog_tabs {
  left: 24px;
  width: calc(100% - 48px);
  height: 47px;
  border-bottom: 1px solid #e5e7eb;
}
.cke_reset_all .cke_dialog_tabs .cke_dialog_tab {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 20px;
  border: none;
  background: none;
  /* font-family: -apple-system, BlinkMacSystemFont, "Spoqa Han Sans Neo",
    "malgun gothic", Arial, sans-serif; */
  font-size: 16px;
  font-weight: 500;
  color: #7e8594;
}
.cke_reset_all .cke_dialog_tabs .cke_dialog_tab.cke_dialog_tab_selected {
  position: relative;
  color: #1d4ed8;
  font-weight: 700;
}
.cke_reset_all
  .cke_dialog_tabs
  .cke_dialog_tab.cke_dialog_tab_selected::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #357fed;
}
.cke_reset_all
  .cke_dialog_tabs
  .cke_dialog_tab:hover:not(
    .cke_reset_all .cke_dialog_tabs .cke_dialog_tab.cke_dialog_tab_selected
  ) {
  position: relative;
  color: #374151;
}
.cke_reset_all
  .cke_dialog_tabs
  .cke_dialog_tab:hover:not(
    .cke_reset_all .cke_dialog_tabs .cke_dialog_tab.cke_dialog_tab_selected
  )::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #d1d5db;
}
.cke_reset_all .cke_dialog_tabs + .cke_dialog_contents {
  border-top: none;
}

.cke_browser_webkit .cke_tpl_list {
  margin-top: 10px !important;
}
.cke_browser_webkit .cke_tpl_list .cke_tpl_title {
  /* font-family: -apple-system, BlinkMacSystemFont, "Spoqa Han Sans Neo",
    "malgun gothic", Arial, sans-serif !important; */
  font-size: 14px !important;
}
.cke_browser_webkit .cke_tpl_list .cke_tpl_title + br + span {
  display: block !important;
  margin-top: 5px !important;
  /* font-family: -apple-system, BlinkMacSystemFont, "Spoqa Han Sans Neo",
    "malgun gothic", Arial, sans-serif !important; */
  font-size: 14px !important;
}
.cke_browser_webkit .cke_tpl_list .cke_tpl_preview_img {
  padding-right: 15px !important;
}
.cke_browser_webkit .cke_dialog_image_ratiolock {
  display: none !important;
}
.cke_browser_webkit #cke_658_uiElement {
  margin-left: 10px !important;
}

.loadingbar {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2000;
  background-color: #fff;
  transition-duration: 1s;
  transition-property: opacity, visibility;
  will-change: opacity, visibility;
}
.loadingbar.hide {
  opacity: 0;
  visibility: hidden;
}
.loadingbar .spinner {
  width: 230px;
  height: 230px;
}

.ico {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  vertical-align: middle;
  overflow: hidden;
  text-indent: -9999px;
  overflow: hidden;
  text-align: left;
}

/* 범용 아이콘  */
.ico-caret-down-darkgary-xs {
  width: 8px;
  height: 8px;
}

.ico-caret-right-gray-xs {
  width: 8px;
  height: 8px;
}

.ico-edit-gray-xs {
  width: 8px;
  height: 8px;
}

.ico-logout-gray-xs {
  width: 8px;
  height: 8px;
}

.ico-plus-gray-xs {
  width: 8px;
  height: 8px;
}

.ico-bararrowsv-gray-sm {
  width: 12px;
  height: 12px;
}

.ico-calendar-lightgray-sm {
  width: 12px;
  height: 12px;
}

.ico-check-disabled-lightgray-sm {
  width: 12px;
  height: 12px;
}

.ico-check-gray-sm {
  width: 12px;
  height: 12px;
}

.ico-check-blue-sm {
  width: 12px;
  height: 12px;
}

.ico-check-primary-sm {
  width: 12px;
  height: 12px;
}

.ico-chevron-default-gray-sm {
  width: 12px;
  height: 12px;
}

.ico-chevrondouble-left-gray-sm {
  width: 12px;
  height: 12px;
}

.ico-chevrondouble-right-gray-sm {
  width: 12px;
  height: 12px;
}

.ico-chevron-down-lightgray-sm {
  width: 12px;
  height: 12px;
}

.ico-chevron-left-gray-sm {
  width: 12px;
  height: 12px;
}

.ico-chevron-up-lightgray-sm {
  width: 12px;
  height: 12px;
}

.ico-clock-gray-sm {
  width: 12px;
  height: 12px;
}

.ico-clockplus-gray-sm {
  width: 12px;
  height: 12px;
}

.ico-time-sm {
  width: 12px;
  height: 12px;
}

.ico-cloudupload-white-sm {
  width: 12px;
  height: 12px;
}

.ico-cog-darkgray-sm {
  width: 12px;
  height: 12px;
}

.ico-copy-gray-sm {
  width: 12px;
  height: 12px;
}

.ico-crop-gray-sm {
  width: 12px;
  height: 12px;
}

.ico-download-white-sm {
  width: 12px;
  height: 12px;
}

.ico-exclamation-sm {
  width: 12px;
  height: 12px;
}

.ico-expand-gray-sm {
  width: 12px;
  height: 12px;
}

.ico-externallink-gray-sm {
  width: 12px;
  height: 12px;
}

.ico-externallink-primary-sm {
  width: 12px;
  height: 12px;
}

.ico-externallink-white-md {
  width: 16px;
  height: 16px;
}

.ico-externallink-darkgray-md {
  width: 16px;
  height: 16px;
}

.ico-fileexcel-white-sm {
  width: 12px;
  height: 12px;
}

.ico-folder-active-primary-sm {
  width: 12px;
  height: 12px;
}

.ico-folder-sm {
  width: 12px;
  height: 12px;
}

.ico-info-lightgray-sm {
  width: 12px;
  height: 12px;
}

.ico-lock-white-sm {
  width: 12px;
  height: 12px;
}

.ico-lock-primary-sm {
  width: 12px;
  height: 12px;
}

.ico-logout-gray-sm {
  width: 12px;
  height: 12px;
}

.ico-minus-gray-sm {
  width: 12px;
  height: 12px;
}

.ico-minus-white-sm {
  width: 12px;
  height: 12px;
}

.ico-pen-white-sm {
  width: 12px;
  height: 12px;
}

.ico-pen-primary-sm {
  width: 12px;
  height: 12px;
}

.ico-pen-darkgary-sm {
  width: 12px;
  height: 12px;
}

.ico-plus-gray-sm {
  width: 12px;
  height: 12px;
}

.ico-plus-primary-sm {
  width: 12px;
  height: 12px;
}

.ico-preview-gray-sm {
  width: 12px;
  height: 12px;
}

.ico-question-sm {
  width: 12px;
  height: 12px;
}

.ico-restore-primary-sm {
  width: 12px;
  height: 12px;
}

.ico-rss-primary-sm {
  width: 12px;
  height: 12px;
}

.ico-save-primary-sm {
  width: 12px;
  height: 12px;
}

.ico-search-disabled-lightgray-sm {
  width: 12px;
  height: 12px;
}

.ico-search-gray-sm {
  width: 12px;
  height: 12px;
}

.ico-search-lightgray-sm {
  width: 12px;
  height: 12px;
}

.ico-times-white-sm {
  width: 12px;
  height: 12px;
}

.ico-trash-disabled-lightgray-sm {
  width: 12px;
  height: 12px;
}

.ico-trash-white-sm {
  width: 12px;
  height: 12px;
}

.ico-user-lightgray-sm {
  width: 12px;
  height: 12px;
}

.ico-close-lightgray-sm {
  width: 12px;
  height: 12px;
}

.ico-close-white-sm {
  width: 12px;
  height: 12px;
}

.ico-table-updown-darkgary-sm {
  width: 12px;
  height: 12px;
}

.ico-edit-primary-sm {
  width: 12px;
  height: 12px;
}

.ico-donut-chart-primary-sm {
  width: 12px;
  height: 12px;
}

.ico-donut-chart-white-sm {
  width: 12px;
  height: 12px;
}

.ico-line-chart-primary-sm {
  width: 12px;
  height: 12px;
}

.ico-line-chart-white-sm {
  width: 12px;
  height: 12px;
}

.ico-bar-chart-primary-sm {
  width: 12px;
  height: 12px;
}

.ico-bar-chart-white-sm {
  width: 12px;
  height: 12px;
}

.ico-bars-gray-md {
  width: 16px;
  height: 16px;
}

.ico-calendar-lightgray-md {
  width: 16px;
  height: 16px;
}

.ico-chevron-left-gray-md {
  width: 16px;
  height: 16px;
}

.ico-chevron-right-gray-md {
  width: 16px;
  height: 16px;
}

.ico-chevron-right-lightgray-md {
  width: 16px;
  height: 16px;
}

.ico-cloudupload-white-md {
  width: 16px;
  height: 16px;
}

.ico-ellipsis-gray-md {
  width: 16px;
  height: 16px;
}

.ico-home-white-md {
  width: 16px;
  height: 16px;
}

.ico-noteedit-active-primary-md {
  width: 16px;
  height: 16px;
}

.ico-noteedit-gray-md {
  width: 16px;
  height: 16px;
}

.ico-pen-primary-md {
  width: 16px;
  height: 16px;
}

.ico-play-white-md {
  width: 16px;
  height: 16px;
}

.ico-preview-gray-md {
  width: 16px;
  height: 16px;
}

.ico-question-md {
  width: 16px;
  height: 16px;
}

.ico-save-primary-md {
  width: 16px;
  height: 16px;
}

.ico-save-white-md {
  width: 16px;
  height: 16px;
}

.ico-search-lightgray-md {
  width: 16px;
  height: 16px;
}

.ico-search-white-md {
  width: 16px;
  height: 16px;
}

.ico-search-gray-md {
  width: 16px;
  height: 16px;
}

.ico-search-darkgray-md {
  width: 16px;
  height: 16px;
}

.ico-sitemap-gray-md {
  width: 16px;
  height: 16px;
}

.ico-trash-white-md {
  width: 16px;
  height: 16px;
}

.ico-undo-primary-md {
  width: 16px;
  height: 16px;
}

.ico-undo-darkgray-md {
  width: 16px;
  height: 16px;
}

.ico-favorit-gray-md {
  width: 16px;
  height: 16px;
}

.ico-close-white-md {
  width: 16px;
  height: 16px;
}

.ico-system-gold-md {
  width: 16px;
  height: 16px;
}

.ico-system-silver-md {
  width: 16px;
  height: 16px;
}

.ico-system-bronze-md {
  width: 16px;
  height: 16px;
}

.ico-externallink-gray-md {
  width: 16px;
  height: 16px;
}

.ico-clock-gray-md {
  width: 16px;
  height: 16px;
}

.ico-exclamation-md {
  width: 16px;
  height: 16px;
}

.ico-site-name-md {
  width: 16px;
  height: 16px;
}

.ico-restore-primary-md {
  width: 16px;
  height: 16px;
}

.ico-chevrondouble-left-lightgray-lg {
  width: 24px;
  height: 24px;
}

.ico-chevrondouble-right-lightgray-lg {
  width: 24px;
  height: 24px;
}

.ico-chevron-down-gray-lg {
  width: 24px;
  height: 24px;
}

.ico-chevron-down-darkgray-lg {
  width: 24px;
  height: 24px;
}

.ico-chevron-left-lightgray-lg {
  width: 24px;
  height: 24px;
}

.ico-chevron-right-lightgray-lg {
  width: 24px;
  height: 24px;
}

.ico-chevron-up-gray-lg {
  width: 24px;
  height: 24px;
}

.ico-info-xl {
  width: 80px;
  height: 80px;
}

.ico-success-xl {
  width: 80px;
  height: 80px;
}

.ico-warning-xl {
  width: 80px;
  height: 80px;
}

.ico-error-xl {
  width: 80px;
  height: 80px;
}

.ico-warning-gray-xl {
  width: 80px;
  height: 80px;
}

/* 레이아웃 */
.ico-dashboard-gray-md {
  width: 16px;
  height: 16px;
}

.ico-dashboard-active-primary-md {
  width: 16px;
  height: 16px;
}

.ico-operation-gray-md {
  width: 16px;
  height: 16px;
}

.ico-operation-active-primary-md {
  width: 16px;
  height: 16px;
}

.ico-monitor-gray-md {
  width: 16px;
  height: 16px;
}

.ico-monitor-active-primary-md {
  width: 16px;
  height: 16px;
}

.ico-log-gray-md {
  width: 16px;
  height: 16px;
}

.ico-log-active-primary-md {
  width: 16px;
  height: 16px;
}

.ico-chartbar-gray-md {
  width: 16px;
  height: 16px;
}

.ico-chartbar-active-primary-md {
  width: 16px;
  height: 16px;
}

.ico-report-gray-md {
  width: 16px;
  height: 16px;
}

.ico-report-active-primary-md {
  width: 16px;
  height: 16px;
}

.ico-plus-gray-md {
  width: 16px;
  height: 16px;
}

.ico-edit-gray-md {
  width: 16px;
  height: 16px;
}

.ico-logout-gray-md {
  width: 16px;
  height: 16px;
}

.ico-sortable-handle-gray-md {
  width: 16px;
  height: 16px;
}

.ico-question-lg {
  width: 24px;
  height: 24px;
}

.ico-plus-white-md {
  width: 16px;
  height: 16px;
}

.ico-new-sm {
  width: 12px;
  height: 12px;
}

.ico-speaker-md {
  width: 16px;
  height: 16px;
}

.ico-notice-md {
  width: 16px;
  height: 16px;
}

.ico-download-gray-md {
  width: 16px;
  height: 16px;
}

.ico-download-darkgray-sm {
  width: 12px;
  height: 12px;
}

.ico-delete-gray-md {
  width: 16px;
  height: 16px;
}

.ico-prev-gray-sm {
  width: 12px;
  height: 12px;
}

.ico-next-gray-sm {
  width: 12px;
  height: 12px;
}

.ico-delete-md {
  width: 16px;
  height: 16px;
}

.ico-download-md {
  width: 16px;
  height: 16px;
}

.ico-plus-primary-md {
  width: 16px;
  height: 16px;
}

.ico-radio-gray-md {
  width: 16px;
  height: 16px;
}

.ico-check-gray-md {
  width: 16px;
  height: 16px;
}

.ico-text-gray-md {
  width: 16px;
  height: 16px;
}

.ico-matrix-gray-md {
  width: 16px;
  height: 16px;
}

.ico-fileexcel-white-md {
  width: 16px;
  height: 16px;
}

.ico-chevron-right-blue-sm {
  width: 12px;
  height: 12px;
}

.ico-user-gray-md {
  width: 16px;
  height: 16px;
}

.ico-view-gray-md {
  width: 16px;
  height: 16px;
}

.ico-pc-gray-md {
  width: 16px;
  height: 16px;
}

.ico-mobile-gray-md {
  width: 16px;
  height: 16px;
}

.ico-menu-gray-md {
  width: 16px;
  height: 16px;
}

.ico-exmark-gray-md {
  width: 16px;
  height: 16px;
}

.ico-best-gray-md {
  width: 16px;
  height: 16px;
}

.ico-add-gray-md {
  width: 16px;
  height: 16px;
}

.ico-average-gray-md {
  width: 16px;
  height: 16px;
}

.ico-speech-gray-md {
  width: 16px;
  height: 16px;
}

.ico-user-gray-md {
  width: 16px;
  height: 16px;
}

.ico-print-white-md {
  width: 16px;
  height: 16px;
}

.ico-chevron-down-blue-md {
  width: 16px;
  height: 16px;
}

.ico-chevron-up-red-md {
  width: 16px;
  height: 16px;
}

.ico-floating-gray-lg {
  width: 24px;
  height: 24px;
}

.ico-tabledb-gray-sm {
  width: 12px;
  height: 12px;
}

.ico-comment-gray-sm {
  width: 12px;
  height: 12px;
}

.ico-editor-bold-md {
  width: 16px;
  height: 16px;
}

.ico-editor-underline-md {
  width: 16px;
  height: 16px;
}

.ico-editor-strike-md {
  width: 16px;
  height: 16px;
}

.ico-editor-italic-md {
  width: 16px;
  height: 16px;
}

.ico-editor-ul-md {
  width: 16px;
  height: 16px;
}

.ico-editor-ol-md {
  width: 16px;
  height: 16px;
}

.ico-editor-picture-md {
  width: 16px;
  height: 16px;
}

.ico-editor-table-md {
  width: 16px;
  height: 16px;
}

.ico-chevron-right-white-md {
  width: 16px;
  height: 16px;
}

.ico-page-lg {
  width: 24px;
  height: 24px;
}

.ico-popular-lg {
  width: 24px;
  height: 24px;
}

.ico-device-lg {
  width: 24px;
  height: 24px;
}

.ico-history-lg {
  width: 24px;
  height: 24px;
}

.ico-card-lg {
  width: 24px;
  height: 24px;
}

.ico-info-lg {
  width: 24px;
  height: 24px;
}

.ico-user-blue-md {
  width: 16px;
  height: 16px;
}

.ico-view-orange-md {
  width: 16px;
  height: 16px;
}

.ico-chevron-right-black-md {
  width: 16px;
  height: 16px;
}

/* 불릿 시스템 */
.page-title {
  font-size: 24px;
  line-height: 1.4;
  letter-spacing: -0.2px;
  font-weight: bold;
}
@media (max-width: 1024.98px) {
  .page-title {
    font-size: 22px;
  }
}

.title1 {
  position: relative;
  display: flex;
  align-items: center;
}
.title1:before {
  flex: 0 0 auto;
  content: "";
}
.title1 .btn {
  vertical-align: initial;
  flex: 0 0 auto;
}

.title1 {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.4;
  letter-spacing: -0.02em;
}
@media (max-width: 1024.98px) {
  .title1 {
    font-size: 22px;
  }
}

.list > li {
  position: relative;
}
.list > li:after {
  display: block;
  clear: both;
  content: "";
}
.list > li:before {
  content: "";
  position: absolute;
  left: 0;
}

.list > li {
  position: relative;
}
.list > li:after {
  display: block;
  clear: both;
  content: "";
}
.list > li:before {
  content: "";
  position: absolute;
  left: 0;
}

.list1 > li {
  padding-left: 14px;
}
.list1 > li:before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 8px;
  width: 5px;
  height: 5px;
  border-radius: 3px;
  background-color: #f3f4f6;
}
.list1 > li + li {
  margin-top: 4px;
}

.list2 > li {
  padding-left: 12px;
  font-size: 16px;
}
.list2 > li:before {
  width: 4px;
  height: 4px;
  background-color: #7e8594;
  border-radius: 50%;
  top: 8.5px;
}
.list2 > li + li {
  margin-top: 6px;
}
@media (max-width: 767.98px) {
  .list2 > li {
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  .content-row > .inner {
    display: flex;
    margin: -10px;
  }
  .content-row > .inner > .col-item {
    flex: 1 1 50%;
    margin: 10px;
  }
  .content-row > .inner > .col-item-fit {
    flex: 0 0 auto;
    margin: 10px;
  }
}
@media (max-width: 767.98px) {
  .content-row > .inner > .col-item + .col-item,
  .content-row > .inner > .col-item + .col-item-fit,
  .content-row > .inner > .col-item-fit + .col-item,
  .content-row > .inner > .col-item-fit + .col-item-fit {
    margin-top: 40px;
  }
}

.card {
  background: #fff;
}
@media (min-width: 1025px) {
  .card {
    padding: 24px;
    border-radius: 16px;
  }
}
@media (max-width: 1024.98px) {
  .card {
    padding: 24px 20px;
  }
}

.card-header {
  line-height: 24px;
}
.card-header:after {
  display: block;
  clear: both;
  content: "";
}
.card-header-underline {
  padding: 20px;
  /* border-bottom: 1px solid #d1d5db; */
}
.card-header .card-title {
  float: left;
}
.card-header .card-toolbar {
  float: right;
  display: flex;
  align-items: center;
  min-height: 24px;
}

.card-title .title1 {
  display: inline-block;
  vertical-align: middle;
}

/* 박스 */
.box {
  position: relative;
  border: 1px solid transparent;
  padding: 20px;
  background-color: #f3f4f6;
  border-radius: 16px;
  margin-bottom: 20px;
}
.box + .box {
  margin-top: -20px;
}
@media (max-width: 1024.98px) {
  .box {
    padding: 15px 20px;
  }
}
.box-point {
  position: relative;
  padding: 27px 26px;
  background-size: 18.5px 18.5px;
  border: 1px solid #e5e7eb;
  border-radius: 16px;
  z-index: 0;
}
.box-point:before {
  content: "";
  position: absolute;
  top: 6px;
  left: 6px;
  right: 6px;
  bottom: 6px;
  background-color: #fff;
  border-radius: 16px;
  z-index: -1;
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  white-space: nowrap;
  overflow: hidden;
  transition-duration: 0.2s;
  transition-property: background-color, box-shadow;
  will-change: background-color, box-shadow;
  border: 1px solid transparent;
  max-width: 100%;
}
.btn .ico {
  flex: 0 0 auto;
}
.btn .ico:first-child {
  margin-right: 4px;
}
.btn .ico:last-child {
  margin-left: 4px;
}
.btn .ico:only-child {
  margin-left: 0px;
}
.btn.disabled,
.btn:disabled {
  opacity: 0.3;
}
.btn.disabled.btn-outline-gray:active,
.btn.disabled.btn-outline-gray:hover,
.btn.disabled.btn-outline-gray:focus,
.btn:disabled.btn-outline-gray:active,
.btn:disabled.btn-outline-gray:hover,
.btn:disabled.btn-outline-gray:focus {
  border-color: #d1d5db;
  background-color: #fff;
}

.btn-xs {
  height: 24px;
  padding: 0 8px;
  min-width: 42px;
  border-radius: 7px;
  font-size: 14px;
}

.btn-sm {
  height: 36px;
  padding: 0 10px;
  min-width: 56px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
}

.btn-md {
  height: 40px;
  padding: 0 12px;
  min-width: 70px;
  border-radius: 9px;
  font-size: 14px;
  font-weight: 500;
}

.btn-lg {
  height: 48px;
  padding: 0 14px;
  min-width: 84px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: bold;
}

.btn-icon {
  min-width: auto;
}
.btn-icon .ico {
  margin: 0 !important;
}

.btn-inline {
  min-width: auto;
}

.btn-block {
  display: flex;
  width: 100%;
}

.btn-round {
  border-radius: 50px;
}

.btn-gray {
  color: #fff;
  background-color: #7e8594;
}
.btn-gray:active,
.btn-gray:hover,
.btn-gray:focus {
  color: #000;
  background-color: #4b5563;
}

.btn-primary {
  color: #fff;
  background-color: #357fed;
}

.btn-kakao {
  color: #000;
  background-color: #f9e000;
}
.btn-primary:active,
.btn-primary:hover,
.btn-primary:focus {
  background-color: #2563eb;
}
.btn-kakao:active,
.btn-kakao:hover,
.btn-kakao {
  color: #000;
  background-color: #e3cc00;
}

.btn-primary-soft {
  color: #1d4ed8;
  border: 1px solid #bfdbfe;
  background-color: #eff6ff;
}
.btn-primary-soft:active,
.btn-primary-soft:hover,
.btn-primary-soft:focus {
  border-color: #93c5fd;
  background-color: #bfdbfe;
}

.btn-outline-gray {
  color: #374151;
  border-color: #d1d5db;
  background-color: #fff;
}
.btn-outline-gray:active,
.btn-outline-gray:hover,
.btn-outline-gray:focus {
  border-color: #a6adb9;
  background-color: #f3f4f6;
}

.btn-outline-primary {
  color: #1d4ed8;
  border-color: #93c5fd;
  background-color: #fff;
}
.btn-outline-primary:active,
.btn-outline-primary:hover,
.btn-outline-primary:focus {
  border-color: #357fed;
  background-color: #eff6ff;
}

.btn-xls {
  color: #fff;
  background-color: #0cae79;
}
.btn-xls.btn-xs {
  min-width: 110px;
}
.btn-xls.btn-sm {
  min-width: 120px;
}
.btn-xls .ico:first-child {
  margin-right: 2px;
}
.btn-xls:hover {
  background-color: #059669;
}

.btn-settings-xs {
  width: 24px;
  height: 24px;
  border-radius: 7px;
  border: 1px solid #d1d5db;
}

/* 버튼 그룹 */
.btn-group {
  display: flex;
  gap: 4px;
}

.btn-group-fixed {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  gap: 8px;
  height: 80px;
  padding: 0 32px;
}
@media (max-width: 1024.98px) {
  .btn-group-fixed {
    position: relative;
    display: none;
    z-index: 50;
  }
}

.btn-group-between {
  display: flex;
  align-items: center;
  gap: 6px;
  margin-top: 20px;
}
.pagination + .btn-group-between {
  margin-top: 0;
}
.btn-group-between .left,
.btn-group-between .right {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}
.btn-group-between .right:only-child {
  flex: 1 1 auto;
  justify-content: flex-end;
}
@media (min-width: 768px) {
  .btn-group-between {
    justify-content: space-between;
  }
}
@media (max-width: 767.98px) {
  .btn-group-between {
    flex-direction: column;
    align-items: stretch;
  }
  .pagination + .btn-group-between {
    margin-top: 44px;
  }
  .btn-group-between .left {
    display: none;
  }
  .btn-group-between .right {
    flex: 1 1 auto;
    flex-wrap: wrap;
  }
  .btn-group-between .right .btn {
    flex: 1 1 calc(33.3333333333% - 5.3333333333px);
  }
  .btn-group-between .right .btn.sm-full {
    flex-basis: 100%;
  }
}

.btn-group-center {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.gap-xs {
  gap: 4px !important;
}
.gap-sm {
  gap: 4px !important;
}
.gap-md {
  gap: 6px !important;
}
.gap-lg {
  gap: 8px !important;
}

/* 탭메뉴 */
.tabmenu {
  margin-bottom: 20px;
}
.tabmenu:before,
.tabmenu:after {
  content: "";
  display: table;
}

.tab-list {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  font-size: 16px;
}
.tab-list li {
  flex: 0 0 auto;
  height: 48px;
  font-weight: 500;
}
.tab-list li a {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 10px;
}
.tab-list li span {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  max-height: 2.4em;
  line-height: 1.2em;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #7e8594;
}
.tab-list li.on {
  position: relative;
}
@media (max-width: 1024.98px) {
  .tab-list li {
    width: 33.333%;
  }
}
@media (max-width: 767.98px) {
  .tab-list li {
    width: 50%;
  }
}

.tabmenu-type1 .tab-list {
  display: flex;
  justify-items: flex-start;
  text-align: center;
  flex-wrap: wrap;
  overflow: hidden;
  background-color: #fff;
  border-radius: 10px;
}
.tabmenu-type1 .tab-list li {
  flex: 1 1 auto;
  width: 20%;
  height: 48px;
}
.tabmenu-type1 .tab-list li a {
  border-radius: 10px;
}
.tabmenu-type1 .tab-list li.on a {
  border: 1px solid #bfdbfe;
  background-color: #eff6ff;
  font-weight: 700;
}
.tabmenu-type1 .tab-list li.on a span {
  color: #1d4ed8;
}
@media (min-width: 1025px) {
  .tabmenu-type1 .tab-list li a:hover {
    border-color: transparent;
    background-color: #f9fafb;
  }
  .tabmenu-type1 .tab-list li a:hover span {
    color: #374151;
  }
  .tabmenu-type1 .tab-list li.on a:hover {
    border: 1px solid #bfdbfe;
    background-color: #eff6ff;
  }
  .tabmenu-type1 .tab-list li.on a:hover span {
    color: #1d4ed8;
  }
}
.tabmenu-type1 .tab-list li:nth-child(n + 6) {
  flex: 0 0 auto;
}
@media (max-width: 1024.98px) {
  .tabmenu-type1 .tab-list {
    flex-wrap: wrap;
  }
  .tabmenu-type1 .tab-list li {
    flex: 1 1 auto;
    width: 33.333%;
  }
  .tabmenu-type1 .tab-list li:nth-child(n + 4) {
    flex: 0 0 auto;
  }
}
@media (max-width: 767.98px) {
  .tabmenu-type1 .tab-list li {
    width: 50%;
  }
  .tabmenu-type1 .tab-list li:nth-child(n + 3) {
    flex: 0 0 auto;
  }
}
.tabmenu-type1.bg-lightgray {
  border-radius: 10px;
}
.tabmenu-type1.bg-lightgray .tab-list {
  border: 1px solid #f9fafb;
  border-radius: 10px;
  background-color: #f9fafb !important;
}
@media (min-width: 1025px) {
  .tabmenu-type1.bg-lightgray li a:hover {
    background-color: #f3f4f6;
  }
}

.tabmenu-type2 li.on span {
  color: #1d4ed8;
}

.tab-content {
  display: none;
}

/* tables */
.table {
  /* margin-left: -24px;
  margin-right: -24px; */
}
.table .table-inner {
  /* padding-left: 24px; */
  /* padding-right: 24px; */
}
.table table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  border-bottom: 1px solid #e5e7eb;
  table-layout: fixed;
}
.table th {
  background-color: #f9fafb;
  font-weight: 500;
  color: #1f2937;
}
.table th > * {
  vertical-align: middle;
}
.table th,
.table td {
  height: 40px;
  padding: 4px 12px;
  border: 1px solid #e5e7eb;
  border-right: 0;
  border-left: 0;
  background-clip: padding-box;
}
.table .req {
  position: relative;
  width: 0.5em;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  vertical-align: middle;
  overflow: hidden;
  text-indent: -9999px;
  overflow: hidden;
  text-align: left;
}
.table .req:after {
  content: "";
  position: absolute;
  left: 2px;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: #ef4444;
}
@media (max-width: 1024.98px) {
  .table {
    /* margin-left: -20px;
    margin-right: -20px; */
  }
  .table .table-inner {
    /* padding-left: 20px;
    padding-right: 20px; */
  }
}

.table-scroll-x .table-inner {
  overflow-x: auto;
}

.table-list {
  text-align: center;
}
.table-list table {
  border: 0;
  border-top: 1px solid #e5e7eb;
  table-layout: auto;
}
.table-list .table-inner {
  overflow-x: auto;
  overflow-y: hidden;
}
.table-list th,
.table-list td {
  height: 40px;
  border-bottom: 1px solid #e5e7eb;
  padding: 4px 12px;
}
.table-list tbody tr:hover {
  background-color: #eff6ff;
}
.table-list th {
  color: #1f2937;
  white-space: nowrap;
}
.table-list td.text-left {
  /* padding-left: 16px;
  padding-right: 16px; */
}
.thumb-preview {
  width: 51.2px;
  height: 32px;
  border-radius: 4px;
  background-size: 19.5px auto;
  overflow: hidden;
}
.thumb-preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  vertical-align: top;
}

.table-list .thumb-preview {
  display: block;
  margin: 0 auto;
}
.table-list .thumb-preview.video {
  position: relative;
}
.table-list .thumb-preview.video::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #17171c;
  opacity: 0.5;
}
.table-list .thumb-preview.video::after {
  content: "";
  position: absolute;
  top: calc(50% - 8px);
  left: calc(50% - 8px);
  width: 16px;
  height: 16px;
  background-size: 16px 16px;
  opacity: 1;
}

.table-form-basic .table-inner {
  /* padding-left: 14px;
  padding-right: 14px; */
}
.table-form-basic table {
  display: block;
  border-bottom: 0;
}
.table-form-basic colgroup {
  display: none;
}
.table-form-basic thead th {
  text-align: center;
}
.table-form-basic tbody {
  display: block;
}
.table-form-basic tr {
  display: flex;
}
.table-form-basic th,
.table-form-basic td {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  border: 0;
  height: auto;
  min-height: 48px;
  padding: 6px 10px;
}
.table-form-basic th {
  background: #fff;
}
.table-form-basic td {
  gap: 8px;
}
.table-form-basic + .btn-group {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
@media (min-width: 1025px) {
  .table-form-basic th {
    flex: 0 0 auto;
    text-align: left;
    width: 10.31%;
  }
  .table-form-basic td {
    flex: 1 1 39.69%;
  }
  .card .table-form-basic .table-inner {
    padding-left: 14px;
    padding-right: 14px;
  }
  .table-form-basic + .btn-group {
    position: relative;
  }
  .table-form-basic + .btn-group:after {
    display: block;
    border-radius: 12px;
    padding: 12px;
    background: red;
    opacity: 0.5;
    position: absolute;
    top: 100%;
    color: #fff;
    content: "[마크업] .btn-group -> .btn-group-center로 변경 요망";
  }
  .table-form-basic + .btn-group-center {
    margin-top: 10px;
  }
}
@media (max-width: 1024.98px) {
  .table-form-basic {
    margin: 0 !important;
  }
  .table-form-basic tr {
    flex-wrap: wrap;
  }
  .table-form-basic th {
    width: 20%;
  }
  .table-form-basic td {
    flex: 1 0 80%;
  }
  .table-form-basic .table-inner {
    padding: 0;
  }
  .table-form-basic table {
    border-top: 0;
  }
  .table-form-basic tbody {
    margin-top: -20px;
  }
  .table-form-basic tr {
    flex-direction: column;
  }
  .table-form-basic th,
  .table-form-basic td {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0;
    border: 0;
    height: auto;
    min-height: auto;
  }
  .table-form-basic th {
    width: auto;
    min-height: auto;
    background: none;
    margin-top: 20px;
  }
  .table-form-basic td {
    margin-top: 8px;
    flex-basis: 100%;
    gap: 8px;
    font-size: 16px;
  }
  .table-form-basic + .btn-group-center {
    margin-top: 32px;
  }
}

.table-row table {
  border-bottom: 0;
  border-top: 1px solid #e5e7eb;
}
.table-row colgroup {
  display: none;
}
.table-row thead th {
  text-align: center;
}
.table-row tbody {
  display: block;
}
.table-row tr {
  display: flex;
  text-align: left;
  color: #1f2937;
}
.table-row th,
.table-row td {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  border-top: 0;
  height: auto;
  min-height: 48px;
  padding: 6px 16px;
}
.table-row th {
  flex: 0 0 auto;
}
.table-row td {
  gap: 8px;
}
.table-row + .btn-group .btn {
  margin-top: 0;
  margin-bottom: 0;
}
@media (min-width: 1025px) {
  .table-row th {
    width: 11.75%;
    min-width: 120px;
  }
  .table-row td {
    min-width: 0;
    flex: 1 1 38.25%;
  }
}
@media (min-width: 768px) {
  .table-row-sm th,
  .table-row-sm td {
    min-height: 40px;
    padding: 4px 12px;
  }
  .table-row-sm th {
    width: 100px;
  }
}
@media (max-width: 1024.98px) {
  .table-row tr {
    flex-wrap: wrap;
  }
  .table-row th {
    width: 20%;
  }
  .table-row td {
    flex: 1 0 80%;
  }
}
@media (max-width: 767.98px) {
  .table-row {
    margin: 0 !important;
  }
  .table-row .table-inner {
    padding: 0;
  }
  .table-row table {
    border-top: 0;
  }
  .table-row tbody {
    margin-top: -20px;
  }
  .table-row tr {
    flex-direction: column;
  }
  .table-row th,
  .table-row td {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0;
    border: 0;
    height: auto;
    min-height: auto;
  }
  .table-row th {
    width: auto;
    min-height: auto;
    background: none;
    margin-top: 20px;
  }
  .table-row td {
    margin-top: 8px;
    flex-basis: 100%;
    gap: 8px;
    font-size: 16px;
  }
}

th > .req,
label + .req {
  overflow: visible !important;
}
th > .req:before,
label + .req:before {
  display: block;
  position: absolute;
  z-index: 10;
  width: 200px;
  margin-left: 10px;
  padding: 12px;
  border-radius: 0 12px 12px 12px;
  color: #fff;
  text-indent: 0;
  font-style: normal;
  white-space: pre-wrap;
}

th > .req:before {
  background: blue;
  content: '[마크업 가이드 위배] .req 필수입력 표시를 span 또는 label 내부로 옮겨주세요.\a\a- 올바른 예시 : <span>아이디<i class="req">필수입력</i></span>';
}

label + .req:before {
  background: red;
  content: '[마크업 가이드 위배] .req 필수입력 표시를 label 내부로 옮겨주세요.\a\a- 올바른 예시 : <label for="">아이디<i class="req">필수입력</i></label>';
}

/* 페이징 */
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #4b5563;
  margin-top: 20px;
}
.pagination a {
  transition-duration: 0.3s;
}
.pagination a:hover {
  /* color: #2563eb;
  background-color: #e3efff;
  font-weight: 500; */
}
.pagination a,
.pagination strong {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  height: 40px;
  min-width: 40px;
  margin: 0 2px;
  padding: 0 8px;
}
.pagination strong {
  color: #2563eb;
  background-color: #e3efff;
  font-weight: 500;
}
.pagination .direction {
  width: 40px;
  overflow: hidden;
  text-indent: -9999px;
  text-align: left;
  white-space: nowrap;
  background-size: 40px;
}
.pagination .direction:disabled,
.pagination .direction.disabled {
  background-color: transparent;
}
.pagination .direction:disabled:hover,
.pagination .direction:disabled:focus,
.pagination .direction.disabled:hover,
.pagination .direction.disabled:focus {
  background-color: transparent;
}
.pagination .direction:hover,
.pagination .direction:focus {
  background-color: #f3f4f6;
}
.pagination .prev {
}
.pagination .prev:disabled,
.pagination .prev.disabled {
}
.pagination .next {
}
.pagination .next:disabled,
.pagination .next.disabled {
}
.pagination .last {
}
.pagination .last:disabled,
.pagination .last.disabled {
}
@media (max-width: 1024.98px) {
  .pagination a,
  .pagination strong {
    height: 36px;
    min-width: 36px;
    margin: 0 2px;
    padding: 0 6px;
  }
  .pagination .direction {
    width: 36px;
    background-size: 36px;
  }
}
@media (max-width: 767.98px) {
  .pagination .direction.first,
  .pagination .direction.last {
    display: none;
  }
}

/* 상태 표시 */
.label-status {
  display: inline-block;
  min-width: 48px;
  border-radius: 14px;
  padding: 0 6px;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
}

.label-gray,
.label-off {
  color: #4b5563;
  background: #f3f4f6;
}

.label-green,
.label-on {
  color: #059669;
  background: #d1fae5;
}

.label-blue {
  color: #2563eb;
  background: #e3efff;
}

.label-orange {
  color: #ea580c;
  background: #ffe6c9;
}

.label-red {
  color: #dc2626;
  background: #fee2e2;
}

.label-violet {
  color: #7c3aed;
  background: #ede9fe;
}

.form-date {
  max-width: 150px;
  appearance: none;
  text-align: center !important;
  border: 1px solid #d1d5db;
  color: #374151;
  background: #fff;
  vertical-align: middle;
  font-size: 14px;
  height: 40px;
  border-radius: 9px;
  padding: 10px 16px;
  line-height: 30px;
  transition-duration: 0.2s;
  transition-property: color, border-color, box-shadow;
  will-change: color, border-color, box-shadow;
}
.form-controlM {
  max-width: 100%;
  appearance: none;
  text-align: left !important;
  border: 1px solid #d1d5db;
  color: #374151;
  background: #fff;
  vertical-align: middle;
  font-size: 14px;
  height: 40px;
  border-radius: 9px;
  padding: 10px 16px;
  /* line-height: 30px; */
  /* transition-duration: 0.2s; */
  transition-property: color, border-color, box-shadow;
  will-change: color, border-color, box-shadow;
}

/* forms */
.form-control {
  max-width: 100%;
  appearance: none;
  text-align: left !important;
  border: 1px solid #d1d5db;
  color: #374151;
  background: #fff;
  vertical-align: middle;
  font-size: 14px;
  height: 40px;
  border-radius: 9px;
  padding: 10px 16px;
  line-height: 30px;
  transition-duration: 0.2s;
  transition-property: color, border-color, box-shadow;
  will-change: color, border-color, box-shadow;
}
.form-control::-ms-clear {
  display: none;
}
.form-control.inline {
  width: auto !important;
}
.form-control-sm {
  min-width: 182px;
  border: 1px solid #d1d5db;
  height: 36px;
  padding: 7px 12px;
  border-radius: 8px;
  font-size: 14px;
  color: #374151;
  vertical-align: middle;
  line-height: 20px;
  background: #fff;
}
.form-control-sm.invalid {
  border-color: #ef4444 !important;
  color: #ef4444;
}
.form-control-sm.invalid + .invalid-message {
  display: block;
  color: #ef4444;
}
.form-control-sm:hover {
  border-color: #bfdbfe;
}
.form-control-sm:focus {
  outline: 0;
  position: relative;
  border-color: #357fed;
}
.form-control-sm:disabled,
.form-control-sm[readonly="readonly"],
.form-control-sm.readonly {
  background-color: #f3f4f6;
  opacity: 1;
}
.form-control-sm:disabled::-ms-value,
.form-control-sm[readonly="readonly"]::-ms-value,
.form-control-sm.readonly::-ms-value {
  color: #7e8594 !important;
}
.form-control-sm:disabled {
  color: #4b5563;
}
.form-control-sm:disabled:hover,
.form-control-sm:disabled:focus {
  box-shadow: none;
}
.form-control-sm:read-only:not(select):not(span) {
  background-color: #f9fafb;
  opacity: 1;
}
.form-control-sm:is(select) {
  min-width: 132px;
  max-width: 100%;
  padding-right: 30px;
  background-size: 12px;
  appearance: none;
}
.form-control-sm:is(select)::-ms-expand {
  display: none;
}
.form-control-sm:is(select):hover {
  border-color: #bfdbfe;
}
.form-control-sm:is(select):focus {
  outline: 0;
  position: relative;
  border-color: #357fed;
}
.form-control-sm:is(select).invalid {
  border-color: #ef4444 !important;
  color: #ef4444;
}
.form-control-sm:is(select).invalid + .invalid-message {
  display: block;
  color: #ef4444;
}
.form-control-sm:is(select):disabled,
.form-control-sm:is(select)[readonly="readonly"],
.form-control-sm:is(select).readonly {
  background-color: #f3f4f6;
  opacity: 1;
}
.form-control-sm:is(select):disabled::-ms-value,
.form-control-sm:is(select)[readonly="readonly"]::-ms-value,
.form-control-sm:is(select).readonly::-ms-value {
  color: #7e8594 !important;
}
.form-control-sm:is(select):read-only:not(select):not(span) {
  background-color: #f9fafb;
  opacity: 1;
}
.form-control-sm.full {
  min-width: 100%;
}
.form-control-lg {
  height: 48px;
  padding: 13px 20px;
  font-size: 14px;
  border-radius: 10px;
}
.form-control:is(select) {
  min-width: 132px;
  max-width: 100%;
  line-height: normal;
  padding-right: 30px;

  background-size: 12px;
}
.form-control:is(select)::-ms-expand {
  display: none;
}
.form-control:is(select):hover {
  border-color: #bfdbfe;
}
.form-control:is(select):focus {
  outline: 0;
  position: relative;
  border-color: #357fed;
}
@media (max-width: 767.98px) {
  .form-control:is(select) {
    padding-top: 7px;
    padding-bottom: 7px;
  }
}
.form-control:is(textarea) {
  height: auto;
  min-height: 120px;
  padding-top: 12px;
  padding-bottom: 12px;
  overflow: auto;
  line-height: inherit;
  resize: none;
  background-size: 5.5px 5.5px;
  background-position: right 6px bottom 6px;
}
.form-control.invalid {
  border-color: #ef4444 !important;
  color: #ef4444;
}
.form-control.invalid + .invalid-message {
  display: block;
  color: #ef4444;
}
.form-control:hover {
  border-color: #bfdbfe;
}
.form-control:focus {
  outline: 0;
  position: relative;
  border-color: #357fed;
}
.form-control:disabled,
.form-control[readonly="readonly"],
.form-control.readonly {
  background-color: #f3f4f6;
  opacity: 1;
}
.form-control:disabled::-ms-value,
.form-control[readonly="readonly"]::-ms-value,
.form-control.readonly::-ms-value {
  color: #7e8594 !important;
}
.form-control:disabled {
  border-color: #e5e7eb;
  color: #4b5563;
}
.form-control:disabled:hover,
.form-control:disabled:focus {
  box-shadow: none;
}
.form-control:read-only:not(select):not(span) {
  background-color: #f9fafb;
  opacity: 1;
}
.form-control::-webkit-input-placeholder {
  color: #7e8594;
  opacity: 1;
  font-size: 14px;
}
.form-control:-ms-input-placeholder {
  color: #7e8594;
  opacity: 1;
  font-size: 14px;
}
@media (max-width: 1024.98px) {
  .form-control {
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
  }
}

.form-control.full,
.form-control-sm.full {
  width: 100%;
}
@media (max-width: 1024.98px) {
  .form-control.full,
  .form-control-sm.full {
    max-width: 100% !important;
  }
}
@media (max-width: 767.98px) {
  .form-control,
  .form-control-sm {
    min-width: auto !important;
    width: 100% !important;
    font-size: 16px !important;
    padding-left: 16px;
    padding-right: 16px;
  }
  .form-control::-webkit-input-placeholder,
  .form-control-sm::-webkit-input-placeholder {
    font-size: 16px;
  }
  .form-control:-ms-input-placeholder,
  .form-control-sm:-ms-input-placeholder {
    font-size: 16px;
  }
}

.check-item {
  position: relative;
  cursor: pointer;
}
.check-item [type="checkbox"],
.check-item [type="radio"] {
  position: absolute;
  width: 1px;
  height: 1px;
  opacity: 0;
}
.check-item [type="checkbox"] + .label,
.check-item [type="checkbox"] + label,
.check-item [type="radio"] + .label,
.check-item [type="radio"] + label {
  position: relative;
  display: inline-block;
}
.check-item [type="checkbox"] + .label:before,
.check-item [type="checkbox"] + label:before,
.check-item [type="radio"] + .label:before,
.check-item [type="radio"] + label:before {
  box-sizing: border-box;
  display: inline-block;
  width: 16px;
  height: 16px;
  vertical-align: middle;
  border-radius: 2px;
  margin-top: -2px;
  margin-right: 5px;
  background-color: #fff;
  content: "";
  transition-duration: 0.2s;
  transition-property: border-color, box-shadow, background-color;
  will-change: border-color, box-shadow, background-color;
}
.check-item [type="checkbox"] + .label.sr-only,
.check-item [type="checkbox"] + label.sr-only,
.check-item [type="radio"] + .label.sr-only,
.check-item [type="radio"] + label.sr-only {
  position: static;
  width: 16px;
  height: 16px;
  vertical-align: middle;
  margin: 0;
  overflow: visible;
}
.check-item [type="checkbox"] + .label.sr-only:before,
.check-item [type="checkbox"] + label.sr-only:before,
.check-item [type="radio"] + .label.sr-only:before,
.check-item [type="radio"] + label.sr-only:before {
  margin-right: 0;
}
.check-item [type="checkbox"] + .label::before,
.check-item [type="checkbox"] + label::before {
  border-radius: 3px;
  border: 1px solid #a6adb9;
}
.check-item [type="checkbox"]:checked + .label:before,
.check-item [type="checkbox"]:checked + label:before {
  border-color: #357fed;
  background-size: 16px;
}
.check-item [type="checkbox"]:checked:hover + .label:before,
.check-item [type="checkbox"]:checked:hover + label:before {
  border-color: #2563eb;
  background-color: #2563eb;
}
.check-item [type="checkbox"]:checked:disabled + .label:before,
.check-item [type="checkbox"]:checked:disabled + label:before {
  background-color: #f3f4f6;
}
.check-item [type="checkbox"]:disabled + .label:before,
.check-item [type="checkbox"]:disabled + label:before {
  border-color: #e5e7eb;
  background-color: #f3f4f6;
}
.check-item [type="checkbox"]:disabled:checked + .label:before,
.check-item [type="checkbox"]:disabled:checked + label:before {
  border-color: #e5e7eb;
  background-size: 16px;
}
.check-item [type="checkbox"]:hover + .label:before,
.check-item [type="checkbox"]:hover + label:before {
  border-color: #a6adb9;
  background-color: #f3f4f6;
}
.check-item [type="checkbox"]:focus + .label:before,
.check-item [type="checkbox"]:focus + label:before {
  border-color: #357fed;
}
.check-item [type="radio"] + .label:before,
.check-item [type="radio"] + label:before {
  border-radius: 50%;
  box-shadow: inset 0 0 0 1px #a6adb9;
}
.check-item [type="radio"]:checked + .label:before,
.check-item [type="radio"]:checked + label:before {
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: 0 0 0 5px #357fed inset;
}
.check-item [type="radio"]:checked:hover + .label:before,
.check-item [type="radio"]:checked:hover + label:before {
  box-shadow: inset 0 0 0 5px #2563eb;
}
.check-item [type="radio"]:hover + .label:before,
.check-item [type="radio"]:hover + label:before {
  box-shadow: inset 0 0 0 1px #a6adb9;
  background-color: #f3f4f6;
}
.check-item [type="radio"]:disabled + .label:before,
.check-item [type="radio"]:disabled + label:before {
  box-shadow: 0 0 0 1px #e5e7eb inset;
  background-color: #f3f4f6;
}
.check-item [type="radio"]:disabled:checked + .label:before,
.check-item [type="radio"]:disabled:checked + label:before {
  box-shadow: 0 0 0 4px #e5e7eb inset;
  background-color: #f3f4f6;
}
.check-item-lg [type="checkbox"],
.check-item-lg [type="radio"] {
  position: absolute;
  width: 1px;
  height: 1px;
  opacity: 0;
}
.check-item-lg [type="checkbox"] + .label,
.check-item-lg [type="checkbox"] + label,
.check-item-lg [type="radio"] + .label,
.check-item-lg [type="radio"] + label {
  position: relative;
  display: inline-block;
}
.check-item-lg [type="checkbox"] + .label:before,
.check-item-lg [type="checkbox"] + label:before,
.check-item-lg [type="radio"] + .label:before,
.check-item-lg [type="radio"] + label:before {
  content: "";
  box-sizing: border-box;
  display: inline-block;
  width: 20px;
  height: 20px;
  vertical-align: middle;
  border-radius: 2px;
  margin-top: -2px;
  margin-right: 5px;
  background-color: #fff;
  content: "";
  transition-duration: 0.2s;
  transition-property: border-color, box-shadow, background-color;
  will-change: border-color, box-shadow, background-color;
}
.check-item-lg [type="checkbox"] + .label.sr-only,
.check-item-lg [type="checkbox"] + label.sr-only,
.check-item-lg [type="radio"] + .label.sr-only,
.check-item-lg [type="radio"] + label.sr-only {
  position: static;
  width: 16px;
  height: 16px;
  vertical-align: middle;
  margin: 0;
  overflow: visible;
}
.check-item-lg [type="checkbox"] + .label.sr-only:before,
.check-item-lg [type="checkbox"] + label.sr-only:before,
.check-item-lg [type="radio"] + .label.sr-only:before,
.check-item-lg [type="radio"] + label.sr-only:before {
  margin-right: 0;
}
.check-item-lg [type="checkbox"] + .label:before,
.check-item-lg [type="checkbox"] + label:before {
  border-radius: 3px;
  border: 1px solid #a6adb9;
}
.check-item-lg [type="checkbox"]:checked + .label:before,
.check-item-lg [type="checkbox"]:checked + label:before {
  border-color: #357fed;
  background-size: 20px;
}
.check-item-lg [type="checkbox"]:checked:hover + .label:before,
.check-item-lg [type="checkbox"]:checked:hover + label:before {
  border-color: #2563eb;
  background-color: #2563eb;
}
.check-item-lg [type="checkbox"]:checked:disabled + .label:before,
.check-item-lg [type="checkbox"]:checked:disabled + label:before {
  border-color: #d1d5db;
  background-color: #f3f4f6;
}
.check-item-lg [type="checkbox"]:hover + .label:before,
.check-item-lg [type="checkbox"]:hover + label:before {
  border-color: #a6adb9;
  background-color: #f3f4f6;
}
.check-item-lg [type="checkbox"]:focus + .label:before,
.check-item-lg [type="checkbox"]:focus + label:before {
  border-color: #357fed;
}
.check-item-lg [type="checkbox"]:disabled + .label:before,
.check-item-lg [type="checkbox"]:disabled + label:before {
  border-color: #e5e7eb;
  background-color: #f3f4f6;
}
.check-item-lg [type="checkbox"]:disabled:checked + .label:before,
.check-item-lg [type="checkbox"]:disabled:checked + label:before {
  border-color: #e5e7eb;
  background-size: 20px;
}
.check-item-lg [type="radio"] + .label:before,
.check-item-lg [type="radio"] + label:before {
  border-radius: 50%;
  box-shadow: inset 0 0 0 1px #a6adb9;
}
.check-item-lg [type="radio"]:checked + .label:before,
.check-item-lg [type="radio"]:checked + label:before {
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: 0 0 0 6px #357fed inset;
}
.check-item-lg [type="radio"]:checked:hover + .label:before,
.check-item-lg [type="radio"]:checked:hover + label:before {
  box-shadow: inset 0 0 0 6px #2563eb;
}
.check-item-lg [type="radio"]:hover + .label:before,
.check-item-lg [type="radio"]:hover + label:before {
  box-shadow: inset 0 0 0 1px #a6adb9;
  background-color: #f3f4f6;
}
.check-item-lg [type="radio"]:disabled + .label:before,
.check-item-lg [type="radio"]:disabled + label:before {
  box-shadow: 0 0 0 1px #e5e7eb inset;
  background-color: #f3f4f6;
}
.check-item-lg [type="radio"]:disabled:checked + .label:before,
.check-item-lg [type="radio"]:disabled:checked + label:before {
  box-shadow: 0 0 0 6px #e5e7eb inset;
  background-color: #f3f4f6;
}

.check-switch {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
  cursor: pointer;
}
.check-switch .label {
  position: relative;
  display: inline-flex;
  align-items: center;
  text-align: center;
  min-width: 48px;
  height: 24px;
  border-radius: 15px;
  padding-left: 28px;
  padding-right: 8px;
  background-color: #a6adb9;
  color: #fff;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  transition-duration: 0.3s;
  transition-property: background-color, hover;
}
.check-switch .label:after {
  content: "";
  position: absolute;
  top: 3px;
  left: 3px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #fff;
  transition-duration: 0.3s;
}
.check-switch .label span {
  display: block;
  line-height: 23px;
}
.check-switch .label .text-checked {
  display: none;
  opacity: 0;
  transition-duration: 0.3s;
  transition-timing-function: ease-in;
}
.check-switch input {
  position: absolute;
  width: 1px;
  height: 1px;
  opacity: 0;
}
.check-switch input:focus + label,
.check-switch input:focus + .label {
  outline: 2px solid rgba(53, 127, 237, 0.5);
}
.check-switch input:not(:focus-visible) + label,
.check-switch input:not(:focus-visible) + .label {
  outline: 0;
}
.check-switch input:checked + .label {
  background-color: #357fed;
  padding-left: 12px;
  padding-right: 23px;
}
.check-switch input:checked + .label:after {
  left: calc(100% - 21px);
}
.check-switch input:checked + .label .text-uncheck {
  display: none;
  opacity: 0;
}
.check-switch input:checked + .label .text-checked {
  display: inline;
  opacity: 1;
}
.check-switch input:disabled + .label {
  opacity: 0.5;
}
.check-switch.check-switch-fix {
  max-width: 60px;
  width: 80px;
}
.check-switch.check-switch-fix .label {
  width: 100%;
  padding-left: 23px;
}
.check-switch.check-switch-fix .label span {
  line-height: 1;
}
.check-switch.check-switch-fix input:checked + .label {
  padding-left: 10px;
}

.pw-group {
  position: relative;
}
.pw-group .form-pw {
  width: 100%;
}
.pw-group input[type="password"],
.pw-group input[type="text"] {
  padding-right: 44px;
}
.pw-group .btn-pw {
  position: absolute;
  top: 0;
  right: 6px;
  width: 38px;
  height: 40px;
  padding: 0 10px;
  font-size: 0;
  line-height: 0;
  overflow: hidden;
}
.pw-group .btn-pw-off::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 16px;
  height: 16px;
  background-size: cover;
  transform: translate(-50%, -50%);
}
.pw-group .btn-pw-on::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 16px;
  height: 16px;
  background-size: cover;
  transform: translate(-50%, -50%);
}
.pw-group .form-control-sm {
  padding-right: 39px !important;
}
.pw-group .form-control-sm + .btn-pw {
  right: 2px;
  height: 36px;
}
.pw-group .form-control-sm + .btn-pw.btn-pw-off::before {
  width: 12px;
  height: 12px;
  background-size: cover;
}
.pw-group .form-control-sm + .btn-pw.btn-pw-on::before {
  width: 12px;
  height: 12px;
  background-size: cover;
}
@media (max-width: 1024.98px) {
  .pw-group {
    width: 100% !important;
    max-width: 100% !important;
  }
  .pw-group .form-pw {
    width: 100% !important;
    max-width: 100% !important;
  }
}

.input-group {
  flex: 1 1 100%;
  display: flex;
  align-items: center;
  gap: 8px;
}
.input-group.fit {
  flex: 0 0 auto;
}
.input-group > * {
  flex: 0 0 auto;
}
.input-group > .width-auto,
.input-group > .form-control,
.input-group > .form-control-sm,
.input-group > .dropdown {
  flex: 1 1 auto;
}
@media (max-width: 767.98px) {
  .input-group {
    max-width: 100% !important;
    width: 100% !important;
  }
}
.input-group.sm-drop {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
@media (max-width: 767.98px) {
  .input-group.sm-drop {
    flex-direction: column;
    align-items: flex-start;
  }
}
.input-group.md-drop {
  display: flex;
  flex-wrap: nowrap;
  gap: 8px;
}
@media (max-width: 1024.98px) {
  .input-group.md-drop {
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
  }
}

.form-search-keyword {
  padding-left: 29px;
  background-size: 16px;
}

.form-datepicker {
  padding-right: 32px;
  width: 100%;
  min-width: 130px;
}
@media (max-width: 1024.98px) {
  .form-datepicker {
    width: 100%;
  }
}

.datepicker {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}
.datepicker.full {
  width: 100%;
  max-width: 100%;
}
.datepicker.full .form-datepicker {
  width: 100%;
}
.datepicker .btn-datepicker {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 0 10px;
  box-shadow: none;
}
.datepicker:focus-within .form-datepicker,
.datepicker:hover .form-datepicker {
  border-color: #bfdbfe;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
}
@media (max-width: 767.98px) {
  .datepicker {
    width: 100%;
  }
}

.radio-btn-group {
  display: flex;
  height: 40px;
  margin: 0;
}
.radio-btn-group .check-item {
  width: 60px;
}
.radio-btn-group .check-item label {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #d1d5db;
  cursor: pointer;
}
.radio-btn-group .check-item:first-child label {
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
}
.radio-btn-group .check-item:first-child [type="radio"]:checked + .label::after,
.radio-btn-group .check-item:first-child [type="radio"]:checked + label::after {
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
}
.radio-btn-group .check-item:last-child label {
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}
.radio-btn-group .check-item:last-child [type="radio"]:checked + .label::after,
.radio-btn-group .check-item:last-child [type="radio"]:checked + label::after {
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}
.radio-btn-group .check-item + .check-item {
  margin: 0 !important;
}
.radio-btn-group .check-item + .check-item label {
  border-left: 0;
}
.radio-btn-group .check-item [type="radio"] + .label,
.radio-btn-group .check-item [type="radio"] + label {
  width: 100%;
  height: 100%;
}
.radio-btn-group .check-item [type="radio"] + .label::before,
.radio-btn-group .check-item [type="radio"] + label::before {
  display: none !important;
  box-shadow: none;
}
.radio-btn-group .check-item [type="radio"]:checked + .label,
.radio-btn-group .check-item [type="radio"]:checked + label {
  background-color: #eff6ff;
  color: #1d4ed8;
}
.radio-btn-group .check-item [type="radio"]:checked + .label::after,
.radio-btn-group .check-item [type="radio"]:checked + label::after {
  content: "";
  position: absolute;
  top: -1px;
  left: -1px;
  width: 100%;
  height: 100%;
  border: 1px solid #357fed;
}
.radio-btn-group .check-item [type="radio"]:checked + .label::before,
.radio-btn-group .check-item [type="radio"]:checked + label::before {
  background: none;
  box-shadow: none;
}
.radio-btn-group .check-item [type="radio"]:checked:hover + .label::before,
.radio-btn-group .check-item [type="radio"]:checked:hover + label::before {
  box-shadow: none;
}
.radio-btn-group .check-item [type="radio"]:hover + .label::before,
.radio-btn-group .check-item [type="radio"]:hover + label::before {
  box-shadow: none;
  background: none;
}
.radio-btn-group .check-item [type="radio"]:disabled + .label::before,
.radio-btn-group .check-item [type="radio"]:disabled + label::before {
  box-shadow: none;
  background: none;
}
.radio-btn-group .check-item [type="radio"]:disabled:checked + .label::before,
.radio-btn-group .check-item [type="radio"]:disabled:checked + label::before {
  box-shadow: none;
  background: none;
}
.radio-btn-group .check-item .label {
  font-weight: 500;
  font-size: 14px;
  color: #4b5563;
}
@media (max-width: 1024.98px) {
  .radio-btn-group {
    width: 100%;
  }
  .radio-btn-group .check-item {
    flex: 1 1 auto;
    width: 16.667%;
  }
}

@media (min-width: 1025px) {
  .input-group-search {
    max-width: 600px;
  }
}
@media (min-width: 768px) {
  .input-group-search .dropdown {
    flex: 0 0 197px;
  }
  .input-group-search .form-control {
    flex: 1 1 auto;
  }
}
@media (max-width: 767.98px) {
  .input-group-search {
    flex-direction: column;
  }
}

.input-group-datepicker {
  flex: 1 1 auto;
  display: inline-flex;
  align-items: center;
  gap: 6px;
}
.input-group-datepicker .input-addon {
  flex: 0 0 auto;
  color: #d1d5db;
}
.input-group-datepicker .datepicker {
  flex: 1 1 auto;
}
@media (min-width: 1025px) {
  .input-group-datepicker {
    min-width: 272px;
    max-width: 424px;
  }
}
@media (max-width: 1024.98px) {
  .input-group-datepicker {
    flex-direction: row;
  }
}

.input-group-date-range {
  max-width: 100%;
  margin: 0;
}
@media (min-width: 1025px) {
  .input-group-date-range {
    flex-wrap: wrap;
    column-gap: 12px;
  }
}
@media (max-width: 1024.98px) {
  .input-group-date-range {
    align-items: flex-start;
    flex-direction: column;
    flex: 1 1 auto;
  }
  .input-group-date-range .input-group-datepicker {
    width: 100%;
  }
}

.dropdown {
  display: inline-block;
  min-width: 80px;
  max-width: 100%;
  vertical-align: middle;
}
.dropdown.full {
  width: 100%;
}
.table-scroll-x .dropdown.dropdown-up.on .btn-select {
  border-radius: 0 0 9px 9px;
}
.table-scroll-x .dropdown.dropdown-up dd {
  top: auto;
  bottom: 34px;
}
.table-scroll-x .dropdown.dropdown-up .option-list {
  border-radius: 9px 9px 0px 0px;
}
.dropdown:hover .btn-select {
  border-color: #bfdbfe;
}
.dropdown:hover .option-list {
  border-top-color: #d1d5db !important;
  border-color: #bfdbfe;
}
.dropdown .select {
  color: #374151;
  position: relative;
}
.dropdown .btn-select {
  width: 100%;
  height: 40px;
  padding: 0 30px 0 16px;
  font-size: 14px;
  text-align: left;
  white-space: nowrap;
  background-size: 16px;
  background-repeat: no-repeat;
  background-position: right 11px center;
  background-color: #fff;
  border-radius: 9px;
  border: 1px solid #d1d5db;
}
.dropdown .btn-select:disabled {
  border-color: #e5e7eb;
  pointer-events: none;
  background-color: #f3f4f6;
}
.dropdown dd {
  display: none;
  position: absolute;
  top: 38px;
  left: 0;
  right: 0;
  z-index: 100;
}
.dropdown .option-list {
  max-height: 328px;
  padding: 4px;
  overflow-y: auto;
  border: 1px solid #d1d5db;
  background-color: #fff;
  border-radius: 0px 0px 9px 9px;
}
.dropdown .option-list li {
  width: 100%;
}
.dropdown .option-list a,
.dropdown .option-list button {
  display: block;
  padding: 7px 12px;
  border-radius: 7px;
  font-size: 14px;
  white-space: nowrap;
}
.dropdown .option-list a:hover,
.dropdown .option-list button:hover {
  background: #f3f4f6;
}
.dropdown .option-list a:active,
.dropdown .option-list button:active {
  background-color: #eff6ff;
  color: #1d4ed8;
}
.dropdown .option-list button {
  width: 100%;
  text-align: left;
}
.dropdown.on {
  border-color: #e5e7eb;
  position: relative;
  z-index: 10;
}
.dropdown.on .btn-select {
  border-radius: 9px 9px 0 0;
}
.dropdown.on dd {
  display: block;
}
.dropdown-sm .btn-select {
  height: 36px;
  padding-left: 12px;
  border-radius: 8px;
  background-size: 12px;
  background-position: right 12px center;
}
.dropdown-sm dd {
  top: 34px;
}
@media (max-width: 1024.98px) {
  .dropdown {
    max-width: 100% !important;
  }
}
@media (max-width: 767.98px) {
  .dropdown {
    width: 100% !important;
    max-width: none !important;
  }
  .dropdown .btn-select {
    font-size: 16px;
  }
  .dropdown .option-list a,
  .dropdown .option-list button {
    font-size: 16px;
  }
}

#inseq-datepicker {
  position: absolute;
  width: 356px;
  padding: 16px 20px;
  font-size: 16px;
  background-color: #fff;
  border: 1px solid #d1d5db;
  border-radius: 9px;
  z-index: 1000;
}
.modal-popup #inseq-datepicker {
  z-index: 1200;
}
#inseq-datepicker.right {
  left: auto !important;
  right: 0 !important;
}
#inseq-datepicker caption {
  height: 0;
  font-size: 0;
  line-height: 0;
  overflow: hidden;
}
#inseq-datepicker table {
  table-layout: auto;
  margin-top: 0;
  width: 100%;
  text-align: center;
  border: 0px solid #d1d5db;
  color: #374151;
  line-height: 2;
}
#inseq-datepicker table a {
  line-height: 2;
}
#inseq-datepicker table th {
  height: auto;
  background: none;
  border: 0;
  margin: 0;
  color: #374151;
  text-align: center;
  line-height: 40px;
  font-weight: 500;
}
#inseq-datepicker table td {
  padding: 0 !important;
  margin: 0;
  height: auto;
  text-align: center;
  border: 0;
  line-height: 1;
}
#inseq-datepicker table tbody td {
  height: 40px;
  color: #4b5563;
}
#inseq-datepicker table tbody td a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  display: block;
  color: #374151;
  text-decoration: none;
  transition-duration: 0.3s;
  transition-property: background-color;
  will-change: background-color;
}
@media (min-width: 1025px) {
  #inseq-datepicker table tbody td a:hover {
    background-color: #f3f4f6;
  }
  #inseq-datepicker table tbody td a:active {
    color: #fff !important;
    background-color: #357fed;
  }
}
#inseq-datepicker table tbody td a.today {
  color: #1d4ed8 !important;
  background-color: #bfdbfe;
}
#inseq-datepicker #tableCalendarTitle td {
  font-size: 18px;
}
#inseq-datepicker #tableCalendarTitle td a {
  font-size: inherit;
}
#inseq-datepicker #tableMonthTitle td a {
  font-size: 18px;
}
#inseq-datepicker #tableYearTitle {
  height: 40px;
}
#inseq-datepicker #tableYearTitle td {
  font-size: 18px;
  font-weight: bold;
}
#inseq-datepicker #tableYearTitle td a.prev {
  margin-left: 30px;
}
#inseq-datepicker #tableYearTitle td a.next {
  margin-right: 30px;
}
#inseq-datepicker #tableCalendarTitle a,
#inseq-datepicker #tableYear a,
#inseq-datepicker #tableMonthTitle a,
#inseq-datepicker #tableMonth a {
  font-weight: bold;
  color: #484e57;
}
#inseq-datepicker #tableMonth,
#inseq-datepicker #tableYear {
  margin: 0 -6px -2px;
  width: calc(100% + 12px);
}
#inseq-datepicker #tableMonth td,
#inseq-datepicker #tableYear td {
  padding: 4px !important;
}
#inseq-datepicker #tableMonth td a,
#inseq-datepicker #tableYear td a {
  display: block;
  height: 52px;
  line-height: 52px;
  border-radius: 8px;
  border: 1px solid #d1d5db;
  font-weight: 400;
}
#inseq-datepicker #tableMonth td a:hover,
#inseq-datepicker #tableYear td a:hover {
  background-color: #f3f4f6;
  border-color: #d1d5db;
}
#inseq-datepicker #tableMonth td a:active,
#inseq-datepicker #tableYear td a:active {
  color: #fff !important;
  background-color: #357fed;
  border-color: #357fed;
}
#inseq-datepicker #tableCalendar th:first-child,
#inseq-datepicker #tableCalendar td:first-child {
  color: #ff4d6d;
}
#inseq-datepicker #tableCalendar th:first-child a,
#inseq-datepicker #tableCalendar td:first-child a {
  color: inherit;
}
#inseq-datepicker #tableCalendar th:last-child a,
#inseq-datepicker #tableCalendar td:last-child a {
  color: inherit;
}
#inseq-datepicker .prev,
#inseq-datepicker .next {
  display: block;
  width: 28px;
  height: 28px;
  line-height: 28px;
  border: 0;
  padding: 0;
  background-size: 28px;
  text-indent: -9999px;
  text-align: left;
  overflow: hidden;
}
#inseq-datepicker .prev {
  float: left;
  margin-left: 40px;
  background-position: right center;
}
#inseq-datepicker .next {
  float: right;
  margin-right: 40px;
  background-position: left center;
}
#inseq-datepicker .btn-cal-close {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 5px;
  right: 5px;
  width: 28px;
  height: 28px;
  opacity: 0.6;
}
#inseq-datepicker .btn-cal-close a {
  display: block;
  width: 30px;
  height: 30px;
  color: transparent;
  background-size: 28px;
}
@media (max-width: 767.98px) {
  #inseq-datepicker {
    left: 50% !important;
    margin-left: -178px !important;
  }
}

/* 모달, 윈도우 팝업 공통 */
.popup-header {
  display: flex;
  align-items: center;
  padding: 19px 19px 19px 24px;
}
.popup-header .popup-title {
  position: relative;
  /* font-family: -apple-system, BlinkMacSystemFont, "Spoqa Han Sans Neo",
    "malgun gothic", Arial, sans-serif; */
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.02em;
  color: #1f2937;
}

.popup-body {
  padding: 24px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.popup-body:first-child {
  margin-top: 20px;
}

.popup-footer {
  position: relative;
  padding: 20px;
  border-top: 1px solid #e5e7eb;
}
.popup-footer .btn-group-center {
  gap: 8px;
}

.btn-close-popup {
  display: block;
  position: absolute;
  top: 0;
  right: -9999px;
  width: 1px;
  height: 1px;
  border: 0;
  text-decoration: none;
  overflow: hidden;
  background-size: 40px 40px;
  overflow: hidden;
}
.btn-close-popup:focus,
.btn-close-popup:active {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  vertical-align: middle;
  overflow: hidden;
  text-indent: -9999px;
  overflow: hidden;
  text-align: left;
  top: 0;
  right: 0;
  width: auto;
  height: auto;
  background-color: #e5e7eb;
}

.popup-header ~ .btn-close-popup {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  vertical-align: middle;
  overflow: hidden;
  text-indent: -9999px;
  overflow: hidden;
  text-align: left;
  top: 16px;
  right: 24px;
  width: 40px;
  height: 40px;
  background-size: 40px auto;
}
@media (max-width: 767.98px) {
  .popup-header ~ .btn-close-popup {
    top: 20px;
    right: 20px;
    height: 28px;
  }
}
.popup-header ~ .btn-close-popup:focus,
.popup-header ~ .btn-close-popup:active,
.popup-header ~ .btn-close-popup:hover {
  background-color: #e5e7eb;
  border-radius: 6px;
}

/* 모달 팝업 */
@media (min-width: 1025px) {
  .modal-open {
    overflow: hidden;
    padding-right: 16px;
  }
  .modal-open .btn-group-fixed {
    margin-right: 16px;
  }
}
@media (max-width: 1024.98px) {
  .modal-open {
    position: fixed;
    width: 100%;
  }
}

.modal-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1100;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-popup .dimed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.modal-popup .popup-inner {
  position: relative;
  z-index: 1100;
  width: 660px;
  transition-duration: 0.3s;
  transition-property: transform;
  background-color: #fff;
  overflow: hidden;
  border-radius: 12px;
  box-shadow: 0px 2px 24px 0px rgba(0, 0, 0, 0.2);
}
.modal-popup.hide {
  opacity: 0;
  visibility: hidden;
}
.modal-popup.hide .popup-inner {
  transform: translateY(-50px);
}
.modal-popup.show {
  opacity: 1;
  visibility: visible;
  transition-duration: 0.3s;
}
.modal-popup.show .popup-inner {
  transform: translateY(0);
}
.modal-popup.modal-alert .popup-inner {
  width: 400px;
  border-radius: 16px;
}
.modal-popup.modal-alert .popup-header {
  padding-top: 32px;
  padding-bottom: 4px;
  justify-content: center;
}
.modal-popup.modal-alert .popup-header .popup-title {
  font-weight: 700;
  text-align: center;
}
.modal-popup.modal-alert .popup-header i {
  display: block;
  margin: 0 auto 12px;
}
.modal-popup.modal-alert .popup-header ~ .popup-body {
  margin-top: 0;
  padding-top: 0;
  min-height: auto;
}
.modal-popup.modal-alert .popup-body {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 103px;
  padding: 32px 20px 20px;
  font-size: 16px;
  line-height: 21px;
  overflow: hidden;
}
.modal-popup.modal-alert .popup-body:first-child {
  margin-top: 0;
}
.modal-popup.modal-alert .popup-footer {
  padding-top: 0;
  padding-bottom: 24px;
  border-top: 0;
}
.modal-popup.modal-sm .popup-inner {
  width: 400px;
}
.modal-popup.modal-md .popup-inner {
  width: 600px;
}
.modal-popup.modal-lg .popup-inner {
  width: 1200px;
}
.modal-popup.modal-xl {
  padding: 20px;
}
.modal-popup.modal-xl .popup-inner {
  width: 1600px;
}
.modal-popup.modal-xl .popup-header {
  height: 100px;
  padding: 16px 40px;
  border-bottom: 1px solid #d1d5db;
}
.modal-popup.modal-xl .popup-title {
  font-size: 28px;
}
.modal-popup.modal-xl .popup-body {
  padding: 20px 40px 40px;
  overflow: auto;
  max-height: calc(100vh - 160px);
}
.modal-popup.modal-xl .popup-header ~ .btn-close-popup {
  top: 30px;
}
@media (min-width: 1025px) {
  .modal-popup .popup-inner {
    border: 1px solid #fff;
  }
  .modal-popup .popup-header {
    margin: -2px -2px 0;
  }
  .modal-popup .popup-body {
    max-height: calc(100vh - 200px);
  }
  .modal-popup.modal-lg {
    padding: 20px;
  }
}
@media (max-width: 1024.98px) {
  .modal-popup {
    padding: 20px;
  }
  .modal-popup .popup-inner.bg-gray {
    background: #fff !important;
  }
  .modal-popup .popup-inner .popup-body {
    padding: 24px;
  }
  .modal-popup .popup-inner .popup-body .card {
    padding: 0 0 24px;
  }
  .modal-popup .popup-inner .popup-body .card + .card {
    position: relative;
    padding-top: 20px;
  }
  .modal-popup .popup-inner .popup-body .card + .card::before {
    position: absolute;
    top: -12px;
    left: -20px;
    content: "";
    display: block;
    width: calc(100% + 40px);
    height: 12px;
    background: #f3f4f6;
  }
  .modal-popup.modal-sm .popup-inner {
    height: auto !important;
    max-height: 100%;
  }
  .modal-popup.modal-sm .popup-header {
    padding: 16px 60px 16px 19px;
    height: 64px;
  }
  .modal-popup.modal-sm .popup-header .popup-title {
    font-weight: 700;
    font-size: 18px;
    letter-spacing: -0.02em;
    max-width: 100%;
    white-space: nowrap;
    max-height: 1.4em;
    line-height: 1.4em;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .modal-popup.modal-sm .popup-header ~ .btn-close-popup {
    top: 20px;
  }
  .modal-popup.modal-alert .popup-inner {
    border: 1px solid #fff;
  }
  .modal-popup:not(.modal-alert) .popup-inner {
    width: 100%;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    height: 100%;
  }
  .modal-popup:not(.modal-alert) .popup-header,
  .modal-popup:not(.modal-alert) .popup-footer {
    flex: 0 0 auto;
  }
  .modal-popup:not(.modal-alert) .popup-body {
    flex: 1 1 auto;
    max-height: 100%;
  }
  .modal-popup.modal-md,
  .modal-popup.modal-lg,
  .modal-popup.modal-xl {
    padding: 0;
  }
  .modal-popup.modal-md .popup-inner,
  .modal-popup.modal-lg .popup-inner,
  .modal-popup.modal-xl .popup-inner {
    border-radius: 0;
  }
  .modal-popup.modal-md .popup-inner .popup-body,
  .modal-popup.modal-lg .popup-inner .popup-body,
  .modal-popup.modal-xl .popup-inner .popup-body {
    max-height: none;
  }
  .modal-popup.modal-md .popup-header,
  .modal-popup.modal-lg .popup-header,
  .modal-popup.modal-xl .popup-header {
    padding: 16px 60px 16px 19px;
    height: 64px;
    border-bottom: 1px solid #d1d5db;
  }
  .modal-popup.modal-md .popup-header .popup-title,
  .modal-popup.modal-lg .popup-header .popup-title,
  .modal-popup.modal-xl .popup-header .popup-title {
    font-weight: 700;
    font-size: 18px;
    letter-spacing: -0.02em;
    max-width: 100%;
    white-space: nowrap;
    max-height: 1.4em;
    line-height: 1.4em;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .modal-popup.modal-md .popup-header ~ .btn-close-popup,
  .modal-popup.modal-lg .popup-header ~ .btn-close-popup,
  .modal-popup.modal-xl .popup-header ~ .btn-close-popup {
    top: 20px;
  }
}

.tooltip {
  position: relative;
  display: inline-block;
  margin: -4px;
}
.table-form-basic th .tooltip,
.table-row th .tooltip {
  margin-right: 0;
}
.tooltip.active .btn-tooltip {
  z-index: 110;
}
.tooltip.active .tooltip-content {
  opacity: 1;
  visibility: visible;
}
.tooltip.active .tooltip-content:before {
  display: block;
}
.tooltip.active .tooltip-content .tooltip-inner {
  width: 252px;
  height: auto;
  padding: 12px;
  text-align: left;
  border-radius: 12px;
  background: #1f2937;
}

.btn-tooltip {
  position: relative;
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.tooltip-content {
  opacity: 0;
  visibility: hidden;
  transition-duration: 0.3s;
  transition-property: opacity;
  position: absolute;
  z-index: 100;
  color: #fff;
  font-weight: 400;
  font-size: 14px;
}
.tooltip-content:focus:not(:focus-visible) {
  outline: none;
}
.tooltip-content:before {
  display: none;
  content: "";
  position: absolute;
  left: calc(50% - 8px);
  top: 28px;
  width: 16px;
  height: 10px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='8' viewBox='0 0 16 8' fill='none'%3E%3Cpath d='M4.87652 1.90435C6.47782 -0.0972809 9.52217 -0.0972824 11.1235 1.90434L16 8H0L4.87652 1.90435Z' fill='%231f2937'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
}
.tooltip-content .tooltip-inner {
  width: 0;
  height: 0;
}
.tooltip-content .tooltip-inner[data-tooltip-data]::before {
  display: block;
  border-radius: 12px;
  padding: 12px;
  margin-bottom: 12px;
  content: "[마크업 오류] 이 툴팁은 마크업이 잘못 적용되었습니다. 가이드에 맞춰 개선해 주세요.";
  background: red;
}
.tooltip-content .btn-group {
  margin-top: 12px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
.tooltip-content .btn-group .btn {
  flex: 1 1 calc(50% - 4px);
}
.tooltip-content.bottom-center {
  top: 0px;
  left: 50%;
  padding-top: 36px;
  transform: translateX(-50%);
}
.tooltip-content.bottom-left {
  top: 0px;
  padding-top: 36px;
  margin-left: -12px;
}
.tooltip-content.bottom-left:before {
  left: 16px;
}
.tooltip-content.bottom-right {
  top: 0px;
  padding-top: 36px;
  transform: translateX(-83%);
}
.tooltip-content.bottom-right:before {
  right: 22px;
  left: auto;
}
.tooltip-content.right-top {
  top: 50%;
  padding-left: 36px;
  transform: translate3d(0, -25px, 0);
}
.tooltip-content.right-top:before {
  top: 20px;
  left: 25px;
  transform: rotate(-90deg);
}
.tooltip-content.right-center {
  top: 50%;
  padding-left: 36px;
  transform: translate3d(0, -50%, 0);
}
.tooltip-content.right-center:before {
  top: calc(50% - 8px);
  left: 25px;
  transform: rotate(-90deg);
}
.tooltip-content.right-bottom {
  top: 50%;
  padding-left: 36px;
  transform: translate3d(0, -75%, 0);
}
.tooltip-content.right-bottom:before {
  top: auto;
  bottom: 17px;
  left: 25px;
  transform: rotate(-90deg);
}
.tooltip-content.left-top {
  top: 50%;
  right: 0;
  padding-right: 36px;
  transform: translate3d(0, -25px, 0);
}
.tooltip-content.left-top:before {
  top: 20px;
  right: 25px;
  left: auto;
  transform: rotate(-270deg);
}
.tooltip-content.left-center {
  top: 50%;
  right: 0;
  padding-right: 36px;
  transform: translate3d(0, -50%, 0);
}
.tooltip-content.left-center:before {
  top: calc(50% - 8px);
  right: 25px;
  left: auto;
  transform: rotate(-270deg);
}
.tooltip-content.left-bottom {
  top: 50%;
  right: 0;
  padding-right: 36px;
  transform: translate3d(0, -75%, 0);
}
.tooltip-content.left-bottom:before {
  top: auto;
  right: 25px;
  bottom: 17px;
  left: auto;
  transform: rotate(-270deg);
}
.tooltip-content-primary {
  background: #1e48bc;
  width: 300px;
  padding: 20px;
}
.tooltip-content-primary:before {
  background-image: url("data:image/svg+xml,%3Csvg width='31' height='19' viewBox='0 0 31 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M29.6852 15.1737L16.8159 0.683686C16.0202 -0.212166 14.6208 -0.212168 13.8251 0.683684L0.955864 15.1737C-0.189836 16.4636 0.725927 18.5018 2.45123 18.5018H28.1898C29.9151 18.5018 30.8309 16.4636 29.6852 15.1737Z' fill='%231e48bc'/%3E%3C/svg%3E%0A");
}
.tooltip-content.top-center {
  padding-bottom: 36px;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
}
.tooltip-content.top-center:before {
  top: auto;
  bottom: 28px;
  transform: rotate(180deg);
}
.tooltip-content.top-left {
  bottom: 0px;
  padding-bottom: 36px;
  margin-left: -12px;
}
.tooltip-content.top-left:before {
  top: auto;
  bottom: 29px;
  left: 16px;
  transform: rotate(180deg);
}
.tooltip-content.top-right {
  bottom: 0px;
  padding-bottom: 36px;
  margin-left: -6px;
  transform: translateX(-83%);
}
.tooltip-content.top-right:before {
  top: auto;
  bottom: 29px;
  right: 16px;
  left: auto;
  transform: rotate(180deg);
}
@media (max-width: 767.98px) {
  .tooltip-content:focus {
    outline: none;
  }
}

.form-colorpicker {
  display: flex;
  align-items: center;
}
.form-colorpicker .hex-input {
  min-width: 200px;
}
.form-colorpicker .hex-color {
  position: relative;
  min-width: 36px;
  width: 36px;
  height: 40px;
  margin-left: 8px;
  padding: 4px;
  border: 0;
  outline: 0;
  appearance: none;
  -webkit-appearance: none;
  background-color: transparent;
  border-radius: 9px;
}
.form-colorpicker .hex-color::after {
  content: "";
  position: absolute;
  top: 1px;
  left: -1px;
  width: 36px;
  height: 36px;
  border: 1px solid #d1d5db;
  border-radius: 9px;
}
.form-colorpicker .hex-color::-webkit-color-swatch {
  border: none;
  border-radius: 6px;
}
@media (max-width: 767.98px) {
  .form-colorpicker {
    width: 100%;
  }
}

.color-preview {
  display: inline-block;
  width: auto;
  height: 24px;
  padding: 0 12px;
  background: #000;
  border-radius: 14px;
  line-height: 1.3rem;
}
.color-preview span {
  font-weight: 500;
  font-size: 12px;
  color: #fff;
}

._toolbar {
  position: fixed;
  z-index: 9999;
  width: 220px;
  right: 20px;
  bottom: 20px;
  padding: 12px 16px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.85);
  border: 2px solid rgba(53, 127, 237, 0.3);
  cursor: grab;
}
._toolbar .title {
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 4px;
}
._toolbar .btn-group {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
._toolbar .btn {
  flex: 1 1 auto;
  margin: 2px;
}
._toolbar .btn-close {
  position: absolute;
  right: 0;
  top: 0;
  padding: 4px;
}

.list-control {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}
.card .list-control:first-child {
  margin-top: -8px;
}
.list-control .left {
  display: flex;
  align-items: center;
  margin: 0 -2px;
}
.list-control .left > * {
  margin-right: 4px;
}
.list-control .left .total {
  margin-right: 8px;
}
.list-control .left .total strong {
  font-weight: 500;
}
.list-control .right {
  display: flex;
  align-items: center;
}
@media (max-width: 767.98px) {
  .list-control {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
  }
  .list-control .right {
    margin-top: 16px;
    width: 100%;
  }
}

.check-tabmenu {
  display: flex;
  text-align: center;
  color: #93c5fd;
}
.check-tabmenu .check-item + .check-item {
  margin-left: -1px;
}
.check-tabmenu .check-item:first-child .label {
  border-radius: 4px 0 0 4px;
}
.check-tabmenu .check-item:last-child .label {
  border-radius: 0 4px 4px 0;
}
.check-tabmenu .check-item .label {
  display: block;
  min-width: 44px;
  font-size: 12px;
  line-height: 18px;
  border: 1px solid #93c5fd;
}
.check-tabmenu .check-item .label:before {
  display: none;
}
.check-tabmenu .check-item :checked + .label {
  position: relative;
  z-index: 1;
  color: #1d4ed8;
  border-color: #357fed;
  background: #eff6ff;
}

@media (max-width: 1024.98px) {
  .search-form + .card {
    margin-top: 12px;
  }
}

/* 데이터 검색 전후 메시지 */
.nodata-area,
.chart-nodata-area,
.modal-chart-nodata-area,
.search-before,
.modal-search-before,
.modal-nodata-area {
  padding: 100px 0;
  border-top: 1px solid #e5e7eb;
  border-bottom: 1px solid #e5e7eb;
}
.nodata-area p,
.chart-nodata-area p,
.modal-chart-nodata-area p,
.search-before p,
.modal-search-before p,
.modal-nodata-area p {
  position: relative;
  padding-top: 170px;
  font-weight: 500;
  font-size: 16px;
  color: #7e8594;
  text-align: center;
}
.nodata-area p:after,
.chart-nodata-area p:after,
.modal-chart-nodata-area p:after,
.search-before p:after,
.modal-search-before p:after,
.modal-nodata-area p:after {
  position: absolute;
  top: 0;
  left: 50%;
  content: "";
  display: inline-block;
  width: 150px;
  height: 150px;
  background-size: 150px auto;
  transform: translateX(-50%);
}
@media (max-width: 767.98px) {
  .nodata-area,
  .chart-nodata-area,
  .modal-chart-nodata-area,
  .search-before,
  .modal-search-before,
  .modal-nodata-area {
    border: 0;
  }
}

.search-before p:after,
.modal-search-before p:after {
}

.chart-nodata-area,
.modal-chart-nodata-area {
  height: 304px;
  padding: 46px 0;
  border: 0;
}
.chart-nodata-area p:after,
.modal-chart-nodata-area p:after {
}

.modal-chart-nodata-area {
  height: auto;
  padding: 142.5px 0;
}

.card .search-wrap {
  padding: 24px;
  background: #f9fafb;
  border-radius: 16px;
}
.card .search-wrap .table-form-basic th {
  background: transparent;
}
.card .search-wrap + .bbs-wrap {
  margin-top: 20px;
}
.card .bbs-wrap {
  padding-top: 13.5px;
}
@media (max-width: 1024.98px) {
  .card .search-wrap {
    padding: 0;
    background: transparent;
  }
}
