.provisionsText {
  width: 80%;
  margin: 0 auto;
  box-sizing: border-box;
  padding-top: 5%;
  padding-bottom: 5%;
}

.Pr-Title {
  font-size: 30px;
  font-weight: 900;
}

.provisSubText {
  font-size: 18px;
  font-weight: 600;
}

table {
  border-collapse: collapse;
  width: 100%;
}

th,
td {
  border: 1px solid black;
  text-align: left;
  padding: 8px;
}

th {
  background-color: #f2f2f2;
  text-align: center;
}
