.headerback {
  background-color: #f5a207;
  min-height: 138px;
}

.groupLogo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.logoee {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.logoee2 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Kakabtn {
  border: none;
  background-color: yellow;
  border-radius: 8px;
  width: 8rem;
  height: 3.5rem;
  font-weight: 800;
}
.twoBox {
  padding-left: 10px;
}

.buttonTap {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  text-align: right;
}

.endBox {
  justify-content: flex-end;
}
