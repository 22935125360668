.IconContainer {
  height: 40px;
  border-radius: 100%;
  width: 40px;
  background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
}

.LoginText {
  font-size: 50px;
  margin-bottom: 54px;
  margin-top: 2rem;
}

.IdText {
  font-size: 10px;
}

.PassWordText {
  font-size: 10px;
}

.LoginButton {
  border-radius: 8px;
  border: none;
  margin-top: 20px;
  background-image: linear-gradient(to right, #ffcc33, #ff9900);
  color: white;
  width: 25vw;
  height: 30px;
  font-weight: 800;
  font-size: 20px;
}

.Image {
  margin-right: 10px;
}

.InputContainer {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  justify-items: center;
  margin-bottom: 10px;
}

.UserLoginBox {
  border-left: 5px solid #ffcc00;
  border-bottom: 5px solid rgba(255, 204, 51, 0.5); /* 투명도 50% */
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 30px;
  padding-right: 30px;
  background-color: white;
  border-radius: 8px;
  justify-content: center;
  position: relative;
}

.UserLoginBox_input {
  /* height: ; */
  width: 25vw;
  grid-column: 2;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom-width: 1px;
}

.LoginInput {
  font-size: 30px;
  margin-right: 20px;
}
/* .InputContainer::before,
.InputContainer::after {
  content: "";
  flex: 1;
} */

.Spacer {
  flex-grow: 1;
}

.logo {
  height: 8rem !important;
  width: 8rem !important;
  border-radius: 50%;
  margin-top: -0rem;
}

.logoBox {
  position: absolute;

  /* top: 50%; */
  top: -5%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.selectBox {
  border-radius: 8px;
  padding: 10px;
  font-size: 16px;
}

.loader {
  display: inline-block;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.footerbg {
  background-color: #292929;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}

/* @media (min-width: 992px) */
.col-lg-3 {
  flex: 0 0 auto;
  width: 25%;
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

.about-info {
  font-size: 14px;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.5);
}

.row-right-align {
  display: flex;
  justify-content: flex-end;
}

.text-center {
  font-size: 14px;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.5);
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  color: white;
}
