@font-face {
  font-family: "NotoSansKR";
  src: url("/public/font/NotoSansKR-VariableFont_wght.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: "NotoSansKR", sans-serif;
}

.App {
  text-align: start;
}

.LoginScreen {
  text-align: center;
  background-image: linear-gradient(
    to bottom left,
    rgba(255, 153, 51, 0.8) 50%,
    rgba(255, 204, 102, 0.5) 50%
  );
  display: flex;
  justify-content: center;
  align-items: center;
  height: 83vh;
  position: relative;
}

.radioPayment {
  display: none;
}
.payLogoDiv {
  height: 58px;
  display: flex;
  justify-items: center;
  align-items: center;
  justify-content: center;
}
.paymentBox {
  border-radius: 20px;
  border: 1px solid #7b7b7b;
  background: #fff;
  width: 120px;

  flex-shrink: 0;
  margin: 0 0.5rem 0 0;
}
.btn-pay {
  display: flex;
  width: 433px;
  height: 58px;
  padding: 6px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border: none;
  color: white;
  border-radius: 8px;
  background: rgba(0, 97, 243, 0.6);
  color: #fff;

  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Noto Sans;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 154%; /* 38.5px */
  letter-spacing: -0.2px;
}
.arrow {
  top: 25%;
  right: 5%;
}
.paymentBox.selected {
  background-color: rgba(0, 97, 243, 0.6); /* Change to your desired color */
}

.rowBox {
  justify-content: center;
  margin-bottom: 20px;
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  /* margin-top: calc(-1 * var(--bs-gutter-y)); */
  /* margin-right: calc(-0.5 * var(--bs-gutter-x)); */
  /* margin-left: calc(-0.5 * var(--bs-gutter-x)); */
  width: 80%;
  word-wrap: break-word;
  word-break: break-all;
  /* max-width: auto; */
}

.p-3 {
  padding: 1rem !important;
}

.userName {
  font-size: 45px;
  font-weight: 600;
}

.buttontext {
  color: black;
  font-size: 20px;
  font-weight: 800;
}

.SpButton {
  width: 70%;
  height: 3.5rem !important;
}

.leftPadding {
  margin-left: 20px;
}

.wantToSayInputBox::placeholder {
  color: #696976;
  font-size: 20px;
  font-weight: 600;
  line-height: 154%;
}
