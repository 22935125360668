@font-face {
  font-family: "NotoSansKR";
  src: url("/public/font/NotoSansKR-VariableFont_wght.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.profileImg {
  display: flex;
  justify-content: center;
  align-items: center;
}

.profileImg img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-top: 52px;
  object-fit: cover;
}

.profileName {
  margin-top: 10px;
  text-align: center;
  font-size: 35px;
  font-weight: 600;
}

.Devider {
  margin-left: auto;
  margin-right: auto;
  border-top: 1px solid #ced4da;
  /* width: 70%; */
  margin: 20px auto;
}

.showBox {
  word-break: break-all;
}

/* .buttonContainer {
  display: flex;
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.buttonContainer button {
  flex-grow: 1;
  margin: 0 10px;
}

@media screen and (max-width: 1200px) {
  .buttonContainer {
    flex-direction: column;
    align-items: stretch;
  }

  .buttonContainer button {
    width: 100%;
    margin: 5px 0;
  }

  .buttonContainer button:last-child {
    margin-bottom: 0;
  }
} */

.buttonContainer {
  display: flex;
  margin-top: 15px;
  margin-bottom: 15px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.buttonContainer button {
  flex-grow: 1;
  margin: 0 10px;
}

@media screen and (max-width: 1400px) {
  .buttonContainer {
    flex-direction: column;
    align-items: stretch;
  }

  .buttonContainer button {
    width: 100%;
    margin: 5px 0;
    flex-grow: 0; /* 버튼이 남은 공간을 채우려고 하는 것을 방지 */
  }

  .buttonContainer button:last-child {
    margin-bottom: 0;
  }
}

.my-custom-button {
  height: 2.5rem;
  background-color: #f5a207 !important;
  color: white !important;
}

.my-custom-button {
  background-color: #f5a207;
}

.my-custom-button {
  height: 2.5rem;
  background-color: #f5a207 !important;
  color: white !important;
}

.my-custom-button {
  background-color: #f5a207;
}

/* .inputContainer {
  display: flex;
  align-items: center;
} */

.inputContainer .userName {
  margin-right: 30px;
}

.donationContainer {
  display: flex;
  align-items: center;
  width: 100%;
}

@media screen and (max-width: 1200px) {
  .donationContainer {
    display: flex;
    flex-direction: column;
  }
}

.donationContainer.mobile {
  .totalMoney {
    width: 100%;
  }
}

.useBolder {
  margin-right: 10px;
}

/* .useBolder {
  font-weight: 900;
  font-size: 30px;
  margin-right: 10px;
} */

.totalMoney::placeholder {
  font-family: "NotoSansKR", sans-serif;
  text-align: center;
}

.totalMoney {
  flex-grow: 1;
  border-radius: 10px;
  border: 2px solid black;
  padding: 10px 10px;
}

@media screen and (max-width: 1200px) {
  .totalMoney {
    width: 100%;
  }
}

.totalMoney::placeholder {
  font-family: "NotoSansKR", sans-serif;
  color: #696976;
  font-size: 15px;
  font-weight: 600;
  line-height: 154%;
}

.whoSponsored {
  margin-top: 20px;
  flex-grow: 1;
  width: 100%;
}

.whoSponsoredInput {
  width: 100%;
  box-sizing: border-box;
  border-radius: 10px;
  border: 2px solid black;
  padding: 10px 10px;
}

.whoSponsoredInput::placeholder {
  font-family: "NotoSansKR", sans-serif;
  text-align: center;
}

.whoSponsoredInput::placeholder {
  font-family: "NotoSansKR", sans-serif;
  color: #696976;
  font-size: 20px;
  font-weight: 600;
  line-height: 154%;
}

.wantToSayBox {
  margin-top: 20px;
  flex-grow: 1;
  width: 100%;
  height: 20%;
  position: relative;
}

.wantToSayInputBox {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  border-radius: 10px;
  border: 2px solid black;
  padding: 10px 10px;
}

.wantToSayInputBox::placeholder {
  font-family: "NotoSansKR", sans-serif;
  text-align: center;
}

.textCounter {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: white;
  padding: 2px 5px;
}

.SponButton {
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttonText2 {
  line-height: 1.7;
}
