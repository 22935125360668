@charset "UTF-8";
.dashboard {
  display: grid;
  gap: 20px 24px;
  grid-template-areas: "guide myinfo" "report myinfo";
  /* grid-template-columns: calc(100% - 314px) 314px; */
  /* grid-template-rows: minmax(120px, auto) auto; */
}
@media (max-width: 1024.98px) {
  .dashboard {
    padding: 20px;
    grid-template-areas: "guide" "report" "myinfo";
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }
  .dashboard .card {
    border-radius: 16px;
  }
}
/* @media (max-width: 767.98px) {
  .dashboard {
    padding: 0;
    gap: 12px;
  }
} */

.section-guide {
  grid-area: guide;
}
.section-guide .quick-link {
  display: flex;
  gap: 20px;
}
.section-guide .quick-link li {
  flex: 1 1 20%;
}
.section-guide .quick-link a {
  display: flex;
  align-items: center;
  background: #fff;
  height: 100%;
  min-height: 120px;
  padding-right: 30px;
}
@media (min-width: 1025px) {
  .section-guide .quick-link a {
    padding: 24px;
    border-radius: 16px;
  }
}
@media (max-width: 1024.98px) {
  .section-guide .quick-link a {
    padding: 24px 20px;
  }
}
.section-guide .quick-link a:after {
  content: "";
  flex: 0 0 auto;
  width: 28px;
  height: 28px;
  margin-left: 8px;
  background-size: 28px auto;
}
.section-guide .quick-link .icon {
  flex: 0 0 auto;
  width: 60px;
  height: 60px;
  padding: 16px;
  border-radius: 50%;
  background: #f3f4f6;
  margin-right: 12px;
  overflow: hidden;
}
.section-guide .quick-link .icon img {
  width: 100%;
  height: 100%;
}
.section-guide .quick-link .cont {
  flex: 1 1 auto;
}
.section-guide .quick-link .title {
  display: block;
  font-weight: 700;
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: -0.02em;
  color: #1f2937;
  margin-bottom: 4px;
}
@media (max-width: 1699.98px) {
  .section-guide .quick-link a {
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    word-break: break-all;
  }
  .section-guide .quick-link a:after {
    display: none;
  }
  .section-guide .quick-link .title {
    margin-top: 8px;
    font-size: 16px;
  }
}
@media (max-width: 1439.98px) {
  .section-guide .quick-link {
    gap: 12px;
  }
}
@media (max-width: 1024.98px) {
  .section-guide .quick-link {
    display: flex;
  }
  .section-guide .quick-link a {
    border-radius: 16px;
  }
}
/* @media (max-width: 767.98px) {
  .section-guide .quick-link {
    display: block;
  }
  .section-guide .quick-link li:not(:first-child) {
    border-top: 1px solid #f3f4f6;
  }
  .section-guide .quick-link a {
    flex-direction: row;
    align-items: center;
    border-radius: 0;
  }
  .section-guide .quick-link a div {
    width: calc(100% - 36px);
  }
  .section-guide .quick-link a:after {
    display: block;
  }
  .section-guide .quick-link .title {
    margin-top: 0;
  }
  .section-guide .quick-link .title + p {
    font-size: 12px;
  }
} */

.section-report {
  grid-area: report;
  display: grid;
  gap: 20px;
}
.section-report .card {
  margin: 0;
}
.section-report .card .card-toolbar .btn-more {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  vertical-align: middle;
  overflow: hidden;
  text-indent: -9999px;
  overflow: hidden;
  text-align: left;
  width: 28px;
  height: 28px;
  margin-left: 8px;
  background-size: 28px auto;
}
.section-report .card .card-toolbar .btn-more:hover,
.section-report .card .card-toolbar .btn-more:focus {
  background-size: 28px auto;
  background-color: #f3f4f6;
  border-radius: 6px;
}
.section-report .card-header .tooltip {
  margin-left: 2px;
}
.section-report .card-title {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: -0.02em;
  color: #1f2937;
}
.section-report .card-title .ico {
  margin-right: 6px;
}
.section-report .shortcut-btn-area {
  text-align: left;
}
.section-report .shortcut-btn-area .btn-lg {
  padding: 0 20px;
}
.section-report .shortcut-btn-area .ico-chevron-right-white-md {
  margin-left: 4px;
  margin-right: 0;
}
.section-report .section-title {
  display: flex;
  gap: 8px;
  align-items: center;
}
.section-report .section-title .title {
  font-size: 24px;
  white-space: nowrap;
}
.section-report .section-title .tooltip .ico-question-md {
  width: 20px;
  height: 20px;
}
.section-report .section-title .dropdown-site .btn-select {
  display: inline-grid;
  align-items: center;
  min-width: 184px;
  min-height: 50px;
  padding: 0 36px 0 12px;
  font-weight: 700;
  font-size: 24px;
  color: #1f2937;
  background-color: transparent;
  background-size: 8px auto;
  background-position: right 12px center;
  border: 0px;
  border-bottom: 2px solid #357fed;
  border-radius: 0;
}
.section-report .section-title .dropdown-site .btn-select span {
  max-width: 100%;
  white-space: nowrap;
  max-height: 1.4em;
  line-height: 1.4em;
  text-overflow: ellipsis;
  overflow: hidden;
}
.section-report .section-title .dropdown-site .select > dd {
  top: 70px;
}
.section-report .section-title .dropdown-site .option-list {
  min-width: 200px;
  max-height: 336px;
  border-top-color: #bfdbfe !important;
  border-radius: 9px;
}
.section-report .today-report {
  display: flex;
  flex-direction: column;
}
.section-report .today-report .card-header {
  flex: 0 0 auto;
  margin-bottom: 16px;
}
.section-report .today-report .card-body {
  flex: 1 1 auto;
  display: grid;
  row-gap: 16px;
  column-gap: 20px;
  grid-template-areas: "hit chart" "view chart";
  grid-template-columns: 260px auto;
}
.section-report .today-report .today {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 20px 25px;
  border: 1px solid #f3f4f6;
  border-radius: 16px;
  background: #f9fafb;
}
.section-report .today-report .today-hit {
  grid-area: hit;
}
/* 
@media screen and (max-width: 1024px) {
  .today-hit {
    width: 300px;
  }
}

@media screen and (max-width: 1024px) {
  .today-view {
    width: 300px;
  }
} */

.section-report .today-report .today-view {
  grid-area: view;
}
.section-report .today-report .today dt {
  display: flex;
  align-items: center;
  font-weight: 500;
  color: #4b5563;
}
.section-report .today-report .today dt .ico {
  margin-right: 4px;
}
.section-report .today-report .today dd {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.section-report .today-report .today .point {
  font-size: 12px;
  font-weight: 500;
}
.section-report .today-report .today .point span {
  display: block;
  color: #7e8594;
}
.section-report .today-report .today .point span:first-child {
  position: relative;
  padding-left: 18px;
  margin-bottom: 2px;
}
.section-report .today-report .today .point.point-down span:first-child {
  color: #357fed;
}
.section-report
  .today-report
  .today
  .point.point-down
  span:first-child::before {
  position: absolute;
  left: 0;
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  background-size: 16px auto;
}
.section-report .today-report .today .point.point-up span:first-child {
  color: #ef4444;
}
.section-report .today-report .today .point.point-up span:first-child::before {
  position: absolute;
  left: 0;
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  background-size: 16px auto;
}
.section-report .today-report .today .point.point-stay span:first-child {
  color: #374151;
}
.section-report
  .today-report
  .today
  .point.point-stay
  span:first-child::before {
  position: absolute;
  left: 0;
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  background-size: 16px auto;
}
.section-report .today-report .today .count {
  font-weight: 700;
  font-size: 28px;
  color: #1f2937;
}
.section-report .today-report .chart-area {
  grid-area: chart;
  min-height: 290px;
}
.section-report .today-report .chart-area #chart-line {
  min-height: 290px;
}
@media (max-width: 1439.98px) {
  .section-report .today-report .card-body {
    gap: 16px;
    grid-template-areas: "hit" "view" "chart";
    grid-template-columns: 1fr;
    grid-template-rows: 118px 118px minmax(234px, auto);
  }
}
@media (max-width: 1024.98px) {
  .section-report .today-report .card-body {
    column-gap: 8px;
    row-gap: 16px;
    grid-template-areas: "hit view" "chart chart";
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 147px minmax(240px, auto);
  }
}
/* @media (max-width: 767.98px) {
  .section-report .today-report .today dd {
    display: block;
  }
} */
.section-report .visitor-report {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-size: cover;
}
.section-report .visitor-report:not(.visitor-nodata) .nodata {
  display: none;
}
.section-report .visitor-report.visitor-up {
  background-size: cover;
  background-position: center center;
  color: #fff;
}
.section-report .visitor-report.visitor-down {
  background-size: cover;
  background-position: center center;
  color: #fff;
}
.section-report .visitor-report.visitor-stay {
  background-size: cover;
  background-position: center center;
  color: #fff;
}
.section-report .visitor-report.visitor-nodata .card-title {
  color: #1f2937;
}
.section-report .visitor-report.visitor-nodata .nodata {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 300px;
  color: #7e8594;
}
.section-report .visitor-report.visitor-nodata .ico + p {
  margin-top: 12px;
}
.section-report .visitor-report.visitor-nodata .ico-visitor-nodata {
  width: 60px;
  height: 60px;
  background-size: 60px auto;
}
.section-report .visitor-report.visitor-nodata .info {
  display: none;
}
.section-report .visitor-report .card-title {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
}
.section-report .visitor-report .point {
  font-size: 56px;
  font-weight: 700;
  line-height: 1.5;
}
.section-report .visitor-report .point + .report {
  margin-top: 4px;
}
.section-report .visitor-report .report {
  font-size: 16px;
}
.section-report .visitor-report .suggestions {
  font-size: 12px;
  margin-top: 4px;
}
.section-report .monthly-device {
  position: relative;
}
.section-report .monthly-device .card-header {
  margin-bottom: 16px;
}
.section-report .monthly-device .tabmenu-icon {
  position: absolute;
  right: 24px;
  bottom: 24px;
  width: 52px;
  height: 28px;
  padding: 2px;
  margin-bottom: 0;
  /* background-color: #e3efff; */
  border-radius: 6px;
  z-index: 10;
}
.section-report .monthly-device .tabmenu-icon .tab-list {
  display: flex;
  align-items: center;
}
.section-report .monthly-device .tabmenu-icon li {
  width: 24px;
  height: 24px;
  border-radius: 6px;
}
.section-report .monthly-device .tabmenu-icon li a {
  padding: 0;
}
.section-report .monthly-device .tabmenu-icon li.on {
  background: #357fed;
}
.section-report
  .monthly-device
  .tabmenu-icon
  li.on
  .ico-donut-chart-primary-sm {
  background-size: 12px auto;
}
.section-report .monthly-device .tabmenu-icon li.on .ico-line-chart-primary-sm {
  background-size: 12px auto;
}
.section-report .monthly-device .device-body .chart-area {
  height: auto;
  min-height: 200px;
}
.section-report .monthly-device .device-body .chart-area.line {
  padding-bottom: 7px;
}
.section-report .monthly-device .device-body .chart-area.pie {
  padding-bottom: 2px;
}
.section-report .monthly-device .device-body #chart-bar,
.section-report .monthly-device .device-body #chart-line,
.section-report .monthly-device .device-body #chart-pie {
  width: 100%;
  height: 100%;
  min-height: 200px;
}
.section-report .menu-ranking {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.section-report .menu-ranking .card-body:not(.ranking-nodata) .nodata {
  display: none;
}
.section-report .menu-ranking .card-body.ranking-nodata .ranking-list {
  display: none;
}
.section-report .menu-ranking .nodata {
  padding: 42px 0;
  text-align: center;
}
.section-report .menu-ranking .nodata p {
  margin-top: 90px;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.3;
  color: #7e8594;
}
.section-report .menu-ranking .nodata .ico-ranking-nodata {
  width: 60px;
  height: 60px;
  background-size: 60px auto;
}
.section-report .menu-ranking .card-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.3;
  color: #7e8594;
}
.section-report .menu-ranking .ranking-list {
  margin-top: 10px;
}
.section-report .menu-ranking .ranking-list li {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  padding: 10px 0;
  border-bottom: 1px solid #f3f4f6;
}
.section-report .menu-ranking .ranking-list li p {
  flex: 1 1 auto;
  max-width: 100%;
  white-space: nowrap;
  max-height: 1.3em;
  line-height: 1.3em;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 500;
}
.section-report .menu-ranking .ranking-list li .count {
  flex: 0 0 auto;
  min-width: 40px;
  margin-left: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.3;
  text-align: right;
  color: #4b5563;
}
.section-report .menu-ranking .ranking-list li:first-child .num {
  background: #ffdc81;
  color: #79470d;
}
.section-report .menu-ranking .ranking-list li:nth-child(2) .num {
  background: #d4d9df;
  color: #5a6a7e;
}
.section-report .menu-ranking .ranking-list li:nth-child(3) .num {
  background: #f6cdaa;
  color: #896241;
}
.section-report .menu-ranking .ranking-list .title {
  width: calc(100% - 50px);
}
.section-report .menu-ranking .ranking-list .num {
  flex: 0 0 auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  margin-right: 10px;
  background: #f3f4f6;
  border-radius: 50%;
  font-weight: 500;
  font-size: 12px;
  color: #4b5563;
}
@media (min-width: 1700px) {
  .section-report {
    grid-template-columns: 832fr 406fr;
    grid-template-rows: 48px 386px 300px;
  }
}
@media (max-width: 1699.98px) {
  .section-report {
    grid-template-areas: "title shortcut" "today today" "visitor ranking" "device device";
    grid-template-columns: 1fr 1fr;
    /* grid-template-rows: auto minmax(300px, auto) minmax(300px, auto) minmax(
        300px,
        auto
      ); */
  }
  .section-report .section-title {
    grid-area: title;
  }
  .section-report .shortcut-btn-area {
    grid-area: shortcut;
  }
  .section-report .today-report {
    grid-area: today;
  }
  .section-report .visitor-report {
    grid-area: visitor;
  }
  .section-report .monthly-device {
    grid-area: device;
  }
  .section-report .menu-ranking {
    grid-area: ranking;
  }
}
@media (min-width: 1280px) {
  .section-report .visitor-report {
    min-width: 50%;
  }

  .section-report .menu-ranking {
    min-width: 50%;
  }
}
/* @media (max-width: 1279.98px) {
  .section-report {
    grid-template-areas: "title shortcut" "today today" "visitor visitor" "ranking ranking" "device device";
  }
  .section-report .visitor-report.visitor-up {
  }
  .section-report .visitor-report.visitor-down {
  }
  .section-report .visitor-report.visitor-stay {
  }
} */
@media (max-width: 1024.98px) {
  .section-report {
    grid-template-areas: "title title" "today today" "visitor visitor" "ranking ranking" "device device" "shortcut shortcut";
  }
  .section-report .shortcut-btn-area .btn-lg {
    width: 100%;
  }
}
/* @media (max-width: 767.98px) {
  .section-report {
    display: flex;
    flex-direction: column;
    background: #fff;
    gap: 0;
  }
  .section-report .card {
    padding: 20px;
    border-radius: 0;
  }
  .section-report .card-title {
    font-size: 16px;
  }
  .section-report .section-title {
    padding: 10px 20px;
  }
  .section-report .section-title .title {
    font-size: 18px;
  }
  .section-report .section-title .dropdown-list {
    width: auto !important;
  }
  .section-report .section-title .dropdown-list .btn-select {
    min-width: 142px;
    min-height: 40px;
    padding: 0 33px 0 8px;
    font-size: 18px;
  }
  .section-report .section-title .dropdown-list.dropdown-site .select > dd {
    top: 40px;
  }
  .section-report .section-title .dropdown-list.dropdown-site .option-list a {
    font-size: 14px;
  }
  .section-report .visitor-report {
    border-radius: 0;
    height: 300px;
  }
  .section-report .visitor-report.visitor-up {
  }
  .section-report .visitor-report.visitor-down {
  }
  .section-report .visitor-report.visitor-stay {
  }
  .section-report .visitor-report.visitor-nodata .nodata {
    height: 200px;
  }
  .section-report .monthly-device {
    order: 5;
  }
  .section-report .menu-ranking {
    width: 100vw;
  }
  .section-report .shortcut-btn-area {
    order: 10;
    padding: 20px;
    border-top: 1px solid #f3f4f6;
  }
  .section-report .shortcut-btn-area .btn {
    display: flex;
  }
} */

.section-myinfo {
  grid-area: myinfo;
  background: #fff;
}
@media (min-width: 1025px) {
  .section-myinfo {
    padding: 24px;
    border-radius: 16px;
  }
}
@media (max-width: 1024.98px) {
  .section-myinfo {
    padding: 24px 20px;
  }
}
.section-myinfo .item-title {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  font-weight: 700;
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: -0.02em;
  color: #1f2937;
}
.section-myinfo .item-title .ico {
  margin-right: 6px;
}
.section-myinfo .myinfo {
  text-align: center;
}
.section-myinfo .myinfo .profile {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  margin: 0 auto 12px;
  border-radius: 50%;
  background-color: #e3efff;
  background-size: 100px auto;
}
.section-myinfo .myinfo .profile img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.section-myinfo .myinfo .name {
  margin-bottom: 4px;
  font-weight: 700;
  font-size: 24px;
  line-height: 1.4;
  color: #1f2937;
}
.section-myinfo .myinfo .role .label {
  position: relative;
  display: inline-block;
  padding: 0 8px 0 26px;
  line-height: 28px;
  border-radius: 14px;
  background: #f3f4f6;
}
.section-myinfo .myinfo .role .label::before {
  position: absolute;
  top: 50%;
  left: 8px;
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  background-size: 16px auto;
  transform: translateY(-50%);
}
.section-myinfo .myinfo .role {
  margin-top: 4px;
}
.section-myinfo .myinfo .last-connect {
  margin-top: 4px;
}
.section-myinfo .myinfo .last-connect .label {
  position: relative;
  display: inline-block;
  margin-right: 3px;
  padding-right: 7px;
  font-size: 12px;
  line-height: 1.3;
  color: #7e8594;
}
.section-myinfo .myinfo .last-connect .label .ico {
  margin-right: 2px;
}
.section-myinfo .myinfo .last-connect .label::after {
  position: absolute;
  top: 50%;
  right: 0;
  content: "";
  display: inline-block;
  width: 1px;
  height: 10px;
  background: #d1d5db;
  transform: translateY(-50%);
}
.section-myinfo .myinfo .last-connect .date {
  font-size: 12px;
  line-height: 1.3;
  color: #4b5563;
}
.section-myinfo .myinfo .btn-group {
  gap: 6px;
  justify-content: center;
  margin-top: 12px;
}
.section-myinfo .myinfo .btn-group .btn-sm {
  height: 32px;
}
.section-myinfo .item-wrap {
  flex: 1 1 auto;
}
.section-myinfo .lastest-visit {
  position: relative;
}
.section-myinfo .lastest-visit .item-body {
  padding-bottom: 20px;
  max-height: 135px;
  min-height: calc(100% - 40px);
  overflow-y: auto;
}
.section-myinfo .lastest-visit .item-body:not(.visit-nodata) .nodata {
  display: none;
}
.section-myinfo .lastest-visit .item-body.visit-nodata {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 18px;
}
.section-myinfo .lastest-visit .item-body.visit-nodata .visit-list {
  display: none;
}
.section-myinfo .lastest-visit .nodata {
  text-align: center;
}
.section-myinfo .lastest-visit .nodata p {
  margin-top: 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.3;
  color: #7e8594;
}
.section-myinfo .lastest-visit .nodata .ico-visit-nodata {
  width: 60px;
  height: 60px;
  background-size: 60px auto;
}
.section-myinfo .lastest-visit .visit-list li {
  border-bottom: 1px solid #f3f4f6;
}
.section-myinfo .lastest-visit .visit-list li:last-child {
  border-bottom: 0px;
}
.section-myinfo .lastest-visit .visit-list a {
  display: inline-block;
  min-height: 50px;
  padding: 8px 0;
}
.section-myinfo .lastest-visit .visit-list .visit-menu {
  display: block;
  font-size: 12px;
  line-height: 1.3;
  color: #4b5563;
}
.section-myinfo .lastest-visit .visit-list p {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.3;
  color: #374151;
}
.section-myinfo .lastest-visit:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 16px;
  height: 24px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
}
.section-myinfo .privacy-history {
  position: relative;
}
.section-myinfo .privacy-history .item-body {
  padding-bottom: 20px;
  height: 110px;
  min-height: 110px;
  overflow-y: auto;
}
.section-myinfo .privacy-history .item-body:not(.history-nodata) .nodata {
  display: none;
}
.section-myinfo .privacy-history .item-body.history-nodata {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 18px;
}
.section-myinfo .privacy-history .item-body.history-nodata .history-list {
  display: none;
}
.section-myinfo .privacy-history .nodata {
  text-align: center;
}
.section-myinfo .privacy-history .nodata p {
  margin-top: 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.3;
  color: #7e8594;
}
.section-myinfo .privacy-history .nodata .ico-history-nodata {
  width: 60px;
  height: 60px;
  background-size: 60px auto;
}
.section-myinfo .privacy-history:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 16px;
  height: 24px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
}
.section-myinfo .privacy-history .history-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.section-myinfo .privacy-history .history-list a {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.section-myinfo .privacy-history .history-list a span {
  max-width: 100%;
  white-space: nowrap;
  max-height: 1.5rem;
  line-height: 1.5rem;
  text-overflow: ellipsis;
  overflow: hidden;
}
.section-myinfo .privacy-history .user-id {
  position: relative;
  flex: 0 0 auto;
  display: inline-block;
  width: 92px;
  max-height: 24px !important;
  height: 24px;
  margin-right: 6px;
  padding: 0 8px 0 24px;
  background: #f3f4f6;
  border-radius: 15px;
  font-size: 12px;
  color: #4b5563;
}
.section-myinfo .privacy-history .user-id::after {
  position: absolute;
  top: 50%;
  left: 8px;
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  background-size: 12px auto;
  transform: translateY(-50%);
}
.section-myinfo .privacy-history .btn-more {
  position: absolute;
  right: 2px;
  top: 19px;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  vertical-align: middle;
  overflow: hidden;
  text-indent: -9999px;
  overflow: hidden;
  text-align: left;
  width: 28px;
  height: 28px;
  margin-left: 8px;
  background-size: 28px auto;
}
.section-myinfo .connect-info dl {
  background: #f9fafb;
  border: 1px solid #e5e7eb;
  border-radius: 12px;
}
.section-myinfo .connect-info dt {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.section-myinfo .connect-info dt .icon {
  width: 40px;
  height: 40px;
}
.section-myinfo .connect-info dt .connect-label {
  font-weight: 500;
  font-size: 12px;
  line-height: 1.3;
  color: #7e8594;
}
.section-myinfo .connect-info .connect {
  font-weight: 700;
  font-size: 16px;
  line-height: 1.3;
}
@media (min-width: 1280px) {
  .section-myinfo .privacy-history .item-body {
    height: 135px;
    min-height: 135px;
  }
}
@media (min-width: 1025px) {
  .section-myinfo {
    padding-top: 32px;
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 0;
  }
  .section-myinfo .info-item + .info-item {
    padding-top: 20px;
    border-top: 1px solid #e5e7eb;
  }
  .section-myinfo .myinfo {
    flex: 0 0 276px;
  }
  .section-myinfo .lastest-visit {
    flex: 1 0 auto;
  }
  .section-myinfo .privacy-history {
    flex: 0 0 auto;
  }
  .section-myinfo .connect-info {
    flex: 0 0 auto;
  }
  .section-myinfo .connect-info .item-body {
    display: flex;
    gap: 12px;
  }
  .section-myinfo .connect-info .item-body dl {
    padding: 20px;
    flex: 1 1 50%;
    text-align: center;
  }
  .section-myinfo .connect-info .item-body dt .icon {
    margin-bottom: 10px;
  }
}
@media (min-width: 1025px) and (max-width: 1699.98px) {
  .section-myinfo {
    position: sticky;
    top: 32px;
    min-height: 876px;
    max-height: calc(100vh - 64px);
  }
  .section-myinfo .privacy-history .item-body {
    height: 110px;
    min-height: 110px;
  }
}
@media (max-width: 1024.98px) {
  .section-myinfo {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    padding: 24px 20px;
    border-radius: 16px;
  }
  .section-myinfo .info-item {
    min-width: 33.3%;
  }
  .section-myinfo .myinfo {
    display: none;
  }
  .section-myinfo .privacy-history .item-body {
    min-height: calc(100% - 40px);
  }
  .section-myinfo .privacy-history .btn-more {
    top: 0;
  }
  .section-myinfo .connect-info .item-body {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .section-myinfo .connect-info dl {
    position: relative;
    padding: 22px 20px;
  }
  .section-myinfo .connect-info dt {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .section-myinfo .connect-info .connect-label {
    padding: 0 0 21px 12px;
  }
  .section-myinfo .connect-info .connect {
    position: absolute;
    left: 72px;
    bottom: 25px;
  }
}
/* @media (max-width: 767.98px) {
  .section-myinfo {
    display: flex;
    flex-direction: column;
    gap: 0;
    padding: 0;
    border-radius: 0;
  }
  .section-myinfo .info-item {
    border-top: 1px solid #f3f4f6;
    margin-bottom: 25px;
    padding: 25px 20px 0;
  }
  .section-myinfo .info-item:last-child {
    padding-bottom: 0;
  }
  .section-myinfo .lastest-visit {
    border-top: 0;
  }
  .section-myinfo .privacy-history {
    width: 100vw;
  }
  .section-myinfo .privacy-history .btn-more {
    top: 18px;
    right: 20px;
  }
} */

.dashboard-old {
  height: 100vh;
  padding: 22px;
  display: grid;
  grid-template-areas: "today . . ." "visit   chart1 chart2 myinfo" "hits    chart1 chart2 myinfo" "favorit chart1 chart2 myinfo" "chart3  chart3 chart3 myinfo";
  grid-template-columns: 350fr 405fr 405fr 405fr;
  grid-template-rows: auto 132fr 132fr 238px 433fr;
}
.dashboard-old .card {
  padding: 30px 24px;
  margin: 10px;
}
.dashboard-old .card .title {
  display: inline-block;
  vertical-align: middle;
  color: #7e8594;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
}
@media (max-width: 1279.98px) {
  .dashboard-old {
    grid-template-rows: auto 140fr 140fr 238px 350fr;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
  }
  .dashboard-old .card {
    padding: 24px 20px;
  }
}
.dashboard-old-today {
  grid-area: today;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 10px 6px;
}
.dashboard-old-today .left {
  padding-left: 12px;
}
.dashboard-old-visit,
.dashboard-old-hits {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dashboard-old-visit .total,
.dashboard-old-hits .total {
  font-size: 28px;
}
.dashboard-old-visit .icon,
.dashboard-old-hits .icon {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  background: #ffffff;
  border: 1px solid #d1d5db;
  border-radius: 10px;
  box-shadow: 0px 5px 25px -13px #357fed;
}
.dashboard-old-visit .icon img,
.dashboard-old-hits .icon img {
  width: 32px;
  height: 32px;
  object-fit: cover;
}
.dashboard-old-visit {
  grid-area: visit;
}
.dashboard-old-hits {
  grid-area: hits;
}
.dashboard-old-favorit {
  grid-area: favorit;
}
.dashboard-old-favorit .ranking-list {
  margin-top: 30px;
}
.dashboard-old-favorit .ranking-list li {
  display: flex;
  margin-top: 18px;
  font-size: 15px;
  font-weight: 500;
}
.dashboard-old-favorit .ranking-list li .number {
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  height: 22px;
  width: 22px;
  margin-right: 4px;
  border-radius: 50%;
  font-size: 12px;
}
.dashboard-old-favorit .ranking-list li a {
  max-width: 100%;
  white-space: nowrap;
  max-height: 22px;
  line-height: 22px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.dashboard-old-favorit .ranking-list li:nth-child(1) .number {
  color: #925e21;
  background: linear-gradient(142.31deg, #ffe39a 7.52%, #ebbb5e 90.11%);
}
.dashboard-old-favorit .ranking-list li:nth-child(2) .number {
  color: #5a6a7e;
  background: linear-gradient(148.52deg, #d4d9df 6.52%, #b9c6d6 93.57%);
}
.dashboard-old-favorit .ranking-list li:nth-child(3) .number {
  color: #896241;
  background: linear-gradient(142.73deg, #ffddc0 6.32%, #e4af81 92.25%);
}
@media (max-width: 1279.98px) {
  .dashboard-old-chart1 select,
  .dashboard-old-chart2 select,
  .dashboard-old-chart3 select {
    min-width: auto;
    width: 64px;
    padding-right: 20px;
  }
}
.dashboard-old-chart1 {
  grid-area: chart1;
}
.dashboard-old-chart2 {
  grid-area: chart2;
}
.dashboard-old-chart3 {
  grid-area: chart3;
}
.dashboard-old-myinfo {
  display: flex;
  flex-direction: column;
  margin-right: 0 !important;
  grid-area: myinfo;
  border-radius: 12px 0px 0px 12px;
  box-shadow: 0px 20px 30px -24px rgba(30, 58, 138, 0.3);
}
.dashboard-old-myinfo .admin-info {
  flex: 1 1 55%;
  max-height: 489px;
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;
  color: #fff;
  background: #357fed;
  border-radius: 12px;
  padding: 20px 32px;
}
.dashboard-old-myinfo .admin-info-header {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  padding-bottom: 12px;
}
.dashboard-old-myinfo .profile-thumb {
  position: relative;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  border: 1px solid transparent;
  background-image: linear-gradient(#357fed, #357fed),
    linear-gradient(
      147.18deg,
      rgba(255, 255, 255, 0.8) 8.06%,
      rgba(255, 255, 255, 0) 94.14%
    );
  background-origin: border-box;
  background-clip: content-box, border-box;
}
.dashboard-old-myinfo .profile-thumb:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  object-fit: cover;
  background: linear-gradient(
    148.36deg,
    rgba(255, 255, 255, 0.3) 6.23%,
    rgba(255, 255, 255, 0) 95.59%
  );
}
.dashboard-old-myinfo .profile-thumb img {
  position: relative;
  width: 77px;
  height: 77px;
  border-radius: 50%;
  object-fit: cover;
  vertical-align: top;
}
.dashboard-old-myinfo .profile-name {
  margin-left: 24px;
}
.dashboard-old-myinfo .profile-name .name {
  display: block;
  font-size: 20px;
  line-height: 25px;
}
.dashboard-old-myinfo .profile-name .level {
  margin-top: 12px;
  display: inline-flex;
  align-items: center;
  padding: 0px 8px;
  height: 24px;
  background: #eff6ff;
  font-weight: 500;
  font-size: 12px;
  color: #1d4ed8;
  border-radius: 100px;
}
.dashboard-old-myinfo .admin-info-body {
  flex: 1 1 auto;
  margin: 36px 0 0;
}
.dashboard-old-myinfo .profile-info dt {
  color: #fff;
  opacity: 0.8;
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
}
.dashboard-old-myinfo .profile-info dd {
  margin-top: 4px;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 16px;
}
.dashboard-old-myinfo .admin-info-footer {
  flex: 0 0 auto;
}
.dashboard-old-myinfo .lastest-visit {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 12px;
  text-align: right;
}
.dashboard-old-myinfo .lastest-visit dd {
  margin-left: 8px;
}
.dashboard-old-myinfo .access-info {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}
.dashboard-old-myinfo .access-info .info-list {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  margin-top: 26px;
}
.dashboard-old-myinfo .access-info .info-list li {
  flex: 1 1 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 36px;
  background: #f9fafb;
  border: 1px solid #e5e7eb;
  border-radius: 12px;
  box-shadow: 0px 20px 30px -26px rgba(30, 58, 138, 0.3);
}
.dashboard-old-myinfo .access-info .info-list li + li {
  margin-top: 20px;
}
.dashboard-old-myinfo .access-info .info-list li .title {
  display: block;
  color: #1f2937;
}
.dashboard-old-myinfo .access-info .info-list li strong {
  display: block;
  margin-top: 8px;
  line-height: 24px;
  font-size: 20px;
  color: #1f2937;
}
.dashboard-old-myinfo .access-info .info-list .icon {
  width: 60px;
  height: 60px;
}
@media (max-width: 1279.98px) {
  .dashboard-old-myinfo .admin-info {
    margin-bottom: 2em;
    padding: 20px 24px 16px;
  }
  .dashboard-old-myinfo .admin-info-body {
    margin-top: 24px;
  }
  .dashboard-old-myinfo .admin-info-body .profile-info:after {
    display: block;
    clear: both;
    content: "";
  }
  .dashboard-old-myinfo .admin-info-body .profile-info dt,
  .dashboard-old-myinfo .admin-info-body .profile-info dd {
    margin: 12px 0 0;
  }
  .dashboard-old-myinfo .admin-info-body .profile-info dt {
    clear: both;
    float: left;
  }
  .dashboard-old-myinfo .admin-info-body .profile-info dd {
    float: right;
  }
  .dashboard-old-myinfo .access-info .info-list {
    margin-top: 16px;
  }
  .dashboard-old-myinfo .access-info .info-list li {
    padding: 12px 24px;
  }
  .dashboard-old-myinfo .access-info .info-list li + li {
    margin-top: 12px;
  }
}

.login-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* height: 100vh; */
  /* margin-left: -260px; */
  padding-top: 4px;
}
.login-area .cont-area {
  position: relative;
  width: 640px;
  height: auto;
  min-height: 0px;
  padding: 60px;
  border-radius: 20px;
  background-color: #fff;
}
.login-area .cont-area .logo-area {
  width: 100%;
  /* height: 28px; */
  text-align: left;
}
.login-area .cont-area .logo-area img {
  width: 110px;
}
.login-area .neibis-logo {
  position: absolute;
  bottom: -87px;
  left: calc(50% - 69px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.login-area .neibis-logo img {
  width: 138px;
  height: 32px;
}
.login-area .neibis-logo span {
  margin-top: 4px;
  font-weight: 400;
  font-size: 10px;
  line-height: 130%;
  letter-spacing: -0.01em;
  color: #a6adb9;
}
.login-area .title {
  margin-top: 12px;
  font-weight: 700;
  font-size: 40px;
  line-height: 150%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.02em;
  color: #0f172a;
}
.login-area .subject {
  margin-top: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.01em;
  color: #7e8594;
}
.login-area form {
  position: relative;
  height: auto;
  min-height: 320px;
  margin-top: 32px;
}
.login-area form .input-area .row {
  display: flex;
  flex-direction: column;
}
.login-area form .input-area .row + .row {
  margin-top: 20px;
}
.login-area form .input-area label {
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  letter-spacing: -0.01em;
  color: #374151;
}
.login-area form .input-area .form-control {
  margin-top: 8px;
  height: 48px;
}
.login-area form .btn-area {
  margin-top: 58px;
}
.login-area form .btn-area .btn {
  height: 56px;
  border-radius: 11px;
  font-size: 18px;
}
.login-area .text-guide {
  position: absolute;
  top: 208px;
  display: flex;
  align-items: flex-start;
  color: #dc2626;
}
.login-area .text-guide .ico {
  margin-right: 4px;
}
.login-area .call-guide-area {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 32px;
  height: 88px;
  padding: 16px 16px 16px 88px;
  background-color: #f3f4f6;
  border-radius: 11px;
}
.login-area .call-guide-area::before {
  content: "";
  position: absolute;
  left: 16px;
  top: 16px;
  width: 56px;
  height: 56px;
  background-size: cover;
}
.login-area .call-guide-area p {
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  letter-spacing: -0.01em;
  color: #7e8594;
}
.login-area .call-guide-area strong {
  display: block;
  margin-top: 2px;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  letter-spacing: -0.015em;
  color: #374151;
}
@media (max-width: 1279.98px) {
  .login-area {
    margin-left: 0;
  }
}
@media (max-width: 720px) {
  .login-area {
    justify-content: flex-start;
    padding: 20px;
  }
  .login-area .cont-area {
    width: 100%;
    height: auto;
    min-height: auto;
    padding: 32px 20px;
  }
  .login-area .cont-area .logo-area {
    width: 100%;
    height: 21px;
    text-align: left;
  }
  .login-area .cont-area .logo-area img {
    width: 82.5px;
  }
  .login-area .neibis-logo {
    bottom: -60px;
  }
  .login-area .neibis-logo img {
    width: 103.5px;
    height: 24px;
  }
  .login-area .neibis-logo span {
    margin-top: 4px;
    font-weight: 400;
    font-size: 10px;
    line-height: 130%;
    letter-spacing: -0.01em;
    color: #a6adb9;
  }
  .login-area .title {
    font-size: 28px;
    line-height: 140%;
  }
  .login-area form {
    position: relative;
    height: auto;
    min-height: 320px;
    margin-top: 32px;
  }
  .login-area form .input-area .form-control {
    height: 40px;
  }
  .login-area form .input-area .row {
    display: flex;
    flex-direction: column;
  }
  .login-area form .input-area .row + .row {
    margin-top: 12px;
  }
  .login-area form .btn-area {
    margin-top: 58px;
  }
  .login-area form .btn-area .btn {
    height: 56px;
    border-radius: 11px;
    font-size: 18px;
  }
  .login-area .text-guide {
    top: 166px;
  }
}
/* @media (max-width: 767.98px) {
  .login-area form {
    height: auto;
  }
  .login-area form .btn-area {
    margin-top: 60px;
  }
  .login-area .call-guide-area {
    height: 80px;
    padding: 14px 12px 14px 84px;
  }
  .login-area .call-guide-area::before {
    top: 12px;
    left: 12px;
  }
  .login-area .call-guide-area p {
    font-size: 12px;
    line-height: 130%;
  }
  .login-area .call-guide-area strong {
    font-size: 14px;
    line-height: 130%;
  }
} */

.login-area .cont-blocked {
  display: none;
}
.login-area.blocked .cont-area > .title,
.login-area.blocked .subject,
.login-area.blocked form,
.login-area.blocked .call-guide-area {
  display: none;
}
.login-area.blocked .cont-area {
  width: 740px;
  min-height: auto;
}
.login-area.blocked .logo-area {
  text-align: center;
}
.login-area.blocked .cont-blocked {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 24px 0 12px;
}
.login-area.blocked .cont-blocked .title {
  font-weight: 700;
  font-size: 28px;
  color: #1f2937;
}
.login-area.blocked .cont-blocked .message {
  margin-top: 8px;
  font-weight: 400;
  font-size: 16px;
  color: #4b5563;
  text-align: center;
}
@media (max-width: 1024.98px) {
  .login-area.blocked {
    padding: 0;
    border-radius: 0;
  }
  .login-area.blocked .cont-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 32px 20px;
    border-radius: 0;
  }
  .login-area.blocked .logo-area {
    height: 24px;
  }
  .login-area.blocked .logo-area img {
    width: 111.28px;
    height: 100%;
    object-fit: cover;
  }
  .login-area.blocked .cont-blocked {
    margin: 32px 0 15px;
  }
  .login-area.blocked .cont-blocked .title {
    margin-top: 20px;
    font-size: 24px;
  }
  .login-area.blocked .neibis-logo {
    bottom: 40px;
  }
  .login-area.blocked .neibis-logo img {
    width: 138px;
    height: 32px;
  }
}

.login-area .pw-group input[type="password"],
.login-area .pw-group input[type="text"] {
  padding-right: 48px;
}
.login-area .pw-group .btn-pw {
  top: auto;
  right: 10px;
  bottom: 0;
  height: 48px;
}
@media (max-width: 1024.98px) {
  .login-area .pw-group .btn-pw {
    height: 40px;
  }
}

.error-area {
  margin-left: -260px;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.error-area .cont-area {
  width: 740px;
  height: 473px;
  padding: 60px;
  border-radius: 20px;
  background-color: #fff;
}
.error-area .cont-area .logo-area {
  width: 100%;
  height: 40px;
  text-align: center;
}
.error-area .cont-area .logo-area img {
  width: 200px;
}
.error-area .cont-inner {
  text-align: center;
}
.error-area .cont-body {
  position: relative;
  margin-top: 24px;
  padding-top: 112px;
  text-align: center;
}
.error-area .cont-body::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 100px;
  background-size: cover;
}
.error-area .cont-body h1 {
  font-weight: 700;
  font-size: 28px;
  line-height: 140%;
  text-align: center;
  letter-spacing: -0.02em;
  color: #1f2937;
}
.error-area .cont-body p {
  margin-top: 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  text-align: center;
  letter-spacing: -0.015em;
  color: #4b5563;
}
.error-area .btn-group-center {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  gap: 8px;
}
.error-area .btn-group-center .btn {
  width: 120px;
}
@media (max-width: 1279.98px) {
  .error-area {
    margin-left: 0;
  }
  .error-area .cont-area {
    border-radius: 0;
  }
}
@media (max-width: 1024.98px) {
  .error-area .cont-area {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 20px;
  }
  .error-area .cont-body h1 {
    font-size: 24px;
    line-height: 140%;
  }
}

.info-revise-pop .table-form-basic tbody + tbody {
  position: relative;
  margin-top: 20px;
}
.info-revise-pop .table-form-basic tbody + tbody:before {
  content: "";
  position: absolute;
  height: 1px;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background: #d1d5db;
}
@media (min-width: 1025px) {
  .info-revise-pop .table-form-basic {
    margin-bottom: -10px;
  }
  .info-revise-pop .table-form-basic th,
  .info-revise-pop .table-form-basic td {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .info-revise-pop .table-form-basic th {
    width: 160px;
  }
  .info-revise-pop .table-form-basic td + th {
    padding-left: 30px;
  }
  .info-revise-pop .table-form-basic tbody + tbody {
    margin-top: 10px;
    padding-top: 11px;
  }
  .info-revise-pop .table-form-basic tbody + tbody:before {
    left: 10px;
    right: 10px;
  }
}

.modal-popup.login-extend .popup-body {
  padding-top: 14px;
  text-align: center;
}
.modal-popup.login-extend .popup-footer {
  padding-bottom: 24px;
}
.modal-popup.login-extend .popup-footer .btn-group-center .btn {
  width: 117px;
}
.modal-popup.login-extend .extend-message {
  font-size: 16px;
  text-align: center;
}
.modal-popup.login-extend .time-limit {
  display: inline-flex;
  align-items: center;
  margin-top: 12px;
  padding: 0px 12px;
  width: auto;
  height: 36px;
  background: #fef2f2;
  border-radius: 100px;
}
.modal-popup.login-extend .time-limit em {
  margin-left: 4px;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: -0.015em;
  color: #4b5563;
  font-style: normal;
}
.modal-popup.login-extend .time-limit strong {
  margin-left: 4px;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: -0.015em;
  color: #374151;
}
.modal-popup.login-extend .ico-time-red {
  width: 20px;
  height: 20px;
  background-size: 20px 20px;
}
@media (max-width: 1024.98px) {
  .modal-popup.login-extend .popup-inner {
    width: 400px !important;
  }
  .modal-popup.login-extend .popup-header .popup-title {
    font-size: 24px;
  }
  .modal-popup.login-extend .popup-body {
    padding-left: 10px;
    padding-right: 10px;
  }
}

/* 사용자메뉴 */
.meta-tag-area {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}
.meta-tag-area .meta-tag {
  display: inline-flex;
  align-items: center;
  padding: 9px 8px;
  background-color: #4b5563;
  border-radius: 8px;
}
.meta-tag-area .meta-tag .txt {
  font-weight: 400;
  font-size: 14px;
  color: #fff;
}
.meta-tag-area .btn-tag-close {
  min-width: 16px;
  height: 16px;
  margin-left: 4px;
  background-color: #1f2937;
  border: 0;
  border-radius: 50%;
}

/* 메인설정 */
.card-col .card-inner {
  display: flex;
  width: 100%;
  gap: 20px;
}
.card-col .card-inner .content-area {
  flex: 1 1 auto;
  min-width: calc(50% - 10px);
}
.card-col .card-inner .content-area .title1 {
  margin-bottom: 12px;
}
.card-col .card-inner .content-area .title1 .num {
  flex: 0 0 auto;
  display: inline-block;
  width: 22px;
  height: 22px;
  margin-right: 4px;
  border-radius: 50%;
  background-color: #1f2937;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #fff;
  text-align: center;
}
@media (min-width: 1025px) {
  .card-col .card-inner .table + .title1 {
    margin-top: 20px;
  }
  .card-col .card-inner .table-row th {
    min-width: 140px;
  }
  .card-col .card-inner .content-area .table-scroll-x {
    margin: 0;
  }
  .card-col .card-inner .content-area .table-scroll-x .table-inner {
    padding: 0;
  }
}
@media (max-width: 1439.98px) {
  .card-col .card-inner {
    flex-direction: column;
  }
  .card-col .card-inner .input-group {
    flex-wrap: nowrap;
  }
}
@media (max-width: 1024.98px) {
  .card-col .card-inner {
    gap: 12px;
  }
  .card-col .card-inner .content-area {
    padding: 24px 0;
  }
  .card-col .card-inner .content-area:first-child {
    padding-top: 0;
  }
  .card-col .card-inner .content-area + .content-area {
    position: relative;
  }
  .card-col .card-inner .content-area + .content-area::before {
    content: "";
    position: absolute;
    top: -12px;
    left: -20px;
    right: -20px;
    width: calc(100% + 40px);
    height: 12px;
    background-color: #f3f4f6;
  }
  .card-col .card-inner .content-area .table + .title1 {
    position: relative;
    margin-top: 36px;
    padding-top: 24px;
  }
  .card-col .card-inner .content-area .table + .title1::before {
    content: "";
    position: absolute;
    top: -12px;
    left: -20px;
    right: -20px;
    width: calc(100% + 40px);
    height: 12px;
    background-color: #f3f4f6;
  }
}

/* 게시물복원관리 */
@media (max-width: 1024.98px) {
  .tabmenu-in-wrap .tabmenu {
    margin: 20px;
  }
}

/* 관리자메뉴권한 */
.step-list li {
  display: flex;
  align-items: flex-start;
}
.step-list li .num {
  min-width: 18px;
  height: 18px;
  background-color: #1f2937;
  border-radius: 50%;
  font-weight: 500;
  font-size: 10px;
  text-align: center;
  line-height: 18px;
  color: #fff;
}
.step-list li + li {
  margin-top: 8px;
}
.step-list li .txt {
  margin-left: 6px;
}

.card .tree-in-wrap {
  gap: 20px;
  width: 100%;
}
.card .tree-in-wrap .table {
  width: 100%;
}
@media (max-width: 1439.98px) {
  .card .tree-in-wrap {
    flex-direction: column;
  }
  .card .tree-in-wrap .table {
    width: calc(100% + 48px);
  }
  .card .tree-in-wrap .tree-menu-area {
    margin-left: 0;
  }
}
@media (max-width: 1024.98px) {
  .card .tree-in-wrap .table {
    width: calc(100% + 40px);
  }
}

.btn-lock {
  width: 108px;
}

/* @media (max-width: 767.98px) {
  .list-control .dropdown.dropdown-sm {
    width: 100% !important;
  }
} */

/* 관리자 아이피관리 */
.ip-group .form-control {
  max-width: 100px;
}
@media (max-width: 1024.98px) {
  .ip-group {
    width: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .ip-group .form-control {
    flex: 1 1 auto;
    max-width: 100%;
    min-width: auto;
  }
  .ip-group .form-control:first-child {
    margin-left: 0;
  }
  .ip-group .form-control:last-child {
    margin-right: 0;
  }
}

/* 코드설정 */
.ico-file {
  width: 20px;
  height: 20px;
  background-size: 20px 20px;
}

.ico-folder {
  width: 20px;
  height: 20px;
  background-size: 20px 20px;
}

.font-size-xl {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  font-weight: 700;
  font-size: 18px;
  line-height: 140%;
  letter-spacing: -0.02em;
  vertical-align: middle;
}
@media (max-width: 1024.98px) {
  .font-size-xl {
    margin-bottom: 8px;
  }
}

.toggle-area .font-size-xl {
  position: relative;
  cursor: pointer;
}
.toggle-area .font-size-xl::before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 24px;
  height: 24px;
  background-size: 24px 24px;
  transform: rotate(180deg);
}
.toggle-area .font-size-xl.on::before {
  transform: rotate(0);
}

.codename-toggle-content .box {
  padding: 12px;
  margin-bottom: 0;
  border-radius: 12px;
}
.codename-toggle-content .codename-area2 {
  display: none;
}
.codename-toggle-content .codename-area2 dl {
  display: flex;
}
.codename-toggle-content .codename-area2 dl + dl {
  margin-top: 6px;
}
.codename-toggle-content .codename-area2 dt {
  position: relative;
  width: 68px;
  margin-right: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  letter-spacing: -0.01em;
  color: #4b5563;
}
.codename-toggle-content .codename-area2 dt::before {
  content: "";
  position: absolute;
  top: 3px;
  left: 76px;
  width: 1px;
  height: 12px;
  background: #a6adb9;
  border-radius: 100px;
}
.codename-toggle-content .codename-area2 dd {
  margin-left: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  letter-spacing: -0.01em;
  color: #374151;
}
@media (max-width: 1024.98px) {
  .codename-toggle-content .codename-area2 dl {
    display: flex;
    flex-direction: column;
  }
  .codename-toggle-content .codename-area2 dt {
    width: 100%;
    margin-right: 0;
    margin-bottom: 4px;
  }
  .codename-toggle-content .codename-area2 dt::before {
    display: none;
  }
  .codename-toggle-content .codename-area2 dd {
    margin-left: 0;
  }
}

.card table.disabled {
  position: relative;
}
.card table.disabled::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  background-color: #fff;
  opacity: 0.5;
}

/* 게시판설정 */
.board-settings .title {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  font-weight: 700;
  font-size: 18px;
  line-height: 140%;
  letter-spacing: -0.02em;
  color: #1f2937;
}
.board-settings .title .num {
  width: 22px;
  height: 22px;
  margin-right: 4px;
  border-radius: 50%;
  background-color: #1f2937;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.01em;
  text-align: center;
  color: #fff;
}

.settings-container {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}
.settings-container .settings-default {
  flex: 0 0 430px;
}
.settings-container .settings-default .form-control-sm {
  min-width: 100px;
  width: 100px;
}
.settings-container .settings-list {
  flex: 1 1 auto;
}
.settings-container .settings-write {
  flex: 1 1 430px;
}
.settings-container .settings-write .form-control-sm {
  min-width: auto;
  width: 100%;
}
@media (max-width: 1699.98px) {
  .settings-container {
    flex-wrap: wrap;
  }
  .settings-container .settings-default {
    flex: 1 1 calc(40% - 20px);
  }
  .settings-container .settings-list {
    flex: 1 1 calc(60% - 20px);
  }
}
@media (max-width: 1439.98px) {
  .settings-container {
    flex-direction: column;
  }
  .settings-container .settings-item {
    flex: 1 1 auto;
    width: 100%;
  }
}
/* @media (max-width: 767.98px) {
  .settings-container .settings-list .table-list table {
    min-width: 700px;
  }
} */

.settings-notice-guide {
  display: flex;
  gap: 12px;
}
.settings-notice-guide .guide-item {
  flex: 1 1 auto;
}
.settings-notice-guide .guide-title {
  display: block;
  height: 40px;
  border: 1px solid #e5e7eb;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background-color: #f9fafb;
  font-weight: 500;
  font-size: 14px;
  line-height: 38px;
  letter-spacing: -0.01em;
  text-align: center;
}
.settings-notice-guide .guide-code {
  min-height: 300px;
  padding: 12px;
  border: 1px solid #e5e7eb;
  border-top: 0;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  background-color: #fff;
}
/* @media (max-width: 767.98px) {
  .settings-notice-guide {
    flex-direction: column;
    width: 100%;
  }
} */

/* 운영 */
/* 조직관리-부서및직원업무설정 */
.table-header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 13px 0px 13px;
}
.table-header .left {
  display: flex;
  align-items: center;
  margin: 0 -2px;
  line-height: 1.6;
}
.table-header .right {
  display: flex;
  align-items: center;
}

.table-in-dropdown .table-inner {
  min-height: 280px;
}

/* 스케쥴-일정관리 */
.input-group.input-group-check .check-wrap {
  margin-right: 12px;
}
.input-group.input-group-check > .dropdown,
.input-group.input-group-check > .form-control {
  flex: 0 0 auto;
  width: 200px !important;
}
@media (max-width: 1279.98px) {
  .input-group.input-group-check {
    width: calc(100% + 4px);
  }
  .input-group.input-group-check > .dropdown,
  .input-group.input-group-check > .form-control {
    flex: 1 1 auto;
    width: 100% !important;
    margin-right: 0;
  }
}
/* @media (max-width: 767.98px) {
  .input-group.input-group-check > .dropdown,
  .input-group.input-group-check > .form-control {
    margin-top: 12px;
  }
} */

.promotion-img {
  width: 100%;
  text-align: center;
}
.promotion-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* 운영자공지사항-등록수정 */
.details-cell {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
}
.details-cell .row-item {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 24px;
  gap: 8px;
}
.details-cell .row-item .btn-group {
  flex-wrap: wrap;
}
.details-cell strong.label,
.details-cell label.label {
  min-width: 96px;
  font-weight: 500;
  font-size: 14px;
  color: #374151;
}
.details-cell .input-wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  gap: 8px;
}
@media (max-width: 1024.98px) {
  .details-cell.full .row-item {
    flex-direction: column;
    align-items: flex-start;
  }
  .details-cell.full .input-wrap {
    width: 100%;
  }
}

.article-nav {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
}
.article-nav .item {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background: #fff;
  border-radius: 16px;
}
.article-nav .item > span {
  display: inline-flex;
  align-items: center;
  font-size: 12px;
}
.article-nav .item:hover {
  background: #f9fafb;
}
.article-nav .link-info {
  display: block;
  margin-top: 8px;
  font-weight: 500;
  max-width: 100%;
  white-space: nowrap;
  max-height: 1.3em;
  line-height: 1.3em;
  text-overflow: ellipsis;
  overflow: hidden;
}
@media (min-width: 1025px) {
  .article-nav {
    min-height: 82px;
    margin-top: 20px;
    gap: 20px;
  }
  .article-nav > li {
    width: calc(50% - 10px);
  }
  .article-nav .item {
    min-height: 82px;
  }
  .article-nav .prev {
    position: absolute;
    left: 0;
  }
  .article-nav .next {
    position: absolute;
    right: 0;
  }
}
@media (max-width: 1024.98px) {
  .article-nav {
    flex-direction: column;
    padding-bottom: 0;
  }
  .article-nav > li {
    width: 100%;
    border-top: 1px solid #e5e7eb;
  }
  .article-nav > li + li {
    margin-left: 0;
    margin-left: 0;
  }
  .article-nav .item {
    min-height: 82px;
    border-radius: 0;
  }
  .article-nav .link-info {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    max-height: 2.6em;
    line-height: 1.3em;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.file-add-area {
  width: 100%;
}
.file-add-area .file-list {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.file-add-area .file-list .file-item {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 12px;
  background: #f3f4f6;
  border-radius: 12px;
}
.file-add-area .file-list .file-item .preview {
  display: inline-block;
  min-width: 40px;
  max-height: auto;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 40px auto;
}
.file-add-area .file-list .file-item .preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-fit: scale-down;
  border-radius: 8px;
}
.file-add-area .file-list .info {
  width: calc(100% - 92px);
  margin-left: 12px;
  margin-right: 12px;
}
.file-add-area .file-list .info .name {
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  letter-spacing: -0.01em;
  color: #374151;
}
.file-add-area .file-list .info .size {
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  letter-spacing: -0.01em;
  color: #7e8594;
}
.file-add-area .file-list .info .size strong {
  font-weight: 400;
}
.file-add-area .file-list .btn-area {
  display: flex;
  align-items: center;
}
.file-add-area .file-list .btn-area .btn {
  width: 32px;
  height: 32px;
  border-radius: 6px;
}
.file-add-area .file-list .btn-area .btn .ico {
  margin-right: 0;
}
.file-add-area .file-list .btn-area .btn + .btn {
  margin-left: 4px;
}
.file-add-area .file-list .btn-area .btn:hover {
  background-color: #e5e7eb;
}

.table + .info-editor-area {
  margin-top: 40px;
}

.info-editor-area .title1 + .editor-wrap {
  margin-top: 12px;
}
.info-editor-area .text-area {
  display: block;
  width: 100%;
  height: auto;
  padding: 24px 0;
}

.info-editor-area + .btn-group-between {
  margin-top: 16px;
}
/* @media (max-width: 767.98px) {
  .info-editor-area + .btn-group-between .btn-group-flex {
    display: flex;
    width: calc(100% + 8px);
    margin-left: -4px;
    margin-right: -4px;
  }
  .info-editor-area + .btn-group-between .btn-group-flex > * {
    flex: 1 1 auto;
    margin: 4px;
    min-width: calc(50% - 8px);
  }
} */

.reply-area {
  display: none;
  padding: 20px 24px 24px;
  background: #f3f4f6;
  border: 1px solid #e5e7eb;
  border-radius: 16px;
}
.reply-area .title1 {
  margin-bottom: 12px;
}
.reply-area .reply-wrap {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.reply-area .reply-wrap + .btn-group-center {
  margin-top: 12px;
}
.reply-area .reply-wrap .item {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.reply-area .reply-wrap .item .label .req {
  position: relative;
  width: 0.5em;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  vertical-align: middle;
  overflow: hidden;
  text-indent: -9999px;
  overflow: hidden;
  text-align: left;
}
.reply-area .reply-wrap .item .label .req:after {
  position: absolute;
  left: 2px;
  content: "";
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: #ef4444;
}

.card + .comment {
  display: none;
}

.comment-area {
  border-radius: 16px;
}
.comment-area .user-wrap {
  display: flex;
  gap: 8px;
}
.comment-area .user-img {
  position: relative;
  width: 36px;
  min-width: 36px;
  height: 36px;
  border: 1px solid #e5e7eb;
  border-radius: 50%;
  overflow: hidden;
  background-size: 16px auto;
}
.comment-area .user-img > img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.comment-area .user-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.comment-area .user-info .user > span {
  font-weight: 400;
}
.comment-area .user-info .date {
  font-weight: 400;
  font-size: 12px;
  color: #7e8594;
}
.comment-area .comment-write .write-wrap {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.comment-area .comment-cont {
  margin-top: 24px;
}
.comment-area .comment-cont .comment-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.comment-area .comment-cont .item {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 24px;
  border-radius: 16px;
  background: #f3f4f6;
}
.comment-area .comment-cont .item .user-img {
  border: 1px solid #e5e7eb;

  background-size: 16px auto;
}
.comment-area .comment-cont .item.delete .text-delete {
  color: #7e8594;
}
@media (max-width: 1024.98px) {
  .comment-area .btn-group-center button {
    flex: 1 1 auto;
  }
}

.btn-group-between + .comment-area {
  margin-top: 20px;
  padding: 20px 24px 24px;
  background: #f3f4f6;
}
.btn-group-between + .comment-area .comment-write .write-wrap {
  gap: 8px;
}
.btn-group-between + .comment-area .comment-cont .item {
  background: #e5e7eb;
}

.list-replay.depth1 .replay-title {
  padding-left: 40px;
}
.list-replay.depth1 .replay-title::before {
  left: 20px;
}

.list-replay.depth2 .replay-title {
  padding-left: 48px;
}
.list-replay.depth2 .replay-title::before {
  left: 28px;
}

.list-replay.depth3 .replay-title {
  padding-left: 56px;
}
.list-replay.depth3 .replay-title::before {
  left: 36px;
}

.list-replay.depth4 .replay-title {
  padding-left: 64px;
}
.list-replay.depth4 .replay-title::before {
  left: 44px;
}

.list-replay.depth5 .replay-title {
  padding-left: 72px;
}
.list-replay.depth5 .replay-title::before {
  left: 52px;
}

.list-replay.depth6 .replay-title {
  padding-left: 80px;
}
.list-replay.depth6 .replay-title::before {
  left: 60px;
}

.list-replay.depth7 .replay-title {
  padding-left: 88px;
}
.list-replay.depth7 .replay-title::before {
  left: 68px;
}

.list-replay.depth8 .replay-title {
  padding-left: 96px;
}
.list-replay.depth8 .replay-title::before {
  left: 76px;
}

.list-replay.depth9 .replay-title {
  padding-left: 104px;
}
.list-replay.depth9 .replay-title::before {
  left: 84px;
}

.list-replay.depth10 .replay-title {
  padding-left: 112px;
}
.list-replay.depth10 .replay-title::before {
  left: 92px;
}

.list-replay {
  background: #f9fafb;
}
.list-replay .replay-title {
  position: relative;
  padding-left: 40px;
}
.list-replay .replay-title::before {
  position: absolute;
  top: 50%;
  left: 20px;
  content: "";
  display: inline-block;
  width: 12px;
  height: 12px;
  background-position: center center;
  background-size: 12px auto;
  transform: translateY(-50%);
}

.comment-num {
  margin-left: 8px;
}
.comment-num .ico + span {
  margin-left: 4px;
  font-weight: 400;
  font-size: 14px;
  color: #374151;
}

.text-delete {
  color: #7e8594;
}

.form-control-sm.full {
  width: 100% !important;
}

/* 설문조사 관리 */
.question-area {
  margin-top: 20px;
}
.question-area .question-item {
  width: 100%;
  background: #f3f4f6;
  border-radius: 16px;
}
.question-area .question-item + .question-item {
  margin-top: 8px;
}
.question-area .question-wrap {
  padding: 20px;
}
.question-area .question-wrap .top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.question-area .question-wrap .top > * {
  margin-left: 8px;
}
.question-area .question-wrap .bot {
  margin-top: 4px;
}
.question-area .question-wrap .bot .form-control:is(textarea) {
  min-height: 60px;
}
.question-area .poll-wrap {
  position: relative;
  padding: 20px;
}
.question-area .poll-wrap::before {
  position: absolute;
  top: 0;
  left: 20px;
  display: block;
  content: "";
  width: calc(100% - 40px);
  height: 1px;
  background: #d1d5db;
}
.question-area .poll-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.question-area .poll-wrap .item {
  width: 100%;
}
.question-area .poll-wrap .item .btn {
  margin-left: 6px;
}
.question-area .poll-wrap .item + .item {
  margin-top: 8px;
}
.question-area .poll-wrap .btn-area {
  width: 100%;
  margin-top: 16px;
  text-align: center;
}
.question-area .poll-wrap .btn-area .btn-more {
  font-weight: 400;
  font-size: 14px;
  color: #2563eb;
}
.question-area .poll-wrap .btn-area .btn-more .ico {
  margin-top: 1px;
}
.question-area .question-wrap .num {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  margin-left: 0 !important;
  background: #1f2937;
  border-radius: 100px;
  font-weight: 500;
  font-size: 14px;
  color: #fff;
}
.question-area .question-wrap .form-control-sm {
  width: calc(100% - 381px);
}
.question-area .question-wrap .check-switch strong {
  font-weight: 500;
  font-size: 14px;
  margin-right: 4px;
}
.question-area .poll-wrap .num {
  display: inline-block;
  min-width: 24px;
}
.question-area .poll-wrap .form-control-sm {
  width: 100%;
  flex: 1 1 auto;
}
.question-area .poll-wrap .item {
  display: flex;
  align-items: center;
}
.question-area + .btn-group {
  margin-top: 20px;
}
@media (min-width: 1280px) {
  .question-area .poll-wrap .btn-delete {
    min-width: 67px;
    flex: 0 0 auto;
  }
}
@media (max-width: 1279.98px) {
  .question-area .poll-wrap .form-control-sm {
    width: calc(100% - 47px);
  }
  .question-area .poll-wrap .btn-delete {
    min-width: 50px;
    margin-left: 4px !important;
  }
  .question-area .poll-wrap .btn-delete .ico-trash-white-sm {
    width: 16px;
    height: 16px;
    margin-right: 0;
    background-size: 16px auto;
    background-position: center center;
  }
  .question-area .poll-wrap .btn-delete span {
    position: absolute;
    left: -9999px;
    width: 1px;
    height: 1px;
    margin: 0 !important;
    font-size: 0;
    line-height: 0;
    overflow: hidden;
  }
}
@media (max-width: 1024.98px) {
  .question-area .question-wrap .top {
    flex-wrap: wrap;
  }
  .question-area .question-wrap .top .num {
    margin-bottom: 8px;
  }
  .question-area .question-wrap .top .form-control-sm {
    display: inline-flex;
    width: calc(100% - 36px) !important;
    margin-bottom: 8px;
  }
  .question-area .question-wrap .top .form-control-sm:is(select) {
    width: calc(100% - 137px) !important;
  }
  .question-area .question-wrap .top .dropdown-sm {
    margin-left: 0;
    width: calc(100% - 137px) !important;
  }
  .question-area .poll-wrap .btn-area .btn-md {
    width: 100%;
  }
}

.poll-wrap.matrix {
  padding-top: 16px !important;
}
.poll-wrap.matrix .answer-num {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 12px;
  padding-bottom: 16px;
  border-bottom: 1px solid #d1d5db;
}
.poll-wrap.matrix .answer-num strong {
  min-width: 64px;
  margin-right: 6px;
  font-weight: 500;
  font-size: 14px;
}
@media (min-width: 1280px) {
  .poll-wrap.matrix .item-header {
    width: calc(100% - 397px);
    margin-left: 255px;
  }
  .poll-wrap.matrix .item-header .form-control-sm {
    width: calc(20% - 4px);
    min-width: auto;
  }
  .poll-wrap.matrix .item-header .form-control-sm + .form-control-sm {
    margin-left: 1px;
  }
  .poll-wrap.matrix .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
  }
  .poll-wrap.matrix .item .input-area {
    display: flex;
    align-items: center;
    width: calc(100% - 93px);
  }
  .poll-wrap.matrix .item .input-area + .btn {
    margin-left: 0;
  }
  .poll-wrap.matrix .item .check-warp {
    display: flex;
    width: calc(100% - 300px);
  }
  .poll-wrap.matrix .item .check-warp .check-item-lg {
    flex: 1 1 auto;
    display: block;
    min-width: 20%;
    text-align: center;
    margin: 0;
  }
  .poll-wrap.matrix .item .form-control:is(textarea) {
    width: 300px;
    height: 80px;
    resize: none;
  }
}
@media (max-width: 1279.98px) {
  .poll-wrap.matrix .answer-num strong {
    display: block;
  }
  .poll-wrap.matrix .item-header {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 0 16px 0;
  }
  .poll-wrap.matrix .item-header .form-control-sm {
    width: 100%;
  }
  .poll-wrap.matrix .item-header .form-control-sm + .form-control-sm {
    margin-top: 8px;
  }
  .poll-wrap.matrix .item {
    display: flex;
    justify-content: space-between;
  }
  .poll-wrap.matrix .item .input-area {
    width: calc(100% - 81px);
  }
  .poll-wrap.matrix .item .input-area .form-control-sm {
    width: 100%;
    margin-left: 0;
  }
  .poll-wrap.matrix .item .input-area .form-control:is(textarea) {
    min-height: 80px;
    width: 100%;
    resize: none;
  }
  .poll-wrap.matrix .item .check-warp {
    display: none;
  }
  .poll-wrap.matrix .btn-delete {
    min-width: 50px;
    height: 40px;
    margin-left: 4px !important;
  }
  .poll-wrap.matrix .btn-delete .ico {
    margin: 0;
  }
  .poll-wrap.matrix .btn-delete span {
    position: absolute;
    left: -9999px;
    width: 1px;
    height: 1px;
    margin: 0 !important;
    font-size: 0;
    line-height: 0;
    overflow: hidden;
  }
}

.form-control-icon:is(select) + .dropdown .btn-select .option1,
.form-control-icon:is(select) + .dropdown .btn-select .option2,
.form-control-icon:is(select) + .dropdown .btn-select .option3,
.form-control-icon:is(select) + .dropdown .btn-select .option4 {
  padding-left: 22px;
}
.form-control-icon:is(select) + .dropdown .option-list li {
  position: relative;
}
.form-control-icon:is(select) + .dropdown .option-list li button {
  padding-left: 34px;
}
.form-control-icon:is(select) + .dropdown .option1::before {
  position: absolute;
  top: 50%;
  left: 12px;
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-size: 16px auto;
  transform: translateY(-50%);
}
.form-control-icon:is(select) + .dropdown .option2::before {
  position: absolute;
  top: 50%;
  left: 12px;
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-size: 16px auto;
  transform: translateY(-50%);
}
.form-control-icon:is(select) + .dropdown .option3::before {
  position: absolute;
  top: 50%;
  left: 12px;
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-size: 16px auto;
  transform: translateY(-50%);
}
.form-control-icon:is(select) + .dropdown .option4::before {
  position: absolute;
  top: 50%;
  left: 12px;
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-size: 16px auto;
  transform: translateY(-50%);
}

.question-area.veiw .question-item {
  position: relative;
  width: 100%;
}
.question-area.veiw .question-wrap + .poll-wrap {
  margin-top: -4px;
}
.question-area.veiw .question-wrap + .poll-wrap .label,
.question-area.veiw .question-wrap + .poll-wrap label {
  font-weight: 400;
}
.question-area.veiw .question-wrap .num {
  position: absolute;
  top: 23px;
  left: 20px;
}
.question-area.veiw .question-wrap .question {
  width: 100%;
  min-height: 34px;
  margin: 0;
  font-size: 16px;
}
.question-area.veiw .question-wrap .question .req {
  position: relative;
  width: 0.5em;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  vertical-align: middle;
  overflow: hidden;
  text-indent: -9999px;
  overflow: hidden;
  text-align: left;
}
.question-area.veiw .question-wrap .question .req:after {
  content: "";
  position: absolute;
  top: 0;
  left: 3px;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: #ef4444;
}
.question-area.veiw .question-wrap .label {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 90px;
  height: 34px;
  background: #e5e7eb;
  border: 1px solid #d1d5db;
  border-radius: 17px;
  font-size: 14px;
  color: #374151;
}
.question-area.veiw .poll-wrap {
  align-items: flex-start;
  padding-top: 16px;
}
.question-area.veiw .poll-wrap::before {
  background: #e5e7eb;
}
.question-area.veiw .poll-wrap .item {
  font-size: 14px;
  font-weight: 500;
}
.question-area.veiw .poll-wrap .item + .item {
  margin-top: 8px;
}
.question-area.veiw .matrix .item-header {
  display: flex;
  width: calc(100% - 328px);
  margin-left: 328px;
  margin-bottom: 16px;
  font-size: 16px;
}
.question-area.veiw .matrix .item-header span {
  display: inline-block;
  flex: 1 1 100%;
  min-width: 20%;
  text-align: center;
  color: #4b5563;
}
.question-area.veiw .matrix .question {
  position: relative;
  width: 328px;
  padding-left: 28px;
  font-size: 14px;
}
.question-area.veiw .matrix .question strong {
  font-weight: 500;
}
.question-area.veiw .matrix .question .num {
  position: absolute;
  top: 0;
  left: 0;
}
.question-area.veiw .matrix .item + .item {
  margin-top: 18px;
}
.question-area.veiw .matrix .check-warp {
  width: calc(100% - 328px);
}
@media (min-width: 1280px) {
  .question-area.veiw
    .item-header
    .check-item-lg
    [type="radio"]
    + .label:before,
  .question-area.veiw
    .item-header
    .check-item-lg
    [type="radio"]
    + label:before {
    display: none;
  }
  .question-area.veiw .question-wrap .question {
    padding: 6.5px 98px 6.5px 36px;
  }
}
@media (max-width: 1279.98px) {
  .question-area.veiw .question-item {
    padding-bottom: 54px;
  }
  .question-area.veiw .question-wrap .question {
    padding: 6.5px 0 6.5px 36px;
  }
  .question-area.veiw .question-wrap .label {
    top: auto;
    bottom: 20px;
    right: 20px;
  }
  .question-area.veiw .matrix .item-header {
    align-items: flex-start;
    width: 100%;
    margin: 0 0 18px;
  }
  .question-area.veiw .matrix .item-header span {
    width: 100%;
    text-align: left;
  }
  .question-area.veiw .matrix .item-header span + span {
    margin-top: 18px;
  }
  .question-area.veiw .matrix .check-warp {
    display: none !important;
  }
  .question-area.veiw .matrix .question {
    width: 100%;
  }
  .question-area.veiw .check-item-lg + .check-item-lg {
    margin: 0;
  }
  .question-area.veiw .poll-wrap .item {
    justify-content: flex-start;
  }
  .question-area.veiw .poll-wrap .item .check-warp {
    display: flex;
  }
  .question-area.veiw .poll-wrap .item .check-item-lg {
    display: inline-block;
    flex: 1 1 auto;
    min-width: 20%;
    text-align: center;
    color: #4b5563;
  }
}
/* @media (max-width: 767.98px) {
  .question-area.veiw + .btn-group-between .btn-group-flex {
    width: 100%;
  }
  .question-area.veiw + .btn-group-between .btn-group-flex .btn {
    width: 100%;
  }
} */

@media (min-width: 1025px) {
  .details .poll-wrap.matrix .item-header {
    display: flex;
    flex-direction: row;
    width: calc(100% - 328px);
    margin-left: 328px;
    margin-bottom: 16px;
  }
  .details .poll-wrap.matrix .item-header span {
    text-align: center;
  }
  .details .poll-wrap.matrix .item-header span + span {
    margin-top: 0;
  }
  .details .poll-wrap.matrix .question {
    width: 328px;
  }
  .details .poll-wrap.matrix .item .check-warp {
    display: flex !important;
  }
  .details .poll-wrap.matrix .item + .item {
    margin-top: 10px;
  }
  .details .poll-wrap.matrix .label {
    top: 0;
    margin: 0 !important;
    font-size: 0;
    line-height: 0;
  }
  .details .poll-wrap.matrix .label::before {
    margin: 0;
  }
}
@media (max-width: 1279.98px) {
  .details .poll-wrap:not(.matrix) .item .check-item-lg {
    text-align: left !important;
  }
}
@media (max-width: 1024.98px) {
  .details .poll-wrap.matrix .item-header {
    display: none;
  }
  .details .poll-wrap.matrix .check-warp {
    display: flex !important;
  }
  .details .poll-wrap.matrix .item {
    flex-direction: column;
    width: 100%;
  }
  .details .poll-wrap.matrix .item + .item {
    margin-top: 24px;
  }
  .details .poll-wrap.matrix .question strong {
    font-weight: 500;
  }
  .details .poll-wrap.matrix .check-warp {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  .details .poll-wrap.matrix .check-warp .check-item-lg {
    text-align: left;
  }
  .details .poll-wrap.matrix .check-warp .check-item-lg:first-child {
    margin-top: 12px;
  }
  .details .poll-wrap.matrix .check-warp .check-item-lg + .check-item-lg {
    margin-top: 16px;
  }
}

.poll-wrap.result ul {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 8px);
  margin: 0 -4px;
}
.poll-wrap.result .item {
  display: flex;
  flex: 0 0 auto;
  width: calc(50% - 8px);
  min-height: 42px;
  margin: 4px;
  padding: 12px;
  background: #e5e7eb;
  border-radius: 12px;
}
.poll-wrap.result .item + .item {
  margin-top: 4px !important;
}
.poll-wrap.result .poll {
  position: relative;
}
.poll-wrap.result .poll .num {
  position: absolute;
}
.poll-wrap.result .poll p {
  padding-left: 28px;
}
.poll-wrap.result .essay-answer .item {
  width: calc(100% - 8px);
  font-weight: 400;
}
.poll-wrap.result .essay-answer .item:nth-child(6n) {
  display: none;
}
.poll-wrap.result.matrix > .item {
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin: 4px 0;
  padding-top: 16px;
}
.poll-wrap.result.matrix .question {
  margin-bottom: 16px;
}
.poll-wrap.result.matrix .question strong {
  font-weight: 500;
}
.poll-wrap.result.matrix .poll-area .item {
  display: flex;
  flex: 0 0 auto;
  margin: 4px;
  background: #d1d5db;
}
@media (min-width: 1025px) {
  .poll-wrap.result .item {
    justify-content: space-between;
  }
  .poll-wrap.result .poll {
    width: calc(100% - 230px);
  }
  .poll-wrap.result .progress {
    width: 220px;
    margin-left: 10px;
    text-align: right;
  }
  .poll-wrap.result.matrix .poll-area {
    flex-direction: row;
  }
  .poll-wrap.result.matrix .poll-area .item {
    width: calc(50% - 8px);
  }
}
@media (max-width: 1024.98px) {
  .poll-wrap.result ul {
    flex-direction: column;
  }
  .poll-wrap.result .item {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  .poll-wrap.result .progress {
    width: 100%;
    margin-top: 8px;
    padding-left: 28px;
  }
  .poll-wrap.result.matrix .poll-area {
    width: calc(100% - 5px);
  }
  .poll-wrap.result.matrix .poll-area .item {
    width: 100%;
  }
}

/* 프로그래스바 커스텀 */
.progressbar-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.progressbar-wrap progress {
  width: 140px;
  height: 12px;
  border-radius: 10px;
  background-color: #fff;
}
.progressbar-wrap progress::-webkit-progress-bar {
  background-color: #fff;
  border-radius: 6px;
}
.progressbar-wrap progress::-webkit-progress-value {
  background: #7e8594;
  border-radius: 6px;
}
.progressbar-wrap progress.top::-webkit-progress-value {
  border-radius: 6px;
}
.progressbar-wrap .info {
  font-size: 12px;
  color: #4b5563;
  margin-right: 8px;
}
@media (max-width: 1024.98px) {
  .progressbar-wrap {
    justify-content: space-between;
  }
  .progressbar-wrap .info {
    order: 1;
  }
  .progressbar-wrap .progressbar {
    order: 0;
  }
}

@media (min-width: 1025px) {
  .webcontents-management {
    display: flex;
    align-items: flex-start;
    gap: 24px;
  }
  .webcontents-management .navigator {
    flex: 0 0 283px;
    position: sticky;
    top: 112px;
    width: 283px;
    padding: 16px;
  }
  .webcontents-management .webcontents-input-area {
    margin-top: 0;
    flex: 1 1 auto;
  }
}

.navigator .title {
  font-weight: 700;
  font-size: 16px;
}
.navigator .site-select .title {
  margin-bottom: 12px;
}
.navigator .site-select .dropdown {
  z-index: 20;
}
.navigator .page-tree {
  margin-top: 20px;
}
.navigator .page-tree .title {
  margin-bottom: 12px;
}
.navigator .page-info {
  margin-top: 12px;
  padding: 12px;
  background: #f3f4f6;
  border-radius: 12px;
}
.navigator .page-info.opened .page-info-body {
  height: auto;
}
.navigator .page-info .page-info-header {
  display: flex;
  justify-content: space-between;
}
.navigator .page-info .page-info-body {
  margin-top: 12px;
  display: grid;
  column-gap: 10px;
  row-gap: 12px;
  grid-template-columns: 74px 1fr;
  font-size: 12px;
  height: 16px;
  overflow: hidden;
}
.navigator .page-info .page-info-body dt {
  color: #7e8594;
}
.navigator .page-info .page-info-body dd {
  font-weight: 500;
  word-break: break-all;
}

.editor-area {
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  margin-bottom: 12px;
}
@media (min-width: 1025px) {
  .editor-area.layout-type-default .codes-area {
    display: flex;
    border-radius: 8px 8px 0 0;
  }
  .editor-area.layout-type-default .code-item {
    height: auto !important;
  }
  .editor-area.layout-type-default .code-item-css {
    width: 200px;
  }
  .editor-area.layout-type-default .code-item-js {
    width: 200px;
  }
  .editor-area.layout-type-default .seperator {
    width: 8px;
    cursor: col-resize;
  }
  .editor-area.layout-type-default .form-code {
    height: 240px !important;
  }
  .editor-area.layout-type-default .CodeMirror {
    height: 164px !important;
  }
  .full-screen .editor-area.layout-type-default {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
  }
  .full-screen .editor-area.layout-type-default .webeditor-area {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
  }
  .full-screen
    .editor-area.layout-type-default
    .webeditor-area
    .webeditor-toolbar {
    flex: 0 0 auto;
  }
  .full-screen
    .editor-area.layout-type-default
    .webeditor-area
    .webeditor-viewport {
    flex: 1 1 auto;
  }
  .full-screen
    .editor-area.layout-type-default
    .webeditor-area
    .viewport-frame {
    height: 100%;
  }
  .editor-area.layout-type-left,
  .editor-area.layout-type-right {
    display: flex;
    min-height: calc(100vh - 281px);
  }
  .full-screen .editor-area.layout-type-left,
  .full-screen .editor-area.layout-type-right {
    min-height: calc(100vh - 140px);
  }
  .editor-area.layout-type-left .codes-area,
  .editor-area.layout-type-right .codes-area {
    flex: 0 0 500px;
    display: flex;
    flex-direction: column;
  }
  .editor-area.layout-type-left .seperator,
  .editor-area.layout-type-right .seperator {
    flex: 0 0 auto;
    height: 8px;
    cursor: row-resize;
  }
  .editor-area.layout-type-left .code-item,
  .editor-area.layout-type-right .code-item {
    width: auto !important;
    display: flex;
    flex-direction: column;
  }
  .editor-area.layout-type-left .code-item-header,
  .editor-area.layout-type-right .code-item-header {
    flex: 0 0 auto;
  }
  .editor-area.layout-type-left .code-item-body,
  .editor-area.layout-type-right .code-item-body {
    flex: 1 1 auto;
  }
  .editor-area.layout-type-left .form-code,
  .editor-area.layout-type-right .form-code {
    height: 100%;
  }
  .editor-area.layout-type-left .webeditor-area,
  .editor-area.layout-type-right .webeditor-area {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
  }
  .editor-area.layout-type-left .webeditor-area .webeditor-toolbar,
  .editor-area.layout-type-right .webeditor-area .webeditor-toolbar {
    flex: 0 0 auto;
  }
  .editor-area.layout-type-left .webeditor-area .webeditor-viewport,
  .editor-area.layout-type-right .webeditor-area .webeditor-viewport {
    flex: 1 1 auto;
  }
  .editor-area.layout-type-left .webeditor-area .viewport-frame,
  .editor-area.layout-type-right .webeditor-area .viewport-frame {
    height: 100%;
  }
  .editor-area.layout-type-left .codes-area {
    border-right: 1px solid #e5e7eb;
  }
  .editor-area.layout-type-right .codes-area {
    order: 2;
    border-left: 1px solid #e5e7eb;
  }
}

.codes-area {
  overflow: hidden;
}
.codes-area .code-item {
  flex: 0 0 auto;
}
.codes-area .code-item-html {
  flex: 1 1 auto;
}
.codes-area .seperator {
  flex: 0 0 auto;
  background: #e5e7eb;
}
.codes-area .code-item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 8px;
  height: 36px;
  gap: 4px;
  background: #f3f4f6;
}
.codes-area .form-code {
  width: 100%;
  border: 0;
  vertical-align: top;
  font-family: "IBM Plex Mono", -apple-system, BlinkMacSystemFont, "굴림",
    "Spoqa Han Sans Neo", "malgun gothic", Arial, sans-serif;
  padding: 12px;
  font-weight: 500;
  font-size: 13px;
  line-height: 1.5;
}
/* @media (max-width: 767.98px) {
  .codes-area .seperator {
    display: none;
  }
} */

.webcontents-input-area {
  padding: 12px;
  overflow-y: auto;
}
.webcontents-input-area .webcontents-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e5e7eb;
  margin-bottom: 12px;
}
.webcontents-input-area .webcontents-header .tabmenu {
  margin-left: -12px;
  margin-bottom: 0;
  padding-left: 12px;
}
.webcontents-input-area .webcontents-header .mode-control {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 4px;
}
.webcontents-input-area .webcontents-header .mode-control .btn {
  margin: 0;
}
/* @media (max-width: 767.98px) {
  .webcontents-input-area .webcontents-header {
    display: block;
  }
  .webcontents-input-area .webcontents-header .mode-control {
    display: none;
  }
} */

.webeditor-area .webeditor-toolbar {
  padding: 6px 12px;
  background: #f3f4f6;
}
.webeditor-area .webeditor-toolbar:after {
  display: block;
  clear: both;
  content: "";
}
.webeditor-area .page-control {
  float: left;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 4px;
}
.webeditor-area .page-control .btn {
  height: 28px;
  padding: 0 6px;
}
.webeditor-area .page-control .btn.on {
  background: #e5e7eb;
}
.webeditor-area .page-control .btn-icon {
  width: 28px;
  border-radius: 5px;
}
.webeditor-area .screen-control {
  float: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
}
.webeditor-area .screen-control .btn {
  margin: 0;
}
.webeditor-area .webeditor-viewport {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f9fafb;
  overflow: auto;
}
.webeditor-area .webeditor-viewport .viewport-frame {
  will-change: width height;
  transform-origin: center center;
  transition: 0.6s;
  transition-property: width height;
  width: 100%;
  height: 492px;
  min-height: 492px;
  display: inline-block;
  border: 4px solid #374151;
  border-radius: 24px;
}
/* @media (max-width: 767.98px) {
  .webeditor-area .webeditor-toolbar {
    display: block;
  }
  .webeditor-area .page-control .dropdown {
    width: auto !important;
  }
  .webeditor-area .webeditor-viewport {
    padding: 0;
    border-radius: 0;
  }
  .webeditor-area .webeditor-viewport .viewport-frame {
    border: 0;
    border-radius: 0;
  }
} */

@media (min-width: 1025px) {
  .full-screen {
    overflow: hidden;
  }
  .full-screen .webcontents-input-area {
    position: fixed;
    left: 0 !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    width: 100% !important;
    height: 100% !important;
    z-index: 800;
    display: flex;
    flex-direction: column;
  }
  .full-screen .webcontents-input-area .webcontents-header {
    flex: 0 0 auto;
  }
  .full-screen .webcontents-input-area .webcontents-body {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
  }
  .full-screen .webcontents-input-area .webcontents-body .btn-group {
    flex: 0 0 auto;
  }
  .full-screen .webcontents-input-area .btn-group-fixed {
    display: none;
  }
  .modal-open .full-screen .webcontents-input-area {
    right: 16px;
  }
}

/* 통계 페이지 */
@media (max-width: 1024.98px) {
  .statistics-contnet-wrap .tabmenu {
    padding: 20px;
    margin: 0;
  }
}
.statistics-contnet-wrap .btn-group-between {
  width: 100%;
  margin-top: 0;
  padding: 20px;
  background-color: #fff;
}
@media (max-width: 1279.98px) {
  .statistics-contnet-wrap .btn-group-between .btn-lg {
    width: 100%;
  }
}
@media (min-width: 1025px) {
  .statistics-contnet-wrap .card + .content-row-area {
    margin-top: 20px;
  }
}
@media (max-width: 1024.98px) {
  .statistics-contnet-wrap .card + .content-row-area {
    margin-top: 12px;
  }
}
.statistics-contnet-wrap .content-row-area {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.statistics-contnet-wrap .content-row-area .card {
  width: 100%;
  min-width: calc(50% - 10px);
  flex: 1 1 auto;
}
.statistics-contnet-wrap .content-row-area .card + .card {
  margin-top: 0;
}
@media (min-width: 1025px) {
  .statistics-contnet-wrap .content-row-area {
    gap: 20px;
  }
  .statistics-contnet-wrap .content-row-area + .card {
    margin-top: 20px;
  }
}
@media (max-width: 1024.98px) {
  .statistics-contnet-wrap .content-row-area {
    flex-direction: column;
    gap: 2px;
  }
  .statistics-contnet-wrap .content-row-area + .card {
    margin-top: 12px;
  }
}
.statistics-contnet-wrap .satisfaction .satisfaction-area {
  min-width: 310px;
  width: auto;
}
.statistics-contnet-wrap .satisfaction td.very-good {
  position: relative;
  background-color: #eff6ff;
  padding-left: 33px;
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  color: #2563eb;
  letter-spacing: -0.01em;
}
.statistics-contnet-wrap .satisfaction td.very-good::before {
  content: "";
  position: absolute;
  top: calc(50% - 10px);
  left: 18px;
  width: 20px;
  height: 20px;
  background-size: 20px 20px;
}
.statistics-contnet-wrap .satisfaction td.good {
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  color: #059669;
  letter-spacing: -0.01em;
}
.statistics-contnet-wrap .satisfaction td.better {
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  color: #ca6c00;
  letter-spacing: -0.01em;
}
.statistics-contnet-wrap .satisfaction td.very-bad {
  position: relative;
  background-color: #fef2f2;
  padding-left: 33px;
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  color: #dc2626;
  letter-spacing: -0.01em;
}
.statistics-contnet-wrap .satisfaction td.very-bad::before {
  content: "";
  position: absolute;
  top: 10px;
  left: 18px;
  width: 20px;
  height: 20px;
  background-size: 20px 20px;
}
.statistics-contnet-wrap .satisfaction .box-point {
  padding: 30px 26px;
  text-align: center;
}
.statistics-contnet-wrap .satisfaction .box-point > strong {
  display: block;
  font-weight: 700;
  font-size: 18px;
  line-height: 140%;
  letter-spacing: -0.02em;
  color: #1f2937;
}
.statistics-contnet-wrap .satisfaction .box-point > span {
  display: block;
  margin-top: 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  letter-spacing: -0.01em;
  color: #374151;
}
.statistics-contnet-wrap .satisfaction .box-point dl {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 87.5px;
  margin: 0;
  border-radius: 16px;
  background-color: #f3f4f6;
}
.statistics-contnet-wrap .satisfaction .box-point dl + dl {
  margin-top: 12px;
}
.statistics-contnet-wrap .satisfaction .box-point dt,
.statistics-contnet-wrap .satisfaction .box-point dd {
  color: #1f2937;
}
.statistics-contnet-wrap .satisfaction .box-point dt {
  font-weight: 500;
  font-size: 12px;
  line-height: 130%;
  letter-spacing: -0.01em;
}
.statistics-contnet-wrap .satisfaction .box-point dd {
  margin-top: 4px;
  font-weight: 700;
  font-size: 18px;
  line-height: 140%;
  letter-spacing: -0.02em;
}
.statistics-contnet-wrap .satisfaction .very-good-area .count-area {
  position: relative;
  margin-top: 12px;
  padding-top: 84px;
}
.statistics-contnet-wrap .satisfaction .very-good-area .count-area::before {
  content: "";
  position: absolute;
  top: 0;
  left: calc(50% - 129px);
  width: 258px;
  height: 84px;
  background-size: cover;
}
.statistics-contnet-wrap .satisfaction .very-good-area dl:first-child {
  background-size: cover;
}
.statistics-contnet-wrap .satisfaction .very-good-area dl:first-child dt,
.statistics-contnet-wrap .satisfaction .very-good-area dl:first-child dd {
  color: #ffffff;
}
.statistics-contnet-wrap .satisfaction .very-good-area dl.point {
  background-color: #fef2f2;
}
.statistics-contnet-wrap .satisfaction .very-good-area dl.point dt {
  color: #374151;
}
.statistics-contnet-wrap .satisfaction .very-good-area dl.point dd {
  color: #1f2937;
}
.statistics-contnet-wrap .satisfaction .better-area .count-area {
  position: relative;
  margin-top: 12px;
  padding-top: 84px;
}
.statistics-contnet-wrap .satisfaction .better-area .count-area::before {
  content: "";
  position: absolute;
  top: 0;
  left: calc(50% - 129px);
  width: 258px;
  height: 84px;
  background-size: cover;
}
.statistics-contnet-wrap .satisfaction .better-area dl:first-child {
  background-size: cover;
}
.statistics-contnet-wrap .satisfaction .better-area dl:first-child dt,
.statistics-contnet-wrap .satisfaction .better-area dl:first-child dd {
  color: #ffffff;
}
.statistics-contnet-wrap .satisfaction .good-area .count-area {
  position: relative;
  margin-top: 12px;
  padding-top: 84px;
}
.statistics-contnet-wrap .satisfaction .good-area .count-area::before {
  content: "";
  position: absolute;
  top: 0;
  left: calc(50% - 129px);
  width: 258px;
  height: 84px;
  background-size: cover;
}
.statistics-contnet-wrap .satisfaction .good-area dl:first-child {
  background-size: cover;
}
.statistics-contnet-wrap .satisfaction .good-area dl:first-child dt,
.statistics-contnet-wrap .satisfaction .good-area dl:first-child dd {
  color: #ffffff;
}
.statistics-contnet-wrap .satisfaction .very-bad-area .count-area {
  position: relative;
  margin-top: 12px;
  padding-top: 84px;
}
.statistics-contnet-wrap .satisfaction .very-bad-area .count-area::before {
  content: "";
  position: absolute;
  top: 0;
  left: calc(50% - 129px);
  width: 258px;
  height: 84px;
  background-size: cover;
}
.statistics-contnet-wrap
  .satisfaction
  .very-bad-area
  .count-area
  dl:first-child {
  background-size: cover;
}
.statistics-contnet-wrap
  .satisfaction
  .very-bad-area
  .count-area
  dl:first-child
  dt,
.statistics-contnet-wrap
  .satisfaction
  .very-bad-area
  .count-area
  dl:first-child
  dd {
  color: #ffffff;
}
.statistics-contnet-wrap .satisfaction .very-bad-area .count-area dl.point {
  background-color: #eff6ff;
}
.statistics-contnet-wrap .satisfaction .very-bad-area .count-area dl.point dt {
  color: #374151;
}
.statistics-contnet-wrap .satisfaction .very-bad-area .count-area dl.point dd {
  color: #1f2937;
}
@media (min-width: 1025px) {
  .statistics-contnet-wrap .satisfaction .box-point {
    height: 100%;
  }
  .statistics-contnet-wrap .satisfaction .box-point .count-area {
    display: flex;
    flex-direction: column;
    height: calc(100% - 58px);
  }
  .statistics-contnet-wrap .satisfaction .box-point .count-area dl {
    flex: 1 1 auto;
  }
}
@media (max-width: 1024.98px) {
  .statistics-contnet-wrap .satisfaction .satisfaction-area {
    width: 100%;
    order: -1;
    margin-bottom: 12px;
  }
  .statistics-contnet-wrap .satisfaction .box-point {
    padding: 33px 20px 33px;
  }
}
@media (max-width: 1024.98px) {
  .statistics-contnet-wrap .satisfaction .box-point {
    background: none;
    border: 0;
    border-radius: 0;
  }
  .statistics-contnet-wrap .satisfaction .box-point::before {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 0;
  }
}
.statistics-contnet-wrap .tabmenu-custom {
  position: absolute;
  right: 0;
  top: 0px;
  width: 52px;
  height: 28px;
  padding: 2px;
  margin-bottom: 0;
  background-color: #e3efff;
  border-radius: 6px;
}
.statistics-contnet-wrap .tabmenu-custom .tab-list {
  display: flex;
  align-items: center;
}
.statistics-contnet-wrap .tabmenu-custom li {
  width: 24px;
  height: 24px;
  border-radius: 6px;
}
.statistics-contnet-wrap .tabmenu-custom li a {
  padding: 0;
}
.statistics-contnet-wrap .tabmenu-custom li .ico {
  position: absolute;
}
.statistics-contnet-wrap .tabmenu-custom li.on {
  background: #357fed;
}
.statistics-contnet-wrap
  .tabmenu-custom
  li:first-child.on
  .ico-line-chart-primary-sm,
.statistics-contnet-wrap
  .tabmenu-custom
  li:first-child.on
  .ico-donut-chart-white-sm,
.statistics-contnet-wrap
  .tabmenu-custom
  li:first-child.on
  .ico-bar-chart-primary-sm {
  display: none;
}
.statistics-contnet-wrap
  .tabmenu-custom
  li:first-child.on
  .ico-line-chart-white-sm,
.statistics-contnet-wrap
  .tabmenu-custom
  li:first-child.on
  .ico-donut-chart-primary-sm,
.statistics-contnet-wrap
  .tabmenu-custom
  li:first-child.on
  .ico-bar-chart-white-sm {
  display: block;
}
.statistics-contnet-wrap
  .tabmenu-custom
  li:last-child.on
  .ico-line-chart-white-sm,
.statistics-contnet-wrap
  .tabmenu-custom
  li:last-child.on
  .ico-donut-chart-primary-sm,
.statistics-contnet-wrap
  .tabmenu-custom
  li:last-child.on
  .ico-bar-chart-white-sm {
  display: none;
}
.statistics-contnet-wrap
  .tabmenu-custom
  li:last-child.on
  .ico-line-chart-white-sm,
.statistics-contnet-wrap
  .tabmenu-custom
  li:last-child.on
  .ico-donut-chart-white-sm,
.statistics-contnet-wrap
  .tabmenu-custom
  li:last-child.on
  .ico-bar-chart-primary-sm {
  display: block;
}
.statistics-contnet-wrap .table-list.satisfaction td.very-good::before {
  left: 38px;
}
.statistics-contnet-wrap .table-list.satisfaction td.very-bad::before {
  left: 38px;
}

@media (max-width: 1024.98px) {
  .modal-popup .popup-body .statistics-contnet-wrap .card + .content-row-area {
    position: relative;
    padding-top: 20px;
  }
  .modal-popup .popup-body .statistics-contnet-wrap .card + .card::before,
  .modal-popup
    .popup-body
    .statistics-contnet-wrap
    .card
    + .content-row-area::before {
    position: absolute;
    top: -12px;
    left: -24px;
    content: "";
    display: block;
    width: calc(100% + 48px);
    height: 12px;
    background: #f3f4f6;
  }
  .modal-popup .popup-body .statistics-contnet-wrap .card + .satisfaction-area {
    position: relative;
    margin-top: -20px;
    margin-bottom: 25px;
  }
  .modal-popup
    .popup-body
    .statistics-contnet-wrap
    .card
    + .satisfaction-area::before {
    position: absolute;
    bottom: -12px;
    left: -12px;
    content: "";
    display: block;
    width: calc(100% + 24px);
    height: 12px;
    background: #f3f4f6;
  }
  .modal-popup .popup-body .statistics-contnet-wrap .content-row-area + .card {
    position: relative;
    padding-top: 20px;
  }
  .modal-popup
    .popup-body
    .statistics-contnet-wrap
    .content-row-area
    + .card::before {
    position: absolute;
    top: -12px;
    left: -24px;
    content: "";
    display: block;
    width: calc(100% + 48px);
    height: 12px;
    background: #f3f4f6;
  }
  .modal-popup .popup-body .statistics-contnet-wrap .satisfaction-area {
    width: calc(100% + 24px);
    margin-left: -12px;
  }
}

.table-align-btn {
  display: inline-flex;
  align-items: baseline;
  height: 20px;
  font-weight: 500;
  color: #1f2937;
}
.table-align-btn .ico-wrap {
  margin-left: 4px;
}
.table-align-btn .ico-table-updown-darkgary-sm {
  margin-bottom: 2px;
}
.table-align-btn .ico-table-updown-darkgary-sm.up {
  background-size: 12px auto;
}
.table-align-btn .ico-table-updown-darkgary-sm.down {
  background-size: 12px auto;
}
.table-align-btn:hover .table-title {
  position: relative;
}
.table-align-btn:hover .table-title::after {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  display: inline-block;
  width: 100%;
  height: 0.75px;
  background-color: #1f2937;
}

.statistics-wrap .statistics-header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 28px;
  margin-bottom: 20px;
}
.statistics-wrap .statistics-header .title1 {
  display: block;
  margin-bottom: 0;
  font-weight: 700;
  font-size: 18px;
  line-height: 140%;
  color: #1f2937;
  letter-spacing: -0.02em;
  text-align: center;
}
.statistics-wrap .statistics-header .btn-tooltip {
  margin-top: 2px;
  margin-left: 4px;
}
.statistics-wrap .statistics-header .tooltip-content.bottom-right {
  left: 3px;
}
.statistics-wrap .statistics-body {
  height: auto;
  min-height: 304px;
}
.statistics-wrap .statistics-body #chart-bar,
.statistics-wrap .statistics-body #chart-line,
.statistics-wrap .statistics-body #chart-pie,
.statistics-wrap .statistics-body #chart-bar-col {
  width: 100%;
  height: 100%;
  min-height: 304px;
}
.statistics-wrap .statistics-body .inner {
  gap: 40px;
}
.statistics-wrap .statistics-body .col-item {
  flex: 1 1 auto;
  width: 100%;
  height: 100%;
}
.statistics-wrap .statistics-body .cont-inner {
  display: block;
  width: 100%;
  height: 100%;
}
.statistics-wrap .statistics-body .scroll-area {
  overflow-x: auto;
}
@media (min-width: 1025px) {
  .statistics-wrap .statistics-body .col-item {
    min-width: 360px;
  }
  .statistics-wrap .statistics-body .col-item.col-3 {
    max-width: calc(100% - 360px);
  }
  .statistics-wrap .statistics-body .col-item.col-3 + .col-item {
    max-width: 360px;
  }
}
@media (max-width: 1024.98px) {
  .statistics-wrap .statistics-body .inner {
    flex-direction: column;
  }
  .statistics-wrap .statistics-body .col-item.col-3 {
    max-width: 100%;
  }
  .statistics-wrap .statistics-body .col-item.col-3 + .col-item {
    max-width: 100%;
  }
}

@media (max-width: 1024.98px) {
  .modal-popup .statistics-body {
    width: 100%;
  }
}

.card.statistics,
.card.modal-statistics {
  display: none;
}

.satisfaction-area.statistics,
.satisfaction-area.modal-statistics {
  display: none;
}

.content-row-area.modal-statistics.statistics,
.content-row-area.modal-statistics.modal-statistics {
  display: none;
}

.card.site-name {
  display: none;
  padding: 12px 0 0;
  background: none;
  font-size: 24px;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: -0.48px;
  color: #1f2937;
}
@media (max-width: 1024.98px) {
  .card.site-name {
    margin: 24px;
    font-size: 18px;
    letter-spacing: -0.36px;
  }
}

.site-name-area {
  display: flex;
  align-items: center;
  padding: 20px 0;
  margin-top: -20px;
}
.site-name-area .site-name {
  display: flex;
  align-items: center;
  height: 32px;
  margin: 0 8px 0 0;
  padding: 0 8px;
  border-radius: 100px;
  background-color: #e5e7eb;
  font-size: 14px;
  font-weight: 500;
  line-height: 130%;
  letter-spacing: -0.14px;
  color: #374151;
}
.site-name-area .site-name .ico {
  margin-bottom: 2px;
  margin-right: 4px;
}
.site-name-area .breadcrumb-list {
  display: flex;
}
.site-name-area .breadcrumb-list li {
  font-size: 14px;
  color: #7e8594;
}
.site-name-area .breadcrumb-list li + li {
  position: relative;
  margin-left: 16px;
}
.site-name-area .breadcrumb-list li + li::before {
  content: ">";
  position: absolute;
  top: 0;
  left: -11px;
  color: #d1d5db;
}
.site-name-area .breadcrumb-list li:last-child {
  color: #374151;
}
@media (max-width: 1024.98px) {
  .site-name-area {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    padding: 12px 24px 24px;
    margin: -24px -24px 24px;
    background-color: #f9fafb;
  }
  .site-name-area .breadcrumb-list {
    flex-wrap: wrap;
  }
}

.guide-list {
  display: none;
  margin-bottom: 22px;
}
.guide-list li {
  display: flex;
  align-items: flex-start;
  position: relative;
  padding-left: 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: -0.14px;
  color: #374151;
}
.guide-list li::before {
  content: "";
  position: absolute;
  top: 8px;
  left: 0;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #7e8594;
}
.guide-list li + li {
  margin-top: 8px;
}
.guide-list li .txt {
  margin-left: 6px;
}

/* 방문자 통계 요약 보고서 */
.content-title-area {
  padding-bottom: 20px;
  border-bottom: 1px solid #e5e7eb;
}
.content-title-area .content-title {
  font-weight: 700;
  font-size: 28px;
  color: #0f172a;
}
.content-title-area .info {
  margin-top: 4px;
}
.content-title-area .info strong {
  font-weight: 700;
  font-size: 18px;
}
.content-title-area .info .label-date {
  margin-left: 6px;
}
.content-title-area .label-date {
  display: inline-block;
  min-width: 48px;
  border-radius: 12px;
  padding: 3px 8px;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: #4b5563;
}

.box-point .list2 strong {
  color: #1f2937;
}
.box-point .list2 + .guide-text {
  margin-top: 12px;
  padding-top: 12px;
  border-top: 1px solid #e5e7eb;
}

.floating-in-menu .card-cont {
  margin-top: 20px;
  padding-top: 40px;
}
.floating-in-menu .card-cont .cont-title {
  position: relative;
  font-weight: 700;
  font-size: 24px;
  color: #1f2937;
}
.floating-in-menu .card-cont .cont-title + p {
  color: #4b5563;
}
.floating-in-menu .card-cont + .card-cont {
  border-top: 1px solid #e5e7eb;
}
.floating-in-menu .card-cont .card-inner {
  display: flex;
  width: 100%;
  margin-top: 20px;
}
.floating-in-menu .card-cont .card-inner.row {
  width: calc(100% - 20px);
  margin-left: -10px;
}
.floating-in-menu .card-cont .card-inner .card-warp {
  flex: 1 1 auto;
  min-width: 50%;
  padding: 24px 20px 14px;
  background: #f9fafb;
  border: 1px solid #f3f4f6;
  border-radius: 16px;
  overflow: hidden;
}
.floating-in-menu .card-cont .card-inner .chart-area {
  width: 100%;
  min-height: 300px;
  height: 100%;
}
.floating-in-menu .card-cont .card-inner + .guide-text {
  margin-top: 12px;
  font-weight: 400;
  font-size: 12px;
  color: #4b5563;
}
.floating-in-menu .card-cont .card-inner + .guide-text .info {
  font-weight: 400;
  color: #7e8594;
}
.floating-in-menu .card-cont .card-inner + .guide-text .info + span {
  position: relative;
  margin-left: 5px;
  padding-left: 4px;
}
.floating-in-menu .card-cont .card-inner + .guide-text .info + span::before {
  position: absolute;
  top: 5px;
  left: 0px;
  content: "";
  display: inline-block;
  width: 1px;
  height: 10px;
  background: #e5e7eb;
}
.floating-in-menu .card-cont .card-inner + .guide-text .sub {
  color: #7e8594;
}
@media (min-width: 1025px) {
  .floating-in-menu .card-cont .card-inner.row .card-warp {
    margin: 0 10px;
  }
}
@media (max-width: 1024.98px) {
  .floating-in-menu .card-cont .card-inner.row {
    width: 100%;
    margin-left: 0;
  }
}
@media (min-width: 768px) {
  .floating-in-menu .card-cont .card-inner + .guide-text .sub {
    font-size: 12px;
  }
}
@media (max-width: 1024.98px) {
  .floating-in-menu .card-cont .card-inner {
    flex-direction: column;
  }
  .floating-in-menu .card-cont .card-inner .card-warp + .card-warp {
    margin-top: 20px;
  }
}
/* @media (max-width: 767.98px) {
  .floating-in-menu .card-cont .card-inner + .guide-text .info {
    font-size: 10px;
  }
  .floating-in-menu .card-cont .card-inner + .guide-text .info + span {
    font-size: 10px;
  }
  .floating-in-menu .card-cont .card-inner + .guide-text .sub {
    font-size: 10px;
  }
  .floating-in-menu .card-cont .card-inner .title1 {
    font-size: 16px;
  }
} */
.floating-in-menu .card-cont .chart-info {
  display: flex;
  width: 100%;
  margin-bottom: 24px;
}
.floating-in-menu .card-cont .chart-info li {
  padding: 12px 25px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1 auto;
  min-width: 25%;
}
.floating-in-menu .card-cont .chart-info li strong {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  font-weight: 700;
  font-size: 24px;
  color: #1f2937;
  text-align: center;
  overflow-wrap: anywhere;
}
.floating-in-menu .card-cont .chart-info li em {
  display: block;
  font-style: normal;
  font-weight: 500;
  text-align: center;
}
.floating-in-menu .card-cont .chart-info li .up {
  color: #ef4444;
}
.floating-in-menu .card-cont .chart-info li .down {
  color: #357fed;
}
.floating-in-menu .card-cont .chart-info li .nodata {
  font-weight: 700;
  color: #1f2937;
}
.floating-in-menu .card-cont .chart-info li + li::before {
  position: absolute;
  top: 50%;
  left: 0;
  content: "";
  display: inline-block;
  width: 1px;
  height: 80px;
  background: #e5e7eb;
  transform: translateY(-50%);
}
.floating-in-menu .card-cont .chart-info .tit {
  display: flex;
  align-items: center;
  width: auto;
  margin-bottom: 8px;
  font-weight: 500;
  color: #4b5563;
}
.floating-in-menu .card-cont .chart-info .tit .ico {
  margin-right: 4px;
}
.floating-in-menu .card-cont .chart-info .tit span {
  display: inline-block;
  text-align: center;
}
@media (min-width: 768px) {
  .floating-in-menu .card-cont .chart-info .tit {
    font-size: 16px;
  }
}
/* @media (max-width: 767.98px) {
  .floating-in-menu .card-cont .chart-info {
    flex-wrap: wrap;
  }
  .floating-in-menu .card-cont .chart-info li {
    padding: 10px 5px 15px;
    width: 50%;
  }
  .floating-in-menu .card-cont .chart-info li strong {
    font-size: 18px;
  }
  .floating-in-menu .card-cont .chart-info li em {
    font-size: 12px;
  }
  .floating-in-menu .card-cont .chart-info .tit {
    font-size: 14px;
  }
  .floating-in-menu .card-cont .chart-info .border-none::before {
    content: none;
  }
} */
.floating-in-menu .card-cont .nodata-area {
  padding: 124.5px 0;
  border: 0;
}
/* @media (max-width: 767.98px) {
  .floating-in-menu .card-cont .cont-title {
    font-size: 18px;
  }
  .floating-in-menu .card-cont .nodata-area {
    padding: 41.25px 0;
  }
  .floating-in-menu .card-cont .nodata-area p {
    padding-top: 132.5px;
    font-size: 14px;
  }
  .floating-in-menu .card-cont .nodata-area p:after {
    width: 112.5px;
    height: 112.5px;
    background-size: 112.5px auto;
  }
} */
.floating-in-menu .cont-best .nodata-area {
  padding: 168px 0;
}
/* @media (max-width: 767.98px) {
  .floating-in-menu .card-cont .list2 > li:before {
    top: 7.5px;
  }
  .floating-in-menu .cont-best .nodata-area {
    padding: 43.25px 0;
  }
  .floating-in-menu .cont-site .chart-info {
    flex-direction: column;
  }
  .floating-in-menu .cont-site .chart-info > li {
    width: 100%;
    min-width: 100%;
    padding: 0;
    margin-bottom: 20px;
  }
  .floating-in-menu .cont-site .chart-info > li + li {
    margin-bottom: 0;
  }
  .floating-in-menu .cont-site .chart-info > li + li::before {
    top: -20px;
    transform: translateY(0%);
    width: 100%;
    height: 1px;
  }
} */

.table-point-red th {
  background-color: #f3f4f6;
}
.table-point-red tr:first-child {
  background-color: #fecaca;
}
.table-point-red tr:nth-child(2) {
  background-color: #fee2e2;
}
.table-point-red tr:nth-child(3) {
  background-color: #fef2f2;
}

.table-point-blue th {
  background-color: #f3f4f6;
}
.table-point-blue tr:first-child {
  background-color: #bfdbfe;
}
.table-point-blue tr:nth-child(2) {
  background-color: #e3efff;
}
.table-point-blue tr:nth-child(3) {
  background-color: #eff6ff;
}

/* 사이트 방문자 수 영역 */
.cont-visitor {
  padding-top: 20px;
}
.cont-visitor .cont-title::before {
  position: absolute;
  top: 50%;
  left: 0px;
  content: "";
  display: inline-block;
  background-repeat: no-repeat;
  transform: translateY(-50%);
}
.cont-visitor .cont-title + p {
  margin-top: 4px;
}
/* @media (min-width: 768px) {
  .cont-visitor .cont-title {
    padding-left: 40px;
  }
  .cont-visitor .cont-title::before {
    width: 32px;
    height: 32px;
    background-size: 32px auto;
  }
}
@media (max-width: 767.98px) {
  .cont-visitor .cont-title {
    padding-left: 32px;
    font-size: 18px;
  }
  .cont-visitor .cont-title::before {
    top: 0;
    width: 24px;
    height: 24px;
    background-size: 24px auto;
    transform: translateY(0%);
  }
  .cont-visitor .card-warp {
    padding: 24px 12px;
  }
} */

/* 인기 메뉴 영역 */
.cont-popularity .cont-title::before {
  position: absolute;
  top: 50%;
  left: 0px;
  content: "";
  display: inline-block;
  background-repeat: no-repeat;
  transform: translateY(-50%);
}
.cont-popularity .cont-title + p {
  margin-top: 4px;
}
.cont-popularity .card-warp .sub-tit {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  font-weight: 500;
  color: #4b5563;
}
.cont-popularity .card-warp .sub-tit .ico {
  margin-right: 4px;
}
.cont-popularity .card-warp .title {
  display: block;
  width: 100%;
  margin-bottom: 24px;
  text-align: center;
  font-weight: 700;
  font-size: 24px;
  color: #1f2937;
}
.cont-popularity .card-warp .title span {
  color: #4b5563;
}
@media (min-width: 768px) {
  .cont-popularity .sub-tit {
    font-size: 16px;
  }
  .cont-popularity .cont-title {
    padding-left: 40px;
  }
  .cont-popularity .cont-title::before {
    width: 32px;
    height: 32px;
    background-size: 32px auto;
  }
  .cont-popularity .cont-title + p {
    margin-top: 4px;
  }
}
/* @media (max-width: 767.98px) {
  .cont-popularity .sub-tit {
    font-size: 14px;
  }
  .cont-popularity .cont-title {
    padding-left: 32px;
    font-size: 18px;
  }
  .cont-popularity .cont-title::before {
    top: 0;
    transform: translateY(0%);
    width: 24px;
    height: 24px;
    background-size: 24px auto;
  }
  .cont-popularity .card-warp .title {
    font-size: 18px;
  }
} */

/* 만족도 조사 영역 */
.cont-satisfaction .cont-title::before {
  position: absolute;
  top: 50%;
  left: 0px;
  content: "";
  display: inline-block;
  background-repeat: no-repeat;
  transform: translateY(-50%);
}
.cont-satisfaction .cont-title + p {
  margin-top: 4px;
}
.cont-satisfaction .card-warp .sub-tit {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  font-weight: 500;
  color: #4b5563;
}
.cont-satisfaction .card-warp .sub-tit .ico {
  margin-right: 4px;
}
.cont-satisfaction .card-warp .title {
  display: block;
  width: 100%;
  margin-bottom: 24px;
  text-align: center;
  font-weight: 700;
  font-size: 24px;
  color: #1f2937;
}
.cont-satisfaction .card-warp .title span {
  color: #4b5563;
}
.cont-satisfaction .card-warp .chart-info {
  padding-bottom: 24px;
  border-bottom: 1px solid #d1d5db;
}
.cont-satisfaction .card-warp .chart-info li + li:before {
  height: 40px;
}
@media (min-width: 768px) {
  .cont-satisfaction .sub-tit {
    font-size: 16px;
  }
  .cont-satisfaction .cont-title {
    padding-left: 40px;
  }
  .cont-satisfaction .cont-title::before {
    width: 32px;
    height: 32px;
    background-size: 32px auto;
  }
  .cont-satisfaction .cont-title + p {
    margin-top: 4px;
  }
  .cont-satisfaction .chart-info {
    padding-bottom: 24px;
    border-bottom: 1px solid #d1d5db;
  }
  .cont-satisfaction .chart-info li {
    min-width: 50%;
    padding: 0 10px;
  }
}
/* @media (max-width: 767.98px) {
  .cont-satisfaction .sub-tit {
    font-size: 14px;
  }
  .cont-satisfaction .cont-title {
    padding-left: 32px;
    font-size: 18px;
  }
  .cont-satisfaction .cont-title::before {
    top: 0;
    transform: translateY(0%);
    width: 24px;
    height: 24px;
    background-size: 24px auto;
  }
  .cont-satisfaction .card-warp .title {
    font-size: 18px;
  }
  .cont-satisfaction .chart-info li {
    padding: 0 5px;
    min-width: 50%;
  }
} */

/* 베스트 게시물 영역 */
.cont-best .cont-title::before {
  position: absolute;
  top: 50%;
  left: 0px;
  content: "";
  display: inline-block;
  background-repeat: no-repeat;
  transform: translateY(-50%);
}
.cont-best .cont-title + p {
  margin-top: 4px;
}
.cont-best .card-warp .sub-tit {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  font-weight: 500;
  color: #4b5563;
}
.cont-best .card-warp .sub-tit .ico {
  margin-right: 4px;
}
.cont-best .card-warp .title {
  display: block;
  width: 100%;
  margin-bottom: 24px;
  text-align: center;
  font-weight: 700;
  font-size: 24px;
  color: #1f2937;
}
.cont-best .card-warp .title span {
  color: #4b5563;
}
@media (min-width: 768px) {
  .cont-best .sub-tit {
    font-size: 16px;
  }
  .cont-best .cont-title {
    padding-left: 40px;
  }
  .cont-best .cont-title::before {
    width: 32px;
    height: 32px;
    background-size: 32px auto;
  }
  .cont-best .cont-title + p {
    margin-top: 4px;
  }
}
/* @media (max-width: 767.98px) {
  .cont-best .sub-tit {
    font-size: 14px;
  }
  .cont-best .cont-title {
    padding-left: 32px;
    font-size: 18px;
  }
  .cont-best .cont-title::before {
    top: 0;
    transform: translateY(0%);
    width: 24px;
    height: 24px;
    background-size: 24px auto;
  }
  .cont-best .card-warp .title {
    font-size: 18px;
  }
} */

/* 게시판 현황 영역 */
.cont-board .cont-title::before {
  position: absolute;
  top: 50%;
  left: 0px;
  content: "";
  display: inline-block;
  background-repeat: no-repeat;
  transform: translateY(-50%);
}
.cont-board .cont-title + p {
  margin-top: 4px;
}
.cont-board .card-warp .sub-tit {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  font-weight: 500;
  color: #4b5563;
}
.cont-board .card-warp .sub-tit .ico {
  margin-right: 4px;
}
.cont-board .card-warp .title {
  display: block;
  width: 100%;
  margin-bottom: 24px;
  text-align: center;
  font-weight: 700;
  font-size: 24px;
  color: #1f2937;
}
.cont-board .card-warp .title span {
  color: #4b5563;
}
.cont-board .card-warp .chart-info {
  padding-bottom: 24px;
  border-bottom: 1px solid #d1d5db;
}
.cont-board .card-warp .chart-info li + li:before {
  height: 40px;
}
@media (min-width: 768px) {
  .cont-board .cont-title {
    padding-left: 40px;
  }
  .cont-board .cont-title::before {
    width: 32px;
    height: 32px;
    background-size: 32px auto;
  }
  .cont-board .cont-title + p {
    margin-top: 4px;
  }
  .cont-board .chart-info li {
    padding: 0 25px;
    min-width: 33.3%;
  }
}
@media (max-width: 1024.98px) {
  .cont-board .sub-tit {
    font-size: 16px;
  }
  .cont-board .card-warp .chart-info {
    padding-bottom: 0;
    border-bottom: none;
  }
}
/* @media (max-width: 767.98px) {
  .cont-board .sub-tit {
    font-size: 14px;
  }
  .cont-board .cont-title {
    padding-left: 32px;
    font-size: 18px;
  }
  .cont-board .cont-title::before {
    top: 0;
    transform: translateY(0%);
    width: 24px;
    height: 24px;
    background-size: 24px auto;
  }
  .cont-board .card-warp .title {
    font-size: 18px;
  }
  .cont-board .card-warp .chart-info li {
    padding: 0 10px;
    min-width: 100%;
  }
  .cont-board .card-warp .chart-info li + li {
    margin-top: 20px;
    padding-top: 20px;
  }
  .cont-board .card-warp .chart-info li + li::before {
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
  }
} */

/* 전체 사이트 방문자 영역 */
.cont-site .cont-title::before {
  position: absolute;
  top: 50%;
  left: 0px;
  content: "";
  display: inline-block;
  background-repeat: no-repeat;
  transform: translateY(-50%);
}
.cont-site .cont-title + p {
  margin-top: 4px;
}
.cont-site .guide-text .list2 li {
  font-weight: 500;
  font-size: 14px;
  color: #374151;
}
.cont-site .guide-text .list2 li::before {
  top: 7.5px;
  background: #a6adb9;
}
.cont-site .row-area {
  display: flex;
  width: 100%;
}
.cont-site .row-area .table-area {
  overflow: hidden;
}
.cont-site .chart-info li {
  width: 50%;
}
.cont-site .chart-info li + li::before {
  height: 40px;
}
@media (min-width: 1025px) {
  .cont-site .row-area {
    flex-direction: row;
    width: 100%;
    gap: 20px;
  }
  .cont-site .row-area .table-area,
  .cont-site .row-area .chart-area {
    flex: 1 1 auto;
    width: 100%;
    min-width: calc(50% - 10px);
  }
}
@media (min-width: 768px) {
  .cont-site .cont-title {
    padding-left: 40px;
  }
  .cont-site .cont-title::before {
    width: 32px;
    height: 32px;
    background-size: 32px auto;
  }
  .cont-site .cont-title + p {
    margin-top: 4px;
  }
  .cont-site .chart-info li {
    min-width: 50%;
    padding: 0 25px;
  }
}
@media (max-width: 1024.98px) {
  .cont-site .row-area {
    flex-direction: column;
  }
  .cont-site .row-area .chart-area {
    margin-top: 20px;
  }
  .cont-site .row-area > div {
    flex: 1 1 auto;
    width: 100%;
  }
}
/* @media (max-width: 767.98px) {
  .cont-site .cont-title {
    padding-left: 32px;
    font-size: 18px;
    letter-spacing: -0.05rem;
  }
  .cont-site .cont-title::before {
    top: 0px;
    width: 24px;
    height: 24px;
    background-size: 24px auto;
    transform: translateY(0%);
  }
  .cont-site .chart-info .tit {
    text-align: center;
  }
  .cont-site .chart-info li {
    padding: 0 10px;
    min-width: 100%;
  }
  .cont-site .chart-info li + li {
    margin-top: 20px;
    padding-top: 20px;
  }
  .cont-site .chart-info li + li::before {
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
  }
  .cont-site .guide-text .list2 li {
    font-weight: 400;
    font-size: 12px;
  }
  .cont-site .guide-text .list2 li::before {
    top: 5.5px;
  }
} */

/* 플롯팅 매뉴 */

.floating-in-menu .card {
  width: calc(100% - 220px);
}
.floating-in-menu .floating-area {
  position: fixed;
  z-index: 500;
  right: 32px;
  top: 0px;
  width: 200px;
  padding: 0 12px;
  transition-duration: 0.6s;
  transition-property: top, margin-top, border-color, background-color,
    box-shadow, transform;
}
.floating-in-menu .floating-area .title {
  font-weight: 700;
  font-size: 16px;
  color: #1f2937;
}
.floating-in-menu .floating-nav {
  margin: 12px 0;
}
.floating-in-menu .floating-nav li {
  position: relative;
}
.floating-in-menu .floating-nav li + li {
  margin-top: 10px;
}
.floating-in-menu .floating-nav li a.on {
  color: #2563eb;
}
.floating-in-menu .floating-nav li a.on .text:after {
  position: absolute;
  top: calc(50% + 1px);
  left: 0px;
  content: "";
  width: 2px;
  height: 16px;
  background: #2563eb;
  border-radius: 1px;
  transform: translateY(-50%);
}
.floating-in-menu .floating-nav a {
  display: block;
  width: 100%;
}
.floating-in-menu .floating-nav a:hover {
  color: #7e8594;
}
.floating-in-menu .floating-nav a:hover .text:after {
  position: absolute;
  top: calc(50% + 1px);
  left: 0px;
  content: "";
  width: 2px;
  height: 16px;
  background: #7e8594;
  border-radius: 1px;
  transform: translateY(-50%);
}
.floating-in-menu .floating-nav .text {
  padding: 3px 12px;
}
.floating-in-menu .btn-group .btn {
  width: 100%;
}
@media (min-width: 1025px) {
  .floating-in-menu {
    margin-top: 20px;
  }
  .floating-in-menu .floating-area {
    bottom: auto;
  }
  .floating-in-menu .btn-group-floating {
    display: none;
  }
}
@media (max-width: 1279.98px) {
  .floating-in-menu .card {
    width: 100%;
  }
  .floating-in-menu .floating-area {
    top: auto;
    right: 10px;
    bottom: 20px;
  }
  .floating-in-menu .floating-wrap {
    position: absolute;
    display: none;
    right: 10px;
    bottom: 58px;
    width: 200px;
    min-height: 280px;
    padding: 16px;
    background: #fff;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.15);
    border-radius: 16px;
  }
  .floating-in-menu .floating-wrap.on {
    display: block;
  }
  .floating-in-menu .btn-group {
    display: none;
  }
  .floating-in-menu .btn-group-floating {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
  .floating-in-menu .btn-floating {
    display: block;
    width: 48px;
    height: 48px;
    padding: 10.5px;
    margin: 0;
    background: #fff;
    border: 1px solid #a6adb9;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
  }
  .floating-in-menu .btn-floating.close {
    background: #374151;
  }
  .floating-in-menu .btn-floating.close .ico-floating-gray-lg {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 24px auto;
  }
}
@media (max-width: 1024.98px) {
  .floating-in-menu {
    margin-top: 12px;
  }
}

.chart-area {
  height: auto;
  min-height: 100px;
}
.chart-area #chart-bar,
.chart-area #chart-line,
.chart-area #chart-pie {
  width: 100%;
  height: 100%;
  min-height: 304px;
}
.chart-area .inner {
  display: flex;
  width: 100%;
  height: 100%;
  gap: 40px;
  margin: 0;
}
.chart-area .col-item {
  position: relative;
  flex: 1 1 auto;
  width: 100%;
  height: 100%;
}
.chart-area .cont-inner {
  display: block;
  width: 100%;
  height: 100%;
}
.chart-area .chart-tit {
  position: absolute;
  top: 42%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  text-align: center;
}
@media (min-width: 1025px) {
  .chart-area .col-item {
    min-width: 30%;
  }
  .chart-area .col-item.col-3 {
    max-width: 70%;
  }
  .chart-area .col-item.col-3 + .col-item {
    max-width: 30%;
  }
}
@media (max-width: 1024.98px) {
  .chart-area .inner {
    flex-direction: column;
  }
  .chart-area .col-item.col-3 {
    max-width: 100%;
  }
  .chart-area .col-item.col-3 + .col-item {
    max-width: 100%;
  }
}

.print-position {
  position: absolute;
  left: -9999px;
}

/* 배경 */
.report-print-area {
  display: none;
}
.report-print-area .cover {
  padding: 86px 66px;
  background: #357fed;
}

.page-inner {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.page-inner .cover-header {
  width: 100%;
  padding: 0 56px;
}
.page-inner .report-title {
  display: block;
  margin-top: 8px;
  margin-bottom: 12px;
  font-weight: 700;
  font-size: 64px;
  line-height: 1.5;
  color: #fff;
  text-align: left;
}
.page-inner .report-title + .report-tit-desc {
  display: inline-block;
  width: 100%;
  padding: 0 10px;
  font-weight: 700;
  font-size: 32px;
  margin-top: 5px;
  color: #f3f4f6;
  text-align: left;
}
.page-inner .label-date {
  display: inline-block;
  height: 32px;
  margin: 12px 8px;
  padding: 3px 8px;
  background: #60a5fa;
  border-radius: 16px;
  font-weight: 400;
  font-size: 18px;
  color: #fff;
}

/* 커버 */
.cover-header {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.cover-footer {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
}
.cover-footer .logo {
  position: absolute;
  bottom: 68px;
  right: 66px;
  width: 96px;
  height: 22px;
}

@media print {
  @page {
    size: A4;
    margin: 0;
    padding: 0;
  }
  body {
    margin: 0;
  }
  .layout-type-sub {
    margin-top: 0;
    background: #357fed;
  }
  * {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
  header,
  footer {
    display: none;
  }
  .printmode #sidebar,
  .printmode .content-header,
  .printmode #cont-search,
  .printmode .floating-area,
  .printmode .table-form-basic,
  .printmode .ui-draggable,
  .printmode .content-title-area,
  .printmode .btn-group-fixed {
    display: none;
  }
  .printmode .content-header.header-up,
  .printmode .content-header.header-down {
    display: none;
  }
  .printmode #content,
  .printmode .nav-collapsed #content {
    margin-left: 0;
  }
  .printmode .wrapper {
    width: 100%;
    min-width: 100%;
  }
  .printmode .content-body {
    padding: 0;
  }
  .printmode table {
    min-width: 100% !important;
  }
  .printmode .report-print-area {
    position: relative;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: block;
    width: 100%;
    height: 100%;
    min-height: 100%;
    page-break-after: avoid;
    page-break-before: avoid;
    page-break-inside: avoid;
  }
  .printmode .floating-in-menu {
    margin: 0;
  }
  .printmode .card {
    width: 100% !important;
    height: 100%;
    padding: 0;
  }
  .printmode .cover {
    display: block;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    min-height: 910px;
    height: 100vh;
    max-height: 1120px;
  }
  .printmode .cover.closing {
    margin-top: 0;
  }
  @-moz-document url-prefix() {
    .printmode .cover {
      min-height: 1122.5px;
    }
    .printmode .cont-site .row-area .chart-area {
      position: relative;
      top: 40px;
      display: block !important;
      width: 100%;
      height: 520px;
      border: 0px solid transparent;
    }
    .printmode .cont-site .row-area .inner {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 300px;
      padding: 24px 20px 14px;
      background: #f9fafb;
      border: 1px solid #f3f4f6;
      border-radius: 16px;
      overflow: auto;
    }
  }
  .printmode .card-cont {
    display: block !important;
    margin-top: 0 !important;
    padding: 44px 42px !important;
    page-break-after: always;
    page-break-inside: avoid;
  }
  .printmode .card-cont + .card-cont {
    border-top: none;
  }
  .printmode.safari .card-cont {
    padding: 0 0 !important;
  }
  .printmode.safari .card-warp + .card-warp {
    margin-top: 0 !important;
  }
  .printmode.safari .row-area .chart-area {
    margin-top: 0 !important;
  }
  .printmode .cont-visitor .card-warp {
    padding: 24px 20px 14px;
  }
  .printmode .cont-visitor .chart-area .inner {
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 0px;
    overflow: hidden;
  }
  .printmode .cont-visitor .chart-area .col-item {
    flex: 0 0 auto;
    margin: 0;
  }
  .printmode .cont-visitor .chart-area .col-item.col-3 {
    max-width: 65% !important;
  }
  .printmode .cont-visitor .chart-area .col-item.col-3 + .col-item {
    max-width: 35% !important;
  }
  .printmode .cont-visitor .chart-area #chart-line {
    max-width: 100% !important;
  }
  .printmode .cont-visitor .chart-area #chart-line * {
    width: 100% !important;
    height: 100% !important;
    object-fit: scale-down;
  }
  .printmode .cont-visitor .chart-area #chart-pie {
    max-width: 100% !important;
  }
  .printmode .cont-visitor .chart-area #chart-pie * {
    width: 100% !important;
    height: 100% !important;
    object-fit: scale-down;
  }
  .printmode .cont-popularity .chart-area {
    width: 100% !important;
  }
  .printmode .cont-popularity .chart-area #chart-bar * {
    width: 100% !important;
    height: 100% !important;
    object-fit: contain;
  }
  .printmode .card-inner.row {
    display: block;
    width: 100%;
  }
  .printmode .card-inner.row .card-warp {
    display: block;
    width: 100%;
  }
  .printmode .card-inner.row .card-warp + .card-warp {
    display: inline-block;
    page-break-before: always;
    break-before: always;
    page-break-inside: avoid;
    break-inside: avoid;
    margin-top: 44px;
  }
  .printmode .chart-info li {
    width: 25%;
  }
  .printmode .cont-best .title .d-down-sm {
    display: none !important;
  }
  .printmode .cont-satisfaction .chart-area {
    width: 100% !important;
  }
  .printmode .cont-satisfaction .chart-area #chart-line2 * {
    width: 100% !important;
    height: 100% !important;
    object-fit: contain;
  }
  .printmode .cont-board .chart-info li {
    width: 33.3%;
    min-width: 33.3%;
  }
  .printmode .cont-board .chart-info li + li {
    margin-top: 0;
    padding-top: 0;
  }
  .printmode .cont-board .chart-info li + li::before {
    top: 50%;
    left: 0;
    width: 1px;
    height: 40px;
    background: #e5e7eb;
    transform: translateY(-50%);
  }
  .printmode .cont-site {
    padding: 20px 42px;
  }
  .printmode .cont-site .chart-area {
    width: 100% !important;
    height: 480px !important;
  }
  .printmode .cont-site .chart-area .chart-tit {
    top: 61%;
  }
  .printmode .cont-site .chart-area #chart-pie2 * {
    min-width: 100% !important;
    height: 100% !important;
    object-fit: contain;
  }
  .printmode .cont-site .card-inner .card-warp {
    background: transparent;
    padding: 0;
    border: none;
  }
  .printmode .cont-site .card-inner .card-warp .chart-info {
    margin-bottom: 0;
    padding: 24px 20px 24px;
    background: #f9fafb;
    border: 1px solid #f3f4f6;
    border-bottom: 0;
    border-radius: 16px 16px 0 0;
  }
  .printmode .cont-site .card-inner .card-warp .table-area {
    padding: 0 20px 14px;
    background: #f9fafb;
    border: 1px solid #f3f4f6;
    border-top: 0;
    border-radius: 0 0 16px 16px;
  }
  .printmode .cont-site .card-inner .card-warp .chart-area {
    padding: 24px 20px 14px;
    background: #f9fafb;
    border: 1px solid #f3f4f6;
    border-radius: 16px;
  }
  .printmode .cont-site .chart-info li {
    width: 50%;
    min-width: 50%;
  }
  .printmode .cont-site .chart-info li + li {
    margin-top: 0;
    padding-top: 0;
  }
  .printmode .cont-site .chart-info li + li::before {
    top: 50%;
    left: 0;
    width: 1px;
    height: 40px;
    background: #e5e7eb;
    transform: translateY(-50%);
  }
  .printmode .row-area {
    display: block;
  }
  .printmode .row-area .table-area {
    page-break-after: always;
    break-after: always;
    page-break-inside: avoid;
    break-inside: avoid;
  }
  .printmode .row-area .chart-area {
    display: flex;
    page-break-before: always;
    break-after: always;
    page-break-inside: avoid;
    break-inside: avoid;
    height: 100%;
    margin-top: 44px;
    padding: 24px 20px;
    background: #f9fafb;
    border: 1px solid #f3f4f6;
    border-radius: 16px;
  }
  .printmode .row-area .chart-area .inner {
    display: block;
    width: 100%;
    height: 300px;
    min-height: 300px;
  }
}
.tutorial {
  max-width: 1200px;
  margin: 0 auto;
}
.tutorial .title2 {
  margin-bottom: 4px;
  font-size: 28px;
  font-weight: 700;
  color: #0f172a;
}
.tutorial .sub-text {
  font-size: 16px;
  font-weight: 400;
  color: #374151;
}
.tutorial .tit-area {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  text-align: left;
}
.tutorial .tutorial-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
  margin-top: 20px;
}
.tutorial .tutorial-list li {
  flex: 1 1 calc(33.3% - 20px);
  width: auto;
  height: 100px;
}
.tutorial .tutorial-list .item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 20px;
  background: #f3f4f6;
  border-radius: 12px;
}
.tutorial .tutorial-list .cont {
  display: flex;
  flex-direction: column;
  gap: 4px;
  color: #4b5563;
}
.tutorial .tutorial-list .cont > strong {
  font-size: 18px;
  color: #1f2937;
}
.tutorial .tutorial-list .ico {
  margin: 6px;
}
@media (min-width: 1025px) {
  .tutorial {
    padding: 40px;
  }
}
@media (max-width: 1024.98px) {
  .tutorial {
    padding: 20px;
  }
  .tutorial .title2 {
    font-size: 24px;
  }
  .tutorial .sub-text {
    font-size: 14px;
  }
  .tutorial .tit-area {
    flex-direction: column;
    gap: 12px;
  }
  .tutorial .tit-area > div {
    width: 100%;
  }
  .tutorial .tutorial-list {
    flex-direction: column;
  }
  .tutorial .tutorial-list .cont > strong {
    font-size: 16px;
  }
}
.tutorial .support-services {
  margin-top: 16px;
}
.tutorial .support-services .services-item .box-state {
  display: flex;
  flex-direction: row;
  gap: 80px;
  width: 100%;
  margin-bottom: 12px;
  padding: 32px;
}
.tutorial .support-services .services-item .box-state + .box {
  margin: 0;
}
.tutorial .support-services .services-item .box-state > div {
  flex: 1 1 auto;
  width: 50%;
}
.tutorial .support-services .services-item .box-state .right {
  position: relative;
}
.tutorial .support-services .services-item .box-state .right::before {
  position: absolute;
  top: 50%;
  left: -40px;
  transform: translateY(-50%);
  content: "";
  display: inline-block;
  width: 1px;
  height: 100%;
}
.tutorial .support-services .state-free {
  margin-bottom: 2px;
  display: inline-block;
  width: 100%;
  font-weight: 500;
  color: #4b5563;
}
.tutorial .support-services .state {
  display: inline-block;
  margin-bottom: 12px;
  font-size: 24px;
  font-weight: 700;
  color: #000;
}
.tutorial .support-services .list-check li {
  position: relative;
  padding-left: 28px;
  font-size: 16px;
}
.tutorial .support-services .list-check li strong {
  font-weight: 500;
  color: #1f2937;
}
.tutorial .support-services .list-check li + li {
  margin-top: 6px;
}
.tutorial .support-services .list-check li::before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 8px;
  background-size: 20px auto;
}
.tutorial .support-services .info .tit {
  margin-bottom: 4px;
  font-size: 16px;
  color: #4b5563;
}
.tutorial .support-services .info dd {
  font-size: 16px;
  font-weight: 700;
  color: #1f2937;
}
.tutorial .support-services .info + .info {
  margin-top: 22px;
}
.tutorial .support-services .basic .box-state {
  background: #fff;
  border: 1px solid #d1d5db;
}
.tutorial .support-services .basic .box-state .right::before {
  background: #e5e7eb;
}
.tutorial .support-services .contract .box-state {
  background: #0f172a;
  border: 1px solid #0f172a;
}
.tutorial .support-services .contract .box-state .right::before {
  background: #374151;
}
.tutorial .support-services .contract .state {
  color: #fff;
}
.tutorial .support-services .contract .list-check {
  color: rgba(255, 255, 255, 0.7);
}
.tutorial .support-services .contract .list-check strong {
  color: #fff;
}
.tutorial .support-services .contract .list-check li::before {
  background-size: 20px auto;
}
.tutorial .support-services .contract .info .tit {
  color: #a6adb9;
}
.tutorial .support-services .contract .info dd {
  color: #fff;
}
.tutorial .support-services .box-guide {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}
.tutorial .support-services .box-guide .info .title3 {
  display: inline-block;
  margin-bottom: 2px;
  font-size: 18px;
  font-weight: 700;
}
.tutorial .support-services .box-guide .info p {
  font-size: 16px;
  color: #4b5563;
}
.tutorial .support-services .box-guide .left {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}
.tutorial .support-services .ico-wrap {
  flex: 0 0 auto;
  width: 56px;
  height: 56px;
  padding: 4px;
  background: #fff;
  border-radius: 50%;
}
.tutorial .support-services .btn-pms-shortcuts {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 4px;
  padding: 0 20px;
  background: #f54090;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
}
.tutorial .support-services .btn-pms-shortcuts .ico {
  margin-top: 2px;
}
@media (max-width: 1024.98px) {
  .tutorial .support-services .services-item .box-state {
    flex-direction: column;
    padding: 20px;
    gap: 25px;
  }
  .tutorial .support-services .services-item .box-state > div {
    width: 100%;
  }
  .tutorial .support-services .services-item .box-state .right::before {
    top: -12px;
    left: 0;
    content: "";
    width: 100%;
    height: 1px;
  }
  .tutorial .support-services .services-item .left {
    width: 100%;
  }
  .tutorial .support-services .state {
    font-size: 18px;
  }
  .tutorial .support-services .list-check li {
    font-size: 14px;
  }
  .tutorial .support-services .info .tit {
    font-size: 14px;
  }
  .tutorial .support-services .info dd {
    font-size: 14px;
  }
  .tutorial .support-services .info + .info {
    margin-top: 12px;
  }
  .tutorial .support-services .box-guide {
    flex-direction: column;
    gap: 12px;
  }
  .tutorial .support-services .box-guide .left {
    gap: 16px;
  }
  .tutorial .support-services .box-guide .info .title3 {
    font-size: 16px;
  }
  .tutorial .support-services .box-guide .info p {
    font-size: 14px;
  }
}
.tutorial .faq-area {
  margin-top: 20px;
}
.tutorial .toggle-list {
  border-top: 1px solid #d1d5db;
}
.tutorial .toggle-item {
  border-bottom: 1px solid #d1d5db;
}
.tutorial .toggle-title.on .ico-chevron-bottom-darkgray {
  transform: rotateX(180deg);
}
.tutorial .toggle-btn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  width: 100%;
  padding: 32px 0;
}
.tutorial .toggle-btn .title {
  display: inline-block;
  font-size: 24px;
  font-weight: 700;
  color: #1f2937;
  text-align: left;
}
.tutorial .toggle-btn .ico-chevron-bottom-darkgray {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  vertical-align: middle;
  overflow: hidden;
  text-indent: -9999px;
  overflow: hidden;
  text-align: left;
  width: 32px;
  height: 32px;
  background-size: 32px auto;
}
.tutorial .toggle-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  padding-bottom: 40px;
  font-size: 16px;
}
.tutorial .toggle-content .list.list-depth1 {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.tutorial .toggle-content .list.list-depth1 > li {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}
.tutorial .toggle-content .list.list-depth1 > li.gap-xl {
  gap: 12px;
}
.tutorial .toggle-content .list.list-depth1 > li::after {
  content: none;
}
.tutorial .toggle-content .list.list-dot li {
  position: relative;
  padding-left: 12px;
}
.tutorial .toggle-content .list.list-dot li + li {
  margin-top: 6px;
}
.tutorial .toggle-content .list.list-dot li:before {
  position: absolute;
  top: 8.5px;
  content: "";
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #a6adb9;
}
.tutorial .toggle-content .tit {
  position: relative;
  display: block;
  padding-left: 28px;
  font-size: 18px;
  font-weight: 700;
  color: #1f2937;
}
.tutorial .toggle-content .num {
  position: absolute;
  top: 0;
  left: 6.4px;
}
.tutorial .toggle-content .img-area {
  width: 100%;
  padding: 40px;
  border-radius: 20px;
  background: #f3f4f6;
  text-align: center;
}
.tutorial .toggle-content .img-area .img {
  width: 100%;
  max-width: 800px;
}
.tutorial .toggle-content .img-area .img-md {
  width: 100%;
  max-width: 432px;
}
.tutorial .toggle-content .btn-xl {
  display: inline-flex;
  gap: 4px;
  height: 56px;
  padding: 12px 24px;
  border-radius: 10px;
  font-weight: 700;
  font-size: 16px;
}
.tutorial .toggle-content .btn-xl .ico {
  margin-top: 2px;
  margin-right: 0;
}
.tutorial .toggle-content .btn-lightgray {
  background: #f3f4f6;
}
@media (max-width: 1024.98px) {
  .tutorial .toggle-btn {
    padding: 24px 0;
  }
  .tutorial .toggle-btn .title {
    font-size: 18px;
  }
  .tutorial .toggle-content {
    font-size: 14px;
    padding-bottom: 24px;
  }
  .tutorial .toggle-content .tit {
    position: relative;
    padding-left: 24px;
    font-size: 16px;
  }
  .tutorial .toggle-content .num {
    position: absolute;
    top: 0;
    left: 5px;
  }
  .tutorial .toggle-content .img-area {
    padding: 20px;
    border-radius: 7.5px;
  }
}
