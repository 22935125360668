@charset "UTF-8";
/* 화면명1 */
/* UI 컴포넌트 가이드 */
.content-header {
  text-align: center;
}
.content-header .content-title {
  line-height: 1;
}
.content-header .content-title img {
  vertical-align: middle;
}
.content-header .content-title strong {
  vertical-align: middle;
  font-weight: 600;
}
.content-header .content-title span {
  display: inline-block;
  vertical-align: middle;
  font-weight: normal;
  font-weight: 500;
}
@media (min-width: 1025px) {
  .content-header {
    margin: 0 0 65px;
    padding-top: 73px;
  }
  .content-header .content-title {
    font-size: 30px;
    font-family: "Spoqa Han Sans Neo", "malgun gothic", Arial, sans-serif;
  }
}
@media (max-width: 1024.98px) {
  .content-header {
    margin: 0 0 39.5px;
    padding-top: 36px;
  }
  .content-header .content-title {
    font-size: 24px;
    padding: 0 15px;
  }
  .content-header .content-title span {
    display: block;
  }
}

@media (min-width: 1025px) {
  .content-body {
    padding-bottom: 50px;
  }
}
@media (max-width: 1024.98px) {
  .content-body {
    padding: 0 15px;
  }
}

.wrapper-html-guide .content-body {
  padding-bottom: 0;
}
.wrapper-html-guide .badge {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin-top: 0.45em;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #c52f2f;
}
.wrapper-html-guide .row .col {
  display: table-cell;
  width: 1%;
}
.wrapper-html-guide .row .col:after {
  display: block;
  clear: both;
  content: "";
}
.wrapper-html-guide .row.row-auto {
  display: table;
  width: 100%;
}
.wrapper-html-guide .row.row-auto .col {
  width: auto;
}
.wrapper-html-guide .examples {
  position: relative;
  z-index: 10;
}
.wrapper-html-guide .examples .title1 {
  display: block;
}
.wrapper-html-guide .gutter-1 {
  margin-left: -2.5px;
  margin-right: -2.5px;
}
.wrapper-html-guide .gutter-1 .col {
  padding: 0 2.5px;
}
.wrapper-html-guide .gutter-1.row-auto {
  width: calc(100% + 10px);
}
.wrapper-html-guide .gutter-2 {
  margin-left: -5px;
  margin-right: -5px;
}
.wrapper-html-guide .gutter-2 .col {
  padding: 0 5px;
}
.wrapper-html-guide .gutter-2.row-auto {
  width: calc(100% + 20px);
}
.wrapper-html-guide .gutter-3 {
  margin-left: -7.5px;
  margin-right: -7.5px;
}
.wrapper-html-guide .gutter-3 .col {
  padding: 0 7.5px;
}
.wrapper-html-guide .gutter-3.row-auto {
  width: calc(100% + 30px);
}
.wrapper-html-guide .gutter-4 {
  margin-left: -10px;
  margin-right: -10px;
}
.wrapper-html-guide .gutter-4 .col {
  padding: 0 10px;
}
.wrapper-html-guide .gutter-4.row-auto {
  width: calc(100% + 40px);
}
.wrapper-html-guide .gutter-5 {
  margin-left: -12.5px;
  margin-right: -12.5px;
}
.wrapper-html-guide .gutter-5 .col {
  padding: 0 12.5px;
}
.wrapper-html-guide .gutter-5.row-auto {
  width: calc(100% + 50px);
}
.wrapper-html-guide .gutter-6 {
  margin-left: -15px;
  margin-right: -15px;
}
.wrapper-html-guide .gutter-6 .col {
  padding: 0 15px;
}
.wrapper-html-guide .gutter-6.row-auto {
  width: calc(100% + 60px);
}
.wrapper-html-guide .gutter-7 {
  margin-left: -17.5px;
  margin-right: -17.5px;
}
.wrapper-html-guide .gutter-7 .col {
  padding: 0 17.5px;
}
.wrapper-html-guide .gutter-7.row-auto {
  width: calc(100% + 70px);
}
.wrapper-html-guide .gutter-8 {
  margin-left: -20px;
  margin-right: -20px;
}
.wrapper-html-guide .gutter-8 .col {
  padding: 0 20px;
}
.wrapper-html-guide .gutter-8.row-auto {
  width: calc(100% + 80px);
}
.wrapper-html-guide .gutter-9 {
  margin-left: -22.5px;
  margin-right: -22.5px;
}
.wrapper-html-guide .gutter-9 .col {
  padding: 0 22.5px;
}
.wrapper-html-guide .gutter-9.row-auto {
  width: calc(100% + 90px);
}
.wrapper-html-guide .gutter-10 {
  margin-left: -25px;
  margin-right: -25px;
}
.wrapper-html-guide .gutter-10 .col {
  padding: 0 25px;
}
.wrapper-html-guide .gutter-10.row-auto {
  width: calc(100% + 100px);
}
@media (min-width: 1025px) {
  .wrapper-html-guide #content {
    padding: 0 180px;
  }
  .wrapper-html-guide .content-row .syntaxhighlighter {
    margin-top: 0 !important;
  }
}
@media (max-width: 1024.98px) {
  .wrapper-html-guide .syntaxhighlighter.html {
    display: none;
  }
}
.wrapper-html-guide .icon-list {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
}
.wrapper-html-guide .icon-list .ico {
  background-color: rgba(255, 0, 0, 0.2);
}
.wrapper-html-guide .icon-list li {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 10px;
}
.wrapper-html-guide .icon-list li p {
  margin-top: 10px;
  font-size: 12px;
}

.guide-section:last-child {
  min-height: 100vh;
  padding-bottom: 50px;
}

#guide-nav {
  position: fixed;
  left: 0;
  padding: 15px;
  top: 0;
  z-index: 100;
  background-color: rgba(255, 255, 255, 0.95);
}
#guide-nav .btn-collaspe {
  display: none;
}
#guide-nav .ico {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  vertical-align: middle;
  overflow: hidden;
  text-indent: -9999px;
  overflow: hidden;
  text-align: left;
}
#guide-nav .ico-menu-white-md {
  width: 16px;
  height: 16px;
}
#guide-nav .ico-times-white-md {
  width: 16px;
  height: 16px;
}
#guide-nav .ico-chevron-left-sm-gray {
  width: 5px;
  height: 9px;
  line-height: 9px;
}
#guide-nav .ico-chevron-right-sm-gray {
  width: 5px;
  height: 9px;
  line-height: 9px;
}
#guide-nav .sidebar-title {
  border-bottom: 1px solid #ddd;
  font-size: 20px;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
#guide-nav .nav-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}
#guide-nav .nav-list {
  font-size: 14px;
  line-height: 1.5;
}
#guide-nav ul {
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;
  padding-bottom: 10px;
}
#guide-nav li a small {
  opacity: 0.8;
  display: none;
}
#guide-nav li a .ico {
  margin-top: -2px;
}
#guide-nav li.on {
  font-weight: bold;
  color: #1e48bc;
}
#guide-nav .quick-link {
  font-size: 13px;
}
@media (max-width: 1024.98px) {
  #guide-nav {
    max-height: 100%;
    transform: translateX(-100%);
    transition-duration: 0.3s;
    transition-property: transform;
    will-change: transform;
  }
  #guide-nav .btn-collaspe {
    display: flex;
    position: absolute;
    top: 0;
    right: -28px;
    width: 28px;
    height: 28px;
    color: #fff;
    line-height: 0;
    opacity: 0.4;
    background-color: #000;
  }
  #guide-nav .btn-collaspe .close {
    display: none;
  }
  #guide-nav .btn-collaspe .close .ico {
    width: 14px;
    height: 14px;
  }
  #guide-nav .btn-collaspe .open {
    font-size: 12px;
    line-height: 1;
  }
  #guide-nav .nav-title {
    font-size: 14px;
  }
  #guide-nav .guide-list,
  #guide-nav .quick-link {
    font-size: 13px;
  }
  .sidebar-open #guide-nav {
    transform: translateX(0);
  }
  .sidebar-open #guide-nav .btn-collaspe {
    right: 0;
  }
  .sidebar-open #guide-nav .btn-collaspe .close {
    display: block;
  }
  .sidebar-open #guide-nav .btn-collaspe .open {
    display: none;
  }
}

.color-palette .examples {
  font-size: 20px;
  font-weight: bold;
}
.color-palette .examples span {
  display: inline-block;
  margin: 3px 4px;
}
.color-palette .examples span:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  margin: -2px 4px 0 0;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  background-color: currentColor;
  background-image: none;
}

.examples {
  border: 1px solid #eee;
  border-radius: 4px;
  padding: 30px;
  background: linear-gradient(
      45deg,
      rgba(0, 0, 0, 0.03) 25%,
      transparent 25%,
      transparent 75%,
      rgba(0, 0, 0, 0.03) 75%,
      rgba(0, 0, 0, 0.03) 0
    ),
    linear-gradient(
      45deg,
      rgba(0, 0, 0, 0.03) 25%,
      transparent 25%,
      transparent 75%,
      rgba(0, 0, 0, 0.03) 75%,
      rgba(0, 0, 0, 0.03) 0
    ),
    white;
  background-repeat: repeat, repeat;
  background-position: 0px 0, 13px 13px;
  transform-origin: 0 0 0;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
  background-size: 26px 26px, 26px 26px;
  transform: scaleX(1) scaleY(1) scaleZ(1);
}
@media (max-width: 1024.98px) {
  .examples {
    padding: 15px;
  }
}

.code {
  border: 1px solid rgba(255, 0, 0, 0.2);
  padding: 4px 8px;
  border-radius: 2px;
  background-color: antiquewhite;
  font-family: "Courier New", "malgungothic", Courier;
  font-size: 14px;
}

.title-guide {
  font-size: 36px;
  font-family: "Spoqa Han Sans Neo", "malgun gothic", Arial, sans-serif;
  letter-spacing: -0.03em;
  color: #222;
  margin: 80px 0 20px 0;
  padding: 15px 0;
  border-top: 2px solid rgba(34, 34, 34, 0.8);
  border-bottom: 1px solid rgba(34, 34, 34, 0.1);
}
.title-guide small {
  margin-left: 10px;
  color: #1e48bc;
}
@media (max-width: 1024.98px) {
  .title-guide {
    font-size: 20px;
  }
}

.guide-title1 {
  font-size: 24px;
  font-weight: bold;
  margin: 1.2em 0 0.5em;
}
.guide-title1 + .guide-title2 {
  margin-top: 1em;
}

.guide-title2 {
  font-size: 18px;
  font-weight: bold;
  margin: 1.5em 0 0.5em;
}

.box-html-guide {
  margin-top: -20px;
  background-color: #f6f6fb;
  padding: 15px 20px;
}

.box-tbd {
  text-align: center;
  font-size: 0.3rem;
  color: #fff;
  line-height: 1rem;
}
.box-tbd:after {
  content: "디자인 수급예정";
}

.bg-setting {
  display: flex;
  text-align: center;
  flex-wrap: wrap;
  margin: -10px -5px;
}
.bg-setting span {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 150px;
  margin: 10px 5px;
  padding: 15px;
  border-radius: 10px;
  font-weight: bold;
  font-size: 14px;
  vertical-align: middle;
}

.score-list {
  display: flex;
  justify-content: space-around;
  margin: 15px 0;
  border: 1px solid #e7e7e7;
  border-radius: 10px;
  padding: 20px 10px 15px;
}
.score-list li {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.score-list strong {
  order: 2;
  margin-top: 10px;
  font-size: 14px;
  font-weight: 500;
}
.score-list .chart {
  position: relative;
  width: 60px;
  height: 60px;
  color: #999;
}
.score-list .chart .percent {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 18px;
  font-weight: bold;
}

.guide-list > li {
  position: relative;
  padding-left: 12px;
  line-height: 1.3;
}
.guide-list > li:before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0.65em;
  margin-top: -2px;
  width: 5px;
  height: 5px;
  border-radius: 3px;
  background-color: #343439;
}
.guide-list > li + li {
  margin-top: 3px;
}
