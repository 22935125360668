.editor-class {
  height: 500px;
  overflow-y: auto;
}

.editor-class .ck-editor__editable::-webkit-scrollbar {
  display: none;
}

/* 스크롤바 숨기기 - 파이어폭스용 */
.editor-class .ck-editor__editable {
  scrollbar-width: none;
}

.ck.ck-editor__editable:not(.ck-editor__nested-editable) {
  min-height: 400px;
  margin-bottom: 20px;
  overflow-y: auto;
}
.ck.ck-button.ck-off.ck-file-dialog-button {
  display: none !important;
}

.ck-dropdown__panel {
  max-height: 200px; /* 원하는 최대 높이 설정 */
  overflow-y: auto; /* 내용이 최대 높이를 초과할 경우 스크롤바 생성 */
}
