@font-face {
  font-family: "NotoSansKR";
  src: url("/public/font/NotoSansKR-VariableFont_wght.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.body {
  font-family: "NotoSansKR", sans-serif;
  color: rgba(0, 0, 0, 0.8);
  font-family: Noto Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  word-break: break-all;
  font-size: 1rem;
}
.radioPayment {
  display: none;
}
/* .policy {
  margin-top: 20px;
} */
.payLogoDiv {
  height: 58px;
  display: flex;
  justify-items: center;
  align-items: center;
  justify-content: center;
}
.paymentBox {
  border-radius: 20px;
  border: 1px solid #7b7b7b;
  background: #fff;
  width: 120px;

  flex-shrink: 0;
  margin: 0 0.5rem 0 0;
}
.btn-pay {
  display: flex;
  width: 433px;
  height: 58px;
  padding: 6px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border: none;
  color: white;
  border-radius: 8px;
  background: rgba(0, 97, 243, 0.6);
  color: #fff;

  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "NotoSansKR", sans-serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 154%; /* 38.5px */
  letter-spacing: -0.2px;
}
.arrow {
  top: 25%;
  right: 5%;
}
.paymentBox.selected {
  background-color: rgba(0, 97, 243, 0.6); /* Change to your desired color */
}

.modal-title {
  font-weight: 900;
}

.profile_img {
  width: 223px;
  height: 223px;
  flex-shrink: 0;
  border-radius: 223px;
  /* background: url(<path-to-image>), lightgray 50% / cover no-repeat; */
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.done-btn {
  display: flex;

  padding: 6px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 8px;
  background: rgba(0, 97, 243, 0.6);
  border: NONE;
  color: white;
}

.bottom {
  width: 323px;
  height: 62px;
}
input {
  border-radius: 8px;
  border: 2px solid #000;
  height: 50px;
  font-size: 1rem;
  text-align: center;
}

input::placeholder {
  color: #696767;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Noto Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 154%; /* 30.8px */
  letter-spacing: -0.2px;
}
.plus {
  width: 30%;
}
.h-87px {
  height: 87px;
}
textarea {
  border-radius: 8px;
  border: 2px solid #000;
  height: 50px;
  font-size: 1rem;
}
textarea::placeholder {
  color: #696767;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Noto Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 154%; /* 30.8px */
  letter-spacing: -0.2px;
}
.header {
  font-size: 16px;
}
.logo {
  display: flex;
  width: 60px;
  height: 60px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 50%;
}

.checkbox-margin {
  margin-top: 7px;
}

.logo-div {
  display: flex;
  align-items: center;
}
.wordcounter {
  bottom: 10%;
  font-size: 0.8rem;
  right: 1%;
}
textarea {
  resize: none;
}
.red {
  color: red;
}

.selected-payment {
  background-color: rgba(0, 97, 243, 0.6);
}

.payTextColor {
  color: black;
}

.mb-3 {
  font-family: "NotoSansKR", sans-serif;
}

.mb-4 {
  font-family: "NotoSansKR", sans-serif;
}
